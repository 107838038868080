/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    DialogContent,
    Dialog,
    makeStyles,
    Grid,
    Button,
    Tooltip,
    MenuItem,
    Checkbox,
} from '@material-ui/core';
import {
    COMPONENT_WIDTH,
    COMPONENT_HEIGHT,
    ALL_EMPLOYEE_TYPES,
} from 'utils/enum/BusinessIntelligenceEnum';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import {
    useMutation,
    useQuery,
} from '@apollo/client';
import { Form, Col } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import CoreSelect from '@material-ui/core/Select';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from '@material-ui/core/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import Select from 'components/widgets/Select';
import UserQuery from 'services/graphQL/query/UserQuery';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import BIMutation from 'services/graphQL/mutate/businessIntelligence/Mutation';

// Icons
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    main: {
        [theme.breakpoints.down('sm')]: {
            overflowY: 'initial',
        },
    },
    settingsDialogContent: {
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                flexDirection: 'column',
            },
            '& > div > div': {
                padding: '0px !important',
            },
        },
    },
    innerSections: {
        height: '400px',
    },
    middleSection: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '150px',
        },
    },
    label: {
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '1.6',
        letterSpacing: '0.0075em',
    },
    paper: {
        height: '100%',
        overflowY: 'auto',
    },
    actions: {
        paddingRight: '40px',
        paddingLeft: '40px',
        justifyContent: 'space-between',
        '& > div:nth-child(1)': {
            display: 'flex',
            width: '240px',
            justifyContent: 'space-between',
        },
    },
    staticIcon: {
        fill: theme.palette.secondary.main,
    },
    currentElement: {
        zIndex: 9999,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '& div.MuiAvatar-root': {
            backgroundColor: theme.palette.background.white,
            border: `1px solid ${theme.palette.border.ghost}`,
            '& > svg': {
                width: '24px',
                height: '24px',
            },
        },
        '& div.MuiListItemText-root > span': {
            fontSize: '13px',
            lineHeight: 'initial',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '70%',
        },
        '& > div.sizes': {
            display: 'flex',
            paddingRight: '2px',
            position: 'absolute',
            right: 0,
            '& > div > div': {
                width: '85px',
                height: '40px !important',
            },
        },
    },
    siteContent: {
        fontSize: '14px',
        '& input': {
            fontSize: '14px',
        },
    },
    select: {
        width: '100%',
        border: `1px solid ${theme.palette.border.ghost}`,
        borderRadius: '0.25rem',
        height: '38px',
        fontSize: '13px',
        '& > div': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
        },
    },
}));

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 250,
        },
    },
    getContentAnchorEl: null,
};

const chartTypes = BIHelper.getChartType();
const SettingsDialog = ({
    staticComponents,
    charts,
    frames,
    components,
    onClose,
    refetchComponents,
    refetchFrames,
}) => {
    const keyStore = new KeyStore();
    const hasBISettingsAccess = keyStore.hasPermission(Permission.BI_SETTINGS_WRITE);

    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState({
        currentComponents: [],
        availableComponents: [],
        selectedCurrent: null,
        selectedAvailable: null,
        isSiteDialogOpen: false,
        selectedSite: null,
        employeeTypes: [],
        isDeletePromptVisible: false,
    });

    const {
        data: usersData,
        loading: loadingUsersData,
        error: errorLoadingUsersData,
    } = useQuery(UserQuery.GET_USERS_BY_COMPANY, {
        variables: {
            search: state.globalSearch,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveDashboardFrame, { loading: savingFrame }] = useMutation(BIMutation.SAVE_DASHBOARD_FRAME, {
        onCompleted: (response) => {
            if (response?.saveDashboardFrame) {
                ModalUtils.successMessage(null, 'Site saved successfully');
                setState((prevState) => ({
                    ...prevState,
                    isSiteDialogOpen: false,
                    isDeletePromptVisible: false,
                    selectedSite: null,
                }));

                refetchFrames();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [saveDashboardSettings, { loading: savingComponents }] = useMutation(BIMutation.SAVE_DASHBOARD_COMPONENTS, {
        onCompleted: (response) => {
            if (response?.saveDashboardComponents) {
                ModalUtils.successMessage(null, 'Components saved successfully');

                const saved = response.saveDashboardComponents;
                if (Array.isArray(saved)) {
                    const clone = state.currentComponents.slice(0);
                    saved.forEach(({ id, staticName, chartId }) => {
                        const component = clone.find((el) => (!el.chartId && el.name === staticName) || (el.chartId && el.chartId === chartId));
                        if (component) component.componentId = id;
                    });

                    setState((prevState) => ({
                        ...prevState,
                        currentComponents: clone,
                    }));

                    refetchComponents();
                }

                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the components');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        if (errorLoadingUsersData) {
            ModalUtils.errorMessage(errorLoadingUsersData?.graphQLErrors);
            return;
        }

        if (!loadingUsersData) {
            const users = usersData?.getUsersByCompany;
            if (users) {
                setState((prevState) => ({
                    ...prevState,
                    employeeTypes: [
                        ...new Set(
                            users
                                .filter((user) => !StringUtils.isEmpty(user.employeeType) && !['Integration', 'Other'].includes(user.employeeType))
                                .map((user) => user.employeeType).sort((a, b) => a.localeCompare(b)),
                        ),
                    ],
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsersData, errorLoadingUsersData]);

    useEffect(() => {
        const split = BIHelper.splitComponents(staticComponents, frames, charts, components);
        setState((prevState) => ({
            ...prevState,
            currentComponents: split.current,
            availableComponents: split.available,
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charts, frames, components]);

    const onSelectComponent = (component, isCurrent) => {
        setState((prevState) => ({
            ...prevState,
            selectedCurrent: isCurrent ? component : null,
            selectedAvailable: isCurrent ? null : component,
        }));
    };

    const onMoving = () => {
        const {
            selectedCurrent,
            selectedAvailable,
            currentComponents,
            availableComponents,
        } = state;

        let cloneCurrent = currentComponents.slice(0);
        let cloneAvailable = availableComponents.slice(0);
        if (!StringUtils.isEmpty(selectedCurrent)) {
            cloneAvailable.order = -1;
            cloneAvailable.width = COMPONENT_WIDTH[0];
            cloneAvailable.height = COMPONENT_HEIGHT[2];
            cloneAvailable.push(selectedCurrent);
            cloneAvailable.sort((a, b) => a.name.localeCompare(b.name));
            cloneCurrent = cloneCurrent.filter((c) => c.name !== selectedCurrent.name);
        }

        if (!StringUtils.isEmpty(selectedAvailable)) {
            selectedAvailable.order = cloneCurrent.length + 1;
            selectedAvailable.height = COMPONENT_HEIGHT[2];
            cloneCurrent.push(selectedAvailable);
            cloneAvailable = cloneAvailable.filter((c) => c.name !== selectedAvailable.name);
        }

        setState((prevState) => ({
            ...prevState,
            currentComponents: cloneCurrent,
            availableComponents: cloneAvailable,
            selectedCurrent: null,
            selectedAvailable: null,
        }));
    };

    const changeSize = (component, property, value) => {
        const cloneCurrent = state.currentComponents.slice(0);
        const element = cloneCurrent.find((c) => c.name === component.name);
        if (element) element[property] = Number(value);

        setState((prevState) => ({
            ...prevState,
            currentComponents: cloneCurrent,
        }));
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            let componentsSorted = arrayMove(
                state.currentComponents.slice(0),
                oldIndex,
                newIndex,
            );

            componentsSorted = componentsSorted.map((component, index) => ({
                ...component,
                order: index + 1,
            }));

            setState((prevState) => ({
                ...prevState,
                currentComponents: componentsSorted,
            }));
        }
    };

    const toggleSiteDialog = (site) => {
        let currentSite = null;
        if (!site) {
            currentSite = {
                employeeTypes: [ALL_EMPLOYEE_TYPES],
            };
        } else {
            currentSite = {
                dashboardFrameId: site.frameId,
                title: site.name,
                url: site.frameURL,
                employeeTypes: Array.isArray(site.frameEmpTypes) ? site.frameEmpTypes : site.frameEmpTypes.split(','),
            };
        }

        setState((prevState) => ({
            ...prevState,
            selectedSite: currentSite,
            isSiteDialogOpen: !state.isSiteDialogOpen,
        }));
    };

    const onChangeSite = (name, value) => {
        const clone = { ...(state.selectedSite ?? {}) };

        if (name === 'employeeTypes' && value.length > 0) {
            const lastItem = [...value].pop();
            if (lastItem === ALL_EMPLOYEE_TYPES) { clone[name] = [ALL_EMPLOYEE_TYPES]; } else { clone[name] = value.filter((i) => i !== ALL_EMPLOYEE_TYPES); }
        } else {
            clone[name] = value;
        }

        setState((prevState) => ({
            ...prevState,
            selectedSite: clone,
        }));
    };

    const saveSettings = () => {
        const { currentComponents } = state;
        if (currentComponents.length === 0) return;

        const toDelete = [];
        components.forEach((c) => {
            if (!currentComponents.some((current) => current.componentId === c.id)) toDelete.push(c);
        });

        saveDashboardSettings({
            variables: {
                input: [
                    ...currentComponents.map((c) => ({
                        id: c.componentId,
                        staticName: (!c.chartId && !c.frameId) ? c.name : null,
                        chartId: c.chartId,
                        frameId: c.frameId,
                        order: c.order,
                        width: c.width,
                        height: c.height,
                    })),
                    ...toDelete.map((c) => ({
                        id: c.id,
                        staticName: c.staticName,
                        chartId: c.chart?.id,
                        frameId: c.frame?.id,
                        order: c.order,
                        width: c.width,
                        height: c.height,
                        active: false,
                    })),
                ],
            },
        });
    };

    const {
        dashboardFrameId,
        title,
        url,
        employeeTypes,
    } = state.selectedSite ?? {};
    const onSaveSite = (active = true) => {
        saveDashboardFrame({
            variables: {
                dashboardFrameId,
                title,
                url,
                employeeTypes: employeeTypes.join(','),
                active,
            },
        });
    };

    const onRemoveFrame = () => {
        onSaveSite(false);
    };

    const getComponentIcon = (c) => {
        const {
            chartId,
            chartType,
            frameId,
        } = c;

        if (chartId && chartType) return chartTypes.find((chart) => chart.name === c.chartType)?.icon;
        if (frameId) return (<LanguageOutlinedIcon className={classes.staticIcon} />);
        return (<AppsOutlinedIcon className={classes.staticIcon} />);
    };

    const toggleDeletePrompt = (site) => {
        setState((prevState) => ({
            ...prevState,
            isDeletePromptVisible: !state.isDeletePromptVisible,
            selectedSite: site ? {
                dashboardFrameId: site.frameId,
                title: site.name,
                url: site.frameURL,
                employeeTypes: Array.isArray(site.frameEmpTypes) ? site.frameEmpTypes : site.frameEmpTypes.split(','),
            } : null,
        }));
    };

    const SortableItem = SortableElement(({ value: c, sortIndex }) => (
        <div
            key={sortIndex}
            index={sortIndex}
            className={classes.currentElement}
        >
            <ListItem
                button
                selected={state.selectedCurrent?.name === c.name}
                onClick={() => onSelectComponent(c, true)}
            >
                <ListItemAvatar>
                    <Avatar>
                        {getComponentIcon(c)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={c.name}
                />
            </ListItem>
            <div className="sizes">
                <Tooltip title="Width" placement="top">
                    <Select
                        nowrap
                        size="sm"
                        loading={false}
                        name=""
                        className={classes.input}
                        onChange={(_, value) => changeSize(c, 'width', value)}
                        value={c.width}
                        options={
                            COMPONENT_WIDTH
                                .map((f) => ({
                                    value: f,
                                    label: `${f}%`,
                                }))
                        }
                    />
                </Tooltip>
                <Tooltip title="Height" placement="top">
                    <Select
                        nowrap
                        size="sm"
                        loading={false}
                        name=""
                        className={classes.input}
                        onChange={(_, value) => changeSize(c, 'height', value)}
                        value={c.height}
                        options={
                            COMPONENT_HEIGHT
                                .map((f) => ({
                                    value: f,
                                    label: `${f} px`,
                                }))
                        }
                    />
                </Tooltip>
            </div>
        </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
        <List>
            {
                items.map((component, index) => (
                    <SortableItem
                        key={`component-${index}`}
                        index={index}
                        sortIndex={index}
                        value={component}
                    />
                ))
            }
        </List>
    ));

    const selectedItem = state.selectedCurrent ?? state.selectedAvailable;
    const isSaveSiteDisabled = StringUtils.isEmpty(title) || StringUtils.isEmpty(url) || !StringUtils.isSecureURL(url) || employeeTypes.length === 0;
    return (
        <>
            <Dialog
                open
                fullWidth
                maxWidth="lg"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title="Dashboard Settings"
                    onClose={onClose}
                    toolbarSize="md"
                />
                <DialogContent className={classes.main}>
                    <div className={classes.settingsDialogContent}>
                        <Grid container spacing={3}>
                            <Grid item md={6} sm={12} className={classes.innerSections}>
                                <div className={classes.label}>
                                    Current Components
                                </div>
                                <Paper elevation={2} className={classes.paper}>
                                    <SortableList
                                        axis="y"
                                        distance={10}
                                        items={state.currentComponents}
                                        onSortEnd={onSortEnd}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item md={1} sm={12}>
                                <Grid
                                    className={clsx(classes.innerSections, classes.middleSection)}
                                    container
                                >
                                    <IconButton
                                        aria-label="add-form"
                                        size="small"
                                        disabled={!StringUtils.isEmpty(state.selectedCurrent)}
                                        onClick={onMoving}
                                    >
                                        <ArrowBackIosIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="remove-form"
                                        size="small"
                                        disabled={!StringUtils.isEmpty(state.selectedAvailable)}
                                        onClick={onMoving}
                                    >
                                        <ArrowForwardIosIcon fontSize="inherit" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item md={5} sm={12} className={classes.innerSections}>
                                <div className={classes.label}>
                                    Available Components
                                </div>
                                <Paper elevation={2} className={classes.paper}>
                                    <List>
                                        {state.availableComponents.map((c, index) => (
                                            <div key={index} className={classes.currentElement}>
                                                <ListItem
                                                    button
                                                    selected={state.selectedAvailable?.name === c.name}
                                                    onClick={() => onSelectComponent(c, false)}
                                                    className={classes.currentElement}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            {getComponentIcon(c)}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={c.name}
                                                    />
                                                </ListItem>
                                            </div>
                                        ))}
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div>
                        {hasBISettingsAccess && (
                            <>
                                <Button
                                    className={classes.containedSecondaryInfo}
                                    disabled={savingComponents}
                                    onClick={() => toggleSiteDialog()}
                                >
                                    Add Site
                                </Button>
                                {selectedItem && selectedItem.frameId && (
                                    <>
                                        <Button
                                            className={classes.containedSecondaryInfo}
                                            disabled={savingComponents}
                                            onClick={() => toggleSiteDialog(selectedItem)}
                                        >
                                            Edit Site
                                        </Button>
                                        <Button
                                            className={classes.containedError}
                                            disabled={savingComponents}
                                            onClick={() => toggleDeletePrompt(selectedItem)}
                                        >
                                            Delete Site
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <Button
                        className={classes.containedSecondaryInfo}
                        disabled={state.currentComponents.length === 0 || savingComponents}
                        onClick={saveSettings}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {state.isSiteDialogOpen && (
                <Dialog
                    open
                    fullWidth
                    maxWidth="sm"
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Site"
                        onClose={() => toggleSiteDialog()}
                        toolbarSize="md"
                    />
                    <DialogContent className={classes.main}>
                        <div className={classes.siteContent}>
                            <Form.Group as={Col}>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={title ?? ''}
                                    onChange={(e) => onChangeSite('title', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={url ?? ''}
                                    onChange={(e) => onChangeSite('url', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Employee Types</Form.Label>
                                <CoreSelect
                                    className={classes.select}
                                    multiple
                                    name="employeeTypes"
                                    value={employeeTypes ?? []}
                                    onChange={(e) => onChangeSite(e.target.name, e.target.value)}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {[ALL_EMPLOYEE_TYPES, ...state.employeeTypes].map((employeeType, index) => (
                                        <MenuItem key={index} value={employeeType}>
                                            <Checkbox checked={(employeeTypes || []).includes(employeeType)} />
                                            <ListItemText primary={employeeType} />
                                        </MenuItem>
                                    ))}
                                </CoreSelect>
                            </Form.Group>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button
                            className={classes.containedSecondaryInfo}
                            disabled={isSaveSiteDisabled || savingFrame}
                            onClick={() => onSaveSite()}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this site?"
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onRemoveFrame}
                disablePrimaryButton={savingFrame}
                disableSecondaryButton={savingFrame}
            />
        </>
    );
};

SettingsDialog.defaultProps = {
    staticComponents: [],
    charts: [],
    frames: [],
    components: [],
};

SettingsDialog.propTypes = {
    staticComponents: PropTypes.array,
    charts: PropTypes.array,
    frames: PropTypes.array,
    components: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    refetchComponents: PropTypes.func.isRequired,
    refetchFrames: PropTypes.func.isRequired,
};

export default SettingsDialog;

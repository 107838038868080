import React, {
    useReducer, useRef, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid, Chip, makeStyles, Button, Dialog, DialogContent,
    AppBar, Toolbar, IconButton, Typography, Slide, useTheme,
    useMediaQuery, Tooltip,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import Split from 'react-split';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import { SaveIcon, AutorenewIcon, CloseIcon } from 'components/icons';
import JournalDistribution from 'components/modules/accounting/journal/list/JournalDistribution';
import {
    Col, Form, Row, InputGroup,
} from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema, getErrorMessage } from 'utils/schema/utils';
import CheckSchema from 'utils/schema/accounting/banking/CheckSchema';
import { useLazyQuery, useMutation } from '@apollo/client';
import DropdownQuery from 'components/widgets/DropdownQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import AccountingQuery from 'services/graphQL/query/accounting/AccountingQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import AccountingSettingsQuery from 'services/graphQL/query/accounting/AccountingSettings';
import UserQuery from 'services/graphQL/query/UserQuery';
import AccountingMutation from 'services/graphQL/mutate/accounting/AccountingMutation';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import {
    CheckTypes, AccountingSubModules, AccountingCBStatus, AccountingCOAType,
    ProcessType, TransactionType, ErrorCodes,
} from 'utils/enum/AccountingEnum';
import ModalUtils from 'utils/ModalUtils';
import AccountingCBMapper from 'services/mapData/AccountingCBMapper';
import UserContext from 'components/context/UserContext';
import CheckDepositReducer, { ACTION_TYPES } from 'components/modules/accounting/banking/reducer/CheckDepositReducer';
import { useParams, useHistory } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import { DataSort, FetchPolicy, ProcessTypeDescription } from 'utils/enum/Core';
import useValidateActions from 'components/modules/accounting/banking/hooks/useValidateActions';
import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';

// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import NumberUtils from 'lib/NumberUtils';
import If from 'components/widgets/conditional/If';
import { isEmpty } from 'lodash';
import FileManagerContainer from 'components/widgets/filemanager/FileManagerContainer';
import AccountingUtils from 'utils/AccountingUtils';

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const checkTypeData = [
    { label: CheckTypes.CUSTOMER, value: CheckTypes.CUSTOMER },
    { label: CheckTypes.VENDOR, value: CheckTypes.VENDOR },
    { label: CheckTypes.EMPLOYEE, value: CheckTypes.EMPLOYEE },
];

const SortFields = {
    VENDOR_NAME: 'vendorName',
};

const keyStore = new KeyStore();

const CheckCreate = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { comeFromInvoice, onCloseCheck, billData } = props;

    const { id } = useParams();
    const history = useHistory();

    const ACCOUNTING_BANKING_CHECK_WRITE = keyStore.hasPermission(Permission.ACCOUNTING_BANKING_CHECK_WRITE);

    const initialState = {
        record: {
            cbId: 0,
            bankAccount: 0,
            type: '',
            cbType: '',
            isPaymentRefund: false,
            isRepoRefund: false,
            paidToId: 0,
            paidTo: '',
            lotId: 0,
            lotName: '',
            checkNumber: 0,
            isACH: false,
            is1099: false,
            date: new Date(),
            amount: 0,
            endingBalance: 0,
            memo: '',
            status: AccountingCBStatus.NEW,
            entityAddress: '',
            processTypeId: comeFromInvoice ? ProcessType.ACCOUNTS_PAYABLE : 0,
            isVehiclePurchase: false,
        },
        isDirty: false,
        couldLostData: false,
        showDialogDuplicateCheck: false,
        paidToList: [],
        accountAlreadySelected: {
            bankAccount: 0,
            checkNumber: 0,
            isACH: false,
        },
        customerMapSetting: null,
        showInventoryPurchaseOptions: false,
        showCheckPopup: true,
        editableCheckNumbers: false,
        isDirtyFiles: false,
    };

    const classes = useStyle();
    const journalRef = useRef();

    const [state, dispatch] = useReducer(CheckDepositReducer, initialState);
    const {
        record, isDirty, couldLostData, showDialogDuplicateCheck,
        paidToList, accountAlreadySelected, customerMapSetting, showInventoryPurchaseOptions,
        showCheckPopup, isDirtyFiles,
    } = state;

    const comeFromAccountPayable = comeFromInvoice || record.processTypeId === ProcessType.ACCOUNTS_PAYABLE;

    const isValidData = isValidSchema(CheckSchema, record);
    const { isValid, errors } = isValidData;

    const { userInformation } = useContext(UserContext);
    const defaultLot = userInformation?.defaultLot || userInformation?.usersLot;
    const lots = userInformation?.lots ?? [];

    if (lots && record.lotId === 0) {
        const lotItem = lots.find((item) => item.lotName === defaultLot);
        const lotId = lotItem?.lotId || 0;
        if (lotId > 0) dispatch({ type: ACTION_TYPES.SET_DEFAULT_LOT, value: lotId });
    }

    const {
        editBankAccount,
        editLot,
        editMemo,
        editDate,
        editAddress,
        editCheckType,
        editPayTo,
        editPaymentRefund,
        editRepoRefund,
        allowSaveAction,
        glOptions,
        editACH,
        editCheckNumber,
    } = useValidateActions(record.status, record.processTypeId, record.cbType, record.isPaymentRefund || record.isRepoRefund);

    const [getLastCheck] = useLazyQuery(AccountingQuery.GET_LAST_CHECK, {
        onCompleted: (res) => {
            const getLastId = res.getLastCheck;
            if (getLastId) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        checkNumber: getLastId.result,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getLastACHCheck] = useLazyQuery(AccountingQuery.GET_LAST_ACHCHECK, {
        onCompleted: (res) => {
            const getLastId = res.getLastACHCheck;
            if (getLastId) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        checkNumber: getLastId.result,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getEndingBalance] = useLazyQuery(AccountingQuery.GET_ENDING_BALANCE, {
        onCompleted: (res) => {
            if (res.getEndingBalance) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        endingBalance: res.getEndingBalance.result,
                    },
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getVendorList, { loading: loadingVendor }] = useLazyQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            sort: {
                field: SortFields.VENDOR_NAME,
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res.getVendorList) {
                const vandorPaidToList = res.getVendorList.map((item) => ({
                    paidToId: item.vendorId,
                    paidTo: item.vendorName,
                    address: item.vendorAddress,
                    controlNumber: '',
                    paidToAmount: 0,
                    is1099: item.is1099,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'paidToList',
                        value: vandorPaidToList,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const useAccountNumber = (record.type === CheckTypes.CUSTOMER && (record.isPaymentRefund || record.isRepoRefund));
    const [getCustomerList, { loading: loadingCustomer }] = useLazyQuery(AccountingSettingsQuery.GET_CUSTOMER_BANKING_SETTINGS_LIST, {
        onCompleted: (res) => {
            if (res.getCustomerBankingSettings?.mapSetting) {
                const mapData = res.getCustomerBankingSettings.mapSetting;
                if (mapData.accountId > 0 && record.cbId === 0) {
                    dispatch({
                        type: ACTION_TYPES.SET_RECORD_VALUE,
                        value: {
                            key: 'customerMapSetting',
                            value: [{
                                accountCOA: {
                                    accountNumber: mapData.accountId,
                                    description: mapData.accountDescription,
                                    controlledBy: mapData.controlledBy,
                                },
                                debit: 0,
                                credit: 0,
                                controlNumber: '',
                                lot: {
                                    lotId: record.lotId,
                                },
                            }],
                        },
                    });
                }
            }

            if (res.getCustomerBankingSettings?.customers) {
                const customerPaidToList = res.getCustomerBankingSettings?.customers
                    .map((item) => ({
                        paidToId: useAccountNumber ? item.accountNumber : item.customerCode,
                        paidTo: useAccountNumber ? [item.accountNumber, ' - ', item.customerName].join('') : item.customerName,
                        customerName: item.customerName,
                        address: item.address,
                        controlNumber: String(item.accountNumber) ?? '',
                        paidToAmount: item.customerRefundAmount,
                    }));

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'paidToList',
                        value: customerPaidToList,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getEmployeeList, { loading: loadingEmployee }] = useLazyQuery(UserQuery.GET_BANKING_EMPLOYEES, {
        onCompleted: (res) => {
            if (res.getBankingEmployees) {
                const employeePaidToList = res.getBankingEmployees.map((item) => ({
                    paidToId: item.userId,
                    paidTo: `${item.firstName} ${item.lastName}`,
                    address: item.address,
                    controlNumber: '',
                    paidToAmount: 0,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: {
                        key: 'paidToList',
                        value: employeePaidToList,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const loadPaidToList = (value, fromLoading = false, repoData = null) => {
        if (!value) return;

        if (value.toLowerCase() === CheckTypes.VENDOR.toLowerCase()) getVendorList();
        if (value.toLowerCase() === CheckTypes.CUSTOMER.toLowerCase()) {
            const isRepoRef = fromLoading ? repoData.isRepoRefund : record.isRepoRefund;
            const isPaymentRef = fromLoading ? repoData.isPaymentRefund : record.isPaymentRefund;
            getCustomerList({
                variables: {
                    isRepoRefund: isRepoRef,
                    isPaymentRefund: isPaymentRef,
                },
            });
        }
        if (value.toLowerCase() === CheckTypes.EMPLOYEE.toLowerCase()) getEmployeeList();
    };

    const getLastCheckOrACH = (currentRecord) => {
        if (currentRecord.isACH) {
            getLastACHCheck({
                variables: {
                    bankAccount: Number(currentRecord.bankAccount),
                },
            });
        } else {
            getLastCheck({
                variables: {
                    bankAccount: Number(currentRecord.bankAccount),
                },
            });
        }
    };

    const [getDefaultBankSettings] = useLazyQuery(AccountingQuery.GET_BANKING_DEFAULT_SETTINGS, {
        onCompleted: (res) => {
            if (res.getBankingDefaultSettings) {
                const { defaultBankChecks, defaultCheckType, editableCheckNumbers } = res.getBankingDefaultSettings;

                if (id > 0) { // this value is comming from useParams
                    dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'editableCheckNumbers', value: editableCheckNumbers } });
                    return;
                }

                const currentRecord = record;
                currentRecord.bankAccount = Number(defaultBankChecks);
                currentRecord.type = defaultCheckType ?? CheckTypes.CUSTOMER;

                if (record.processTypeId === ProcessType.ACCOUNTS_PAYABLE) currentRecord.type = CheckTypes.VENDOR;

                dispatch({
                    type: ACTION_TYPES.SET_RECORD_AND_SETTING,
                    value: {
                        record: currentRecord,
                        editableCheckNumbers,
                    },
                });

                getLastCheckOrACH(currentRecord);
                getEndingBalance({
                    variables: {
                        bankAccount: Number(currentRecord.bankAccount),
                    },
                });

                loadPaidToList(currentRecord.type, true, { isRepoRefund: false, isPaymentRefund: false });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getCheck, { loading }] = useLazyQuery(AccountingQuery.GET_ACCOUNTING_CB, {
        onCompleted: (res) => {
            if (res.getCheck) {
                const result = AccountingCBMapper.mapAccountingCB(res.getCheck);

                result.isACH = result.achCheckNumber > 0;
                result.checkNumber = result.isACH ? result.achCheckNumber : result.checkNumber;
                if (result.type.toLowerCase().indexOf(CheckTypes.CUSTOMER.toLowerCase()) >= 0) result.type = CheckTypes.CUSTOMER;
                else if (result.type.toLowerCase().indexOf(CheckTypes.VENDOR.toLowerCase()) >= 0) result.type = CheckTypes.VENDOR;
                else if (result.type.toLowerCase().indexOf(CheckTypes.EMPLOYEE.toLowerCase()) >= 0) result.type = CheckTypes.EMPLOYEE;
                else result.type = CheckTypes.CUSTOMER;

                loadPaidToList(result.type, true, { isRepoRefund: result.isRepoRefund, isPaymentRefund: result.isPaymentRefund });

                getEndingBalance({
                    variables: {
                        bankAccount: Number(result.bankAccount),
                    },
                });

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: { ...result },
                        accountAlreadySelected: {
                            bankAccount: result.bankAccount,
                            checkNumber: result.checkNumber,
                            isACH: result.isACH,
                        },
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: initialState,
        });
    };

    const onCloseAction = (saveSuccess = false) => {
        if (record.processTypeId === ProcessType.ACCOUNTS_PAYABLE) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    showCheckPopup: false,
                },
                fireAction: (record.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()
                        && record.processTypeId === ProcessType.ACCOUNTS_PAYABLE) ? onCloseCheck : null,
                fireActionParam: saveSuccess,
            });

            history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.ACCOUNTS_PAYABLE}`);
        } else {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    showCheckPopup: false,
                    isDirty: false,
                },
            });

            history.push(`/${modules.ACCOUNTING}/${AccountingSubModules.BANKING}`);
        }
    };

    const onPopupClose = (event, forceClose = false) => {
        if ((isDirty || isDirtyFiles) && !forceClose) {
            dispatch(
                {
                    type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
                },
            );
            return;
        }

        onClearForm();
        onCloseAction();
    };

    const isDuplicated = (data) => !isEmpty(data.filter((item) => item.extensions && item.extensions.code === ErrorCodes.DUPLICATE));

    const createQuery = comeFromAccountPayable ? AccountingMutation.CREATE_UPDATE_CHECK_FROM_INVOICE : AccountingMutation.CREATE_UPDATE_CHECK;
    const [addRecord, { loading: isSaving }] = useMutation(createQuery, {
        onCompleted: (mutationData) => {
            const aliasMethod = comeFromAccountPayable ? 'createUpdateCheckFromInvoice' : 'createUpdateCheck';
            if (mutationData[aliasMethod] > 0) {
                ModalUtils.successMessage(null, 'Successfully saved!');
            }
        },
        onError: ({ graphQLErrors }) => {
            if (isDuplicated(graphQLErrors)) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD_VALUE,
                    value: { key: 'showDialogDuplicateCheck', value: true },
                });
            } else {
                ModalUtils.errorMessage(graphQLErrors);
            }
        },
    });

    const onSave = async (requestedNew) => {
        let resultSave = false;
        let recordToSave = { ...record };

        if ((recordToSave.isPaymentRefund || recordToSave.isRepoRefund) && customerMapSetting) {
            recordToSave.refundAccount = Number(customerMapSetting[0].controlNumber);
        } else recordToSave.refundAccount = null;

        const { records, errors: glLinesErrors } = journalRef.current.getRecords();

        if (records.length === 0) {
            ModalUtils.errorMessage(null, 'The GL lines are empty');
            return;
        }

        if (glLinesErrors.length > 0) {
            ModalUtils.errorMessage(glLinesErrors);
            return;
        }

        recordToSave.accountingLines = records;

        const linesSumAmount = records.reduce((sum, next) => sum + next.amount, 0);
        if (NumberUtils.round(record.amount) !== NumberUtils.round(linesSumAmount)) {
            ModalUtils.errorMessage([{ message: 'The sum of the lines must be equals to the total amount of the check.' }]);
            return;
        }

        recordToSave = AccountingCBMapper.mapCheckToSave(recordToSave);

        const resultAfterSave = await addRecord({
            variables: {
                record: recordToSave,
            },
        });

        const aliasMethod = comeFromAccountPayable ? 'createUpdateCheckFromInvoice' : 'createUpdateCheck';
        const createdCbId = resultAfterSave?.data[aliasMethod];
        resultSave = createdCbId > 0;

        if (resultSave) {
            if (!resultSave && record.cbId === 0) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        ...record,
                        cbId: createdCbId,
                    },
                    isDirty: false,
                });
            }
        }

        if (resultSave) {
            if (!requestedNew) onCloseAction(true);
            else {
                dispatch({
                    type: ACTION_TYPES.SET_SAVE_AND_NEW_CHECK,
                    value: initialState,
                });

                journalRef.current.clear();
                getLastCheckOrACH(recordToSave);

                getEndingBalance({
                    variables: {
                        bankAccount: Number(recordToSave.bankAccount),
                    },
                });
            }
        }
    };

    const onChangeValue = (field, value) => {
        if (field === 'amount') {
            dispatch({
                type: ACTION_TYPES.SET_AMOUNT,
                value,
            });
            return;
        }

        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        if (field === 'isPaymentRefund' || field === 'isRepoRefund') {
            if (field === 'isPaymentRefund' && value) currentRecord.isRepoRefund = false;
            if (field === 'isRepoRefund' && value) currentRecord.isPaymentRefund = false;

            getCustomerList({
                variables: {
                    isRepoRefund: currentRecord.isRepoRefund,
                    isPaymentRefund: currentRecord.isPaymentRefund,
                },
            });
        }

        if (field === 'bankAccount' || field === 'isACH' || field === 'checkNumberUpdate') {
            if (accountAlreadySelected?.bankAccount > 0
                && accountAlreadySelected?.bankAccount === currentRecord.bankAccount
                && accountAlreadySelected.isACH === currentRecord.isACH) {
                currentRecord.checkNumber = accountAlreadySelected.checkNumber;
            } else {
                getLastCheckOrACH(currentRecord);
                getEndingBalance({
                    variables: {
                        bankAccount: Number(currentRecord.bankAccount),
                    },
                });
            }
        }

        if (field === 'type') {
            loadPaidToList(value);
            // reset every time that check type change
            currentRecord.paidTo = '';
            currentRecord.paidToId = 0;
        }

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangePaidToValue = (value, controlNumber, amount) => {
        if (controlNumber && customerMapSetting) {
            const setting = customerMapSetting[0];
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    customerMapSetting: [
                        {
                            ...setting,
                            controlNumber,
                            credit: amount,
                        }],
                    record: {
                        ...record,
                        ...value,
                    },
                },
            });
        } else {
            dispatch({
                type: ACTION_TYPES.SET_RECORD_CHANGED,
                value: {
                    ...record,
                    ...value,
                },
            });
        }
    };

    useEffect(() => {
        if (id > 0) {
            dispatch({
                type: ACTION_TYPES.SET_RECORD,
                value: {
                    ...record,
                    cbId: Number(id),
                },
            });

            getCheck({
                variables: {
                    cbId: Number(id),
                },
            });
        }
        getDefaultBankSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (comeFromInvoice) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    showCheckPopup: comeFromInvoice,
                    record: {
                        ...record,
                        processTypeId: comeFromInvoice ? ProcessType.ACCOUNTS_PAYABLE : 0,
                        paidToId: billData.vendorId,
                        paidTo: billData.vendorName,
                        entityAddress: billData.vendorAddress,
                        type: CheckTypes.VENDOR,
                    },
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comeFromInvoice]);

    const getStatusChip = () => {
        if (record?.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()) return null;

        let badgeStatus = classes.primary;
        if (record?.status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase()) badgeStatus = classes.badgeApproved;
        else if (record?.status.toLowerCase() === AccountingCBStatus.VOID.toLowerCase()) badgeStatus = classes.badgeVoid;
        else if (record?.status.toLowerCase() === AccountingCBStatus.CLEARED.toLowerCase()) badgeStatus = classes.badgeCleared;
        else if (record?.status.toLowerCase() === AccountingCBStatus.PRINTED.toLowerCase()) badgeStatus = classes.badgePrinted;

        return (
            <div className={classes.margin5}>
                <span className={classes.fontSmall}>Status: </span>
                <Chip size="small" label={record.status} className={badgeStatus} />
            </div>
        );
    };

    const loadingPaidTo = loadingVendor || loadingCustomer || loadingEmployee;

    const getAsyncOptionsFiltered = (inputValue) => new Promise((resolve) => {
        const filtered = paidToList.filter((data) => data.paidTo.toLowerCase().includes(inputValue.toLowerCase()));
        resolve(filtered.slice(0, paidToList.length));
    });

    const enableBtnSave = (isValid && isDirty
        && allowSaveAction
        && !isSaving
    ) || (
        allowSaveAction
            && !isSaving
            && isDirtyFiles
    );

    const onDuplicateClose = () => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD_VALUE,
            value: { key: 'showDialogDuplicateCheck', value: false },
        });
    };

    const onDuplicateContinue = () => {
        getLastCheckOrACH(record);
        onDuplicateClose();
    };

    return (
        <Dialog
            open={showCheckPopup}
            onClose={onPopupClose}
            aria-labelledby="form-dialog-title"
            fullScreen
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <div className={classes.buttonSpacing}>
                        {ACCOUNTING_BANKING_CHECK_WRITE
                        && allowSaveAction
                        && (record.status.toLowerCase() !== AccountingCBStatus.VOID.toLowerCase())
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(false)}
                                    >
                                        Save
                                    </Button>
                                )}
                        {ACCOUNTING_BANKING_CHECK_WRITE
                        && !comeFromAccountPayable
                        && !record.processTypeId
                        && allowSaveAction
                        && (record.status.toLowerCase() === AccountingCBStatus.NEW.toLowerCase()
                                || record.status.toLowerCase() === AccountingCBStatus.POSTED.toLowerCase()
                                || record.status.toLowerCase() === AccountingCBStatus.APPROVED.toLowerCase())
                                && (
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!enableBtnSave}
                                        onClick={() => onSave(true)}
                                    >
                                        Save & New
                                    </Button>
                                )}
                    </div>
                    <div className={classes.centerItems}>
                        <Typography variant="h6" className={classes.title}>
                            { record.cbId === 0 ? 'New Check' : `Edit Check ${record.checkNumber}` }
                        </Typography>
                        { getStatusChip() }
                        <IconButton edge="start" color="inherit" onClick={onPopupClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                { loading ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Split
                                sizes={isMobile ? [70, 30] : [35, 65]}
                                className={isMobile ? classes.verticalSplit : 'split'}
                                direction={isMobile ? null : 'horizontal'}
                            >
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={isMobile ? 12 : 5}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Pay From *</Form.Label>
                                                <DropdownQuery
                                                    name="bankAccount"
                                                    disabled={!editBankAccount}
                                                    className={isValidField(errors, 'bankAccount') ? 'invalid-field' : ''}
                                                    value={record.bankAccount}
                                                    placeHolder="select the account"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                                        variables: {
                                                            paginate: {
                                                                init: 0,
                                                                ignoreLimit: true,
                                                            },
                                                            filters: {
                                                                accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                                            },
                                                        },
                                                        rootData: 'getAccountingCOAList.data',
                                                        idField: 'accountNumber',
                                                        descriptionField: 'fullDescription',
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Check Type *</Form.Label>
                                                <DropdownQuery
                                                    name="type"
                                                    disabled={!editCheckType}
                                                    className={isValidField(errors, 'type') ? 'invalid-field' : ''}
                                                    value={record.type}
                                                    placeHolder="select"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        localData: checkTypeData,
                                                        idField: 'type',
                                                        descriptionField: 'type',
                                                    }}
                                                />
                                            </Form.Group>
                                            {record.type === CheckTypes.CUSTOMER
                                    && (
                                        <Form.Group as={Col}>
                                            <Form.Group as={Row}>
                                                <Form.Check
                                                    id="isPaymentRefund"
                                                    checked={record.isPaymentRefund}
                                                    disabled={!editPaymentRefund}
                                                    type="checkbox"
                                                    label="Payment Refund"
                                                    className={clsx('am-mt-8', classes.marginleft20)}
                                                    onChange={(e) => onChangeValue('isPaymentRefund', e.target.checked)}
                                                />
                                                <Form.Check
                                                    id="isRepoRefund"
                                                    checked={record.isRepoRefund}
                                                    disabled={!editRepoRefund}
                                                    type="checkbox"
                                                    label="Repo Refund"
                                                    className={clsx('am-mt-8', classes.marginleft20)}
                                                    onChange={(e) => onChangeValue('isRepoRefund', e.target.checked)}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                    )}
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Pay To *</Form.Label>
                                                <AsyncCreatableSelect
                                                    isValidNewOption={() => !(useAccountNumber)}
                                                    defaultOptions={paidToList}
                                                    getOptionLabel={(option) => option.paidTo ?? ''}
                                                    getOptionValue={(option) => option.paidToId}
                                                    isClearable
                                                    className={clsx('form-control select-sm',
                                                        classes.selectSM, isValidField(errors, 'paidTo') ? 'invalid-field' : '')}
                                                    classNamePrefix="select"
                                                    getNewOptionData={(newValue) => ({ paidToId: 0, paidTo: newValue })}
                                                    loadOptions={(inputValue) => getAsyncOptionsFiltered(inputValue)}
                                                    isLoading={loadingPaidTo}
                                                    isDisabled={loadingPaidTo || !editPayTo}
                                                    value={!record.paidTo ? null : { paidToId: record.paidToId, paidTo: record.paidTo }}
                                                    onChange={(newValue) => {
                                                        onChangePaidToValue({
                                                            paidToId: newValue?.paidToId ?? 0,
                                                            paidTo: useAccountNumber ? newValue?.customerName ?? '' : newValue?.paidTo ?? '',
                                                            entityAddress: newValue?.address ?? '',
                                                            ...(!id ? { is1099: newValue?.is1099 ?? false } : {}),
                                                        }, newValue?.controlNumber ?? '', newValue?.paidToAmount ?? 0);
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Lot</Form.Label>
                                                <DropdownQuery
                                                    name="lotId"
                                                    disabled={!editLot}
                                                    className={isValidField(errors, 'lotId') ? 'invalid-field' : ''}
                                                    value={record.lotId}
                                                    placeHolder="select a lot"
                                                    onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                    dataSource={{
                                                        query: LotQuery.GET_LOTS,
                                                        rootData: 'lotList',
                                                        idField: 'lotId',
                                                        descriptionField: 'lotName',
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={isMobile ? 12 : 7}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Check #</Form.Label>
                                                        <InputGroup className="mb-2">
                                                            <Form.Control
                                                                className={isValidField(errors, 'checkNumber') ? 'invalid-field' : ''}
                                                                size="sm"
                                                                type="number"
                                                                value={record.checkNumber}
                                                                disabled={!(editCheckNumber && state.editableCheckNumbers)}
                                                                onChange={(e) => onChangeValue('checkNumber', e.target.value)}
                                                                placeholder="Check #"
                                                            />
                                                            <If condition={editCheckNumber && state.editableCheckNumbers}>
                                                                <Tooltip title="Get last check number" placement="top-start">
                                                                    <InputGroup.Text className="no-padding">
                                                                        <IconButton
                                                                            size="small"
                                                                            disableFocusRipple
                                                                            color="inherit"
                                                                            onClick={() => onChangeValue('checkNumberUpdate', record.checkNumber)}
                                                                        >
                                                                            <AutorenewIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </InputGroup.Text>
                                                                </Tooltip>
                                                            </If>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Amount</Form.Label>
                                                        <InputNumber
                                                            disabled
                                                            size="sm"
                                                            showCurrency
                                                            allowNegative
                                                            value={record.amount}
                                                            thousandSeparator
                                                            placeholder="Amount"
                                                            onChange={(value) => onChangeValue('amount', value)}
                                                        />
                                                    </Form.Group>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="required">Date *</Form.Label>
                                                        <DatePicker
                                                            selected={record.date}
                                                            disabled={!editDate}
                                                            name="date"
                                                            size="sm"
                                                            className={clsx('form-control form-control-sm',
                                                                isValidField(errors, 'date') ? 'invalid-field' : '')}
                                                            placeholderText="mm/dd/yyyy"
                                                            popperContainer={CalendarContainer}
                                                            wrapperClassName="col"
                                                            onChange={(date) => onChangeValue('date', date)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label />
                                                        <Form.Check
                                                            id="isACH"
                                                            checked={record.isACH}
                                                            type="checkbox"
                                                            label="ACH / WT"
                                                            className="am-mt-8"
                                                            disabled={!editACH}
                                                            onChange={(e) => onChangeValue('isACH', e.target.checked)}
                                                        />
                                                        <Form.Check
                                                            id="is1099"
                                                            checked={record.is1099}
                                                            type="checkbox"
                                                            label="1099"
                                                            className="am-mt-8"
                                                            disabled={record.status === AccountingCBStatus.VOID}
                                                            onChange={(e) => onChangeValue('is1099', e.target.checked)}
                                                        />
                                                    </Form.Group>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label className="required">Ending Balance</Form.Label>
                                                    <InputNumber
                                                        disabled
                                                        size="sm"
                                                        showCurrency
                                                        value={record.endingBalance}
                                                        thousandSeparator
                                                        placeholder="Ending balance"
                                                        allowNegative
                                                    />
                                                </Form.Group>
                                            </Grid>
                                            {showInventoryPurchaseOptions && (
                                                <Form.Group as={Col}>
                                                    <Form.Label />
                                                    <Form.Group as={Row}>
                                                        <Form.Check
                                                            id="isVehiclePurchase"
                                                            checked={record.isVehiclePurchase}
                                                            type="checkbox"
                                                            label="Purchase"
                                                            className={clsx('am-mt-8', classes.marginleft20)}
                                                            onChange={(e) => onChangeValue('isVehiclePurchase', e.target.checked)}
                                                        />
                                                        <Form.Check
                                                            id="isRepairExpense"
                                                            checked={!record.isVehiclePurchase}
                                                            type="checkbox"
                                                            label="Repair / Expense"
                                                            className={clsx('am-mt-8', classes.marginleft20)}
                                                            onChange={(e) => onChangeValue('isVehiclePurchase', !e.target.checked)}
                                                        />
                                                    </Form.Group>
                                                </Form.Group>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Address</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={!editAddress}
                                                    className={isValidField(errors, 'entityAddress') ? 'invalid-field' : ''}
                                                    size="sm"
                                                    rows="2"
                                                    value={record.entityAddress || ''}
                                                    placeholder="Address"
                                                    onChange={(e) => onChangeValue('entityAddress', e.target.value)}
                                                />
                                                <p className={classes.fontSmall}>{getErrorMessage('entityAddress', errors)}</p>
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <Form.Label className="required">Memo</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={!editMemo}
                                                    className={isValidField(errors, 'memo') ? 'invalid-field' : ''}
                                                    size="sm"
                                                    rows="2"
                                                    value={record.memo || ''}
                                                    placeholder="Memo"
                                                    onChange={(e) => onChangeValue('memo', e.target.value)}
                                                />
                                                <p className={classes.fontSmall}>{getErrorMessage('memo', errors)}</p>
                                            </Form.Group>
                                        </Grid>
                                    </Grid>
                                    {Number(record.cbId) > 0 && (
                                        <Grid item xs={12}>
                                            <Form.Group as={Col}>
                                                <FileManagerContainer
                                                    verticalMode
                                                    documentsProps={[
                                                        {
                                                            label: 'Files Attachments',
                                                            referenceId: Number(record.cbId),
                                                            referenceType: ProcessTypeDescription.CHECK,
                                                            canDelete: ACCOUNTING_BANKING_CHECK_WRITE,
                                                            canWrite: ACCOUNTING_BANKING_CHECK_WRITE,
                                                            ...AccountingUtils.getAccountingDocumentProps(),
                                                            ...AccountingUtils.getCheckDocumentProps(),
                                                        },
                                                    ]}
                                                    showPopupPreview
                                                />
                                            </Form.Group>
                                        </Grid>
                                    )}
                                </div>
                                <JournalDistribution
                                    processRecordId={record.cbId}
                                    processTypeId={comeFromAccountPayable ? ProcessType.ACCOUNTS_PAYABLE : ProcessType.CHECK}
                                    writePermissions={ACCOUNTING_BANKING_CHECK_WRITE}
                                    ref={journalRef}
                                    onAmountChange={(value) => {
                                        onChangeValue('amount', value);
                                    }}
                                    onInventoryPurchaseLineAdded={(value) => dispatch({
                                        type: ACTION_TYPES.SET_RECORD_VALUE,
                                        value: { key: 'showInventoryPurchaseOptions', value },
                                    })}
                                    onDirty={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'isDirty', value: true } })}
                                    glOptions={glOptions}
                                    glLines={billData ? billData.glLines : customerMapSetting}
                                    transactionAmount={record.amount}
                                    transactionType={TransactionType.CREDIT}
                                    accountTypesExcluded={[AccountingCOAType.BANK]}
                                />

                            </Split>
                            <ConfirmDialog
                                title="Attention - You have unsaved changes!"
                                description="Do you want to close without saving?"
                                open={couldLostData}
                                variant="outlined"
                                titlePrimary="Close"
                                titleSecondary="Cancel"
                                onClose={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickSecondary={() => dispatch({ type: ACTION_TYPES.SET_RECORD_VALUE, value: { key: 'couldLostData', value: false } })}
                                onClickPrimary={(e) => onPopupClose(e, true)}
                            />
                            <ConfirmDialog
                                title="Duplicate check number!"
                                description="This check number has already been used. It will be changed to continue."
                                open={showDialogDuplicateCheck}
                                variant="outlined"
                                titlePrimary="OK"
                                titleSecondary="Cancel"
                                onClose={onDuplicateClose}
                                onClickSecondary={onDuplicateClose}
                                onClickPrimary={onDuplicateContinue}
                            />
                        </Container>
                    )}
            </DialogContent>
            {isSaving && <DialogActionMessage message="Saving information... " />}
        </Dialog>
    );
};

CheckCreate.propTypes = {
    comeFromInvoice: PropTypes.bool,
    onCloseCheck: PropTypes.func,
    billData: PropTypes.object,
};

CheckCreate.defaultProps = {
    comeFromInvoice: false,
    onCloseCheck: () => {},
    billData: null,
};

export default CheckCreate;

import { gql } from '@apollo/client';

export default class ProcessFilesQuery {
    static GET_FILES_BY_PROCESS = gql`
        query getDocumentByReference($referenceId: Int!, $referenceType: String!) {
            getDocumentByReference(referenceId: $referenceId, referenceType: $referenceType) {
                documentId
                referenceId
                referenceType
                documentType
                documentSize
                documentUrl
                documentName
                isSecureUrl
            }
        }
    `;

    static UPLOAD_FILES = gql`
    mutation uploadFilesByReference($referenceId: Int!, $referenceType: String!, $file: Upload!, $fileName: String) {
        uploadFilesByReference(file: $file, referenceId: $referenceId, referenceType: $referenceType, fileName: $fileName){
            documentId
            referenceId
            referenceType
            documentUrl
        }
    }
    `;

    static DELETE_DOCUMENT = gql`
    mutation deleteFilesByReference($documentId: Int!, $referenceId: Int!, $referenceType: String!) {
        deleteFilesByReference(documentId: $documentId, referenceId: $referenceId, referenceType: $referenceType)
    }
    `;

    static GET_DOCUMENT_SECURE_URL = gql`
    query getDocumentByReferenceSecureURL($path: String!) {
        getDocumentByReferenceSecureURL(path: $path)
    }
    `

    static GET_DOCUMENT_UPLOAD_SIGNED_URL = gql`
    query getDocumentUploadSignedURL($referenceId: Int!, $fileName: String!, $referenceType: String!) {
        getDocumentByReferenceUploadSignedURL(referenceId: $referenceId, fileName: $fileName, referenceType: $referenceType) {
            url
            path
        }
    }
    `
}

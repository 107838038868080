import React from 'react';
import {
    AppBar, Dialog, DialogContent, IconButton, makeStyles, Toolbar, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FilePreviewStyles from 'styles/theme/Files/FilePreview';
import Loading from 'components/widgets/Loading';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => FilePreviewStyles.getStyle(theme));

const FilePreviewerPopup = ({ state, setState }) => {
    const classes = useStyles();

    const onClosePopup = () => {
        setState({
            ...state, previewURL: '', previewTitle: null, onOpenPopup: false,
        });
    };

    return (
        <Dialog
            open={state.onOpenPopup}
            onClose={onClosePopup}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
        >
            <AppBar className={classes.appBarMove}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        {`Document Preview ${state.previewTitle}`}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onClosePopup} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                <div>
                    {!state.previewURL && !state.isProcessingFile && (
                        <div>
                            Click on a document&apos;s name to preview
                        </div>
                    )}
                    {state.previewURL && state.previewExt === 'pdf' && (
                        <iframe
                            className={classes.iframe}
                            title="Preview"
                            src={`${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${state.previewURL}`}
                            frameBorder="0"
                            allowFullScreen
                        />
                    )}
                    {state.previewURL && state.previewExt !== 'pdf' && (
                        <img
                            className={classes.innerImage}
                            alt="Preview"
                            src={state.previewURL}
                        />
                    )}
                    {state.isProcessingFile && (
                        <Loading className={classes.loader} />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

FilePreviewerPopup.propTypes = {
    state: PropTypes.shape({
        previewTitle: PropTypes.string || null,
        previewURL: PropTypes.string,
        previewExt: PropTypes.string,
        isProcessingFile: PropTypes.bool,
        onOpenPopup: PropTypes.bool,
    }),
    setState: PropTypes.func.isRequired,
};

FilePreviewerPopup.defaultProps = {
    state: {
        previewTitle: null,
        previewURL: '',
        previewExt: '',
        isProcessingFile: false,
        onOpenPopup: false,
    },
};
export default FilePreviewerPopup;

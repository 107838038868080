import ButtonStyles from 'styles/theme/Button';

export default class FormTab {
    static formPacks(theme = {}) {
        return ({
            buttonActions: {
                borderRadius: theme.spacing(0.5),
                border: `1px solid ${theme.palette.border.primary}`,
                padding: 0,
                height: theme.spacing(2.2),
            },
            main: {
                width: '100%',
                boxShadow: `0px 1px 4px rgba(${theme.palette.rgb.black}, 0.1)`,
            },
            title: {
                color: theme.palette.text.boulderGray,
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: theme.spacing(5),
                padding: theme.spacing(0, 2),
            },
            contentItem: {
                height: theme.spacing(4.2),
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 2),
                border: `1px solid ${theme.palette.border.primary}`,
                borderTop: 'none',
                cursor: 'pointer',
                justifyContent: 'space-between',

                '&:first-child': {
                    borderTop: `1px solid ${theme.palette.border.primary}`,
                },
                '&.active': {
                    backgroundColor: theme.palette.background.snowyMint,
                },
                '& .actions button': {
                    display: 'none',
                },
                '&.active:hover .actions .play-arrow': {
                    display: 'none',
                },
                '&.active:hover .actions .active.play-arrow': {
                    display: 'inline-flex',
                },
                '&:hover .actions button': {
                    display: 'flex',
                },
                '& .actions .play-arrow': {
                    color: theme.palette.info.main,
                },
            },
            item: {
                color: theme.palette.text.boulderGray,
            },
            body: {
                flex: 1,
                overflow: 'auto',
            },
            button: {
                minWidth: 'auto',
                height: theme.spacing(2.2),
                padding: 0,
            },
        });
    }

    static formsTab(theme = {}) {
        return ({
            root: {
                background: theme.palette.background.white,
                flexGrow: 1,
                '& .gridItem': {
                    display: 'flex',
                    height: '100%',
                    overflow: 'hidden',
                    padding: theme.spacing(2, 3),
                },
                '& .gridItem:last-child': {
                    padding: 0,
                },
            },
        });
    }

    static formDetail(theme = {}) {
        return ({
            tabPanel: {
                background: theme.palette.background.white,
                flex: 1,
                height: '100%',
                overflow: 'hidden',
            },
            box: {
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
            },
            tab: {
                height: '100%',
            },
            navbar: {
                '& > a': {
                    color: theme.palette.text.link,
                    'text-decoration': 'none',
                    'background-color': 'transparent',
                    'line-height': '1.5',
                },
            },
            borderContainer: {
                '& + div': {
                    boxShadow: `0px 1px 4px rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginLeft: '0.5px',
                },
            },
            containerDetail: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            },
            footer: {
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: theme.spacing(0, 2),
                boxShadow: `0px 1px 4px rgba(${theme.palette.rgb.black}, 0.1)`,
            },
            printButton: {
                background: theme.palette.background.purpleHeart,
                color: theme.palette.text.white,
                boxShadow: 'none',
                '&:hover': {
                    background: theme.palette.background.purpleHeart,
                    boxShadow: 'none',
                },
            },
            save: {
                background: theme.palette.background.eucalyptus,
                color: theme.palette.text.white,
                boxShadow: 'none',
                '&:hover': {
                    background: theme.palette.background.forestGreen,
                    boxShadow: 'none',
                },
            },
            tabContainer: {
                height: 'calc(100% - 60px)',
            },
            search: {
                marginRight: theme.spacing(2),
                width: 300,
                padding: '5px',
            },
            sortingDropdown: {
                width: 150,
                marginLeft: 4,
            },
            sortingDiv: {
                right: 38,
                paddingTop: 1,
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
            },
        });
    }

    static formPacksTab(theme = {}) {
        return ({
            checkbox: {
                marginRight: 0,
                '& .form-check-input': {
                    width: '16px',
                    height: '16px',
                },
            },
            print: {
                color: theme.palette.background.wildBlueYonder,
            },
            preview: {
                position: 'relative',
            },
            ...ButtonStyles.getStyle(theme),
        });
    }

    static formLibraryTab(theme = {}) {
        return ({
            columnStyle: {
                textAlign: 'center',
            },
            print: {
                color: theme.palette.background.wildBlueYonder,
            },
        });
    }

    static createFormPacks(theme = {}) {
        return ({
            contentItem: {
                height: theme.spacing(4.2),
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 2),
                border: `1px solid ${theme.palette.border.primary}`,
                borderTop: 'none',
                cursor: 'pointer',
                justifyContent: 'space-between',
            },
            newForm: {
                height: theme.spacing(3),
                borderRadius: 0,
                fontSize: '14px',
                padding: theme.spacing(0.5),
                paddingRight: theme.spacing(8),
                outline: 0,
                '&:focus': {
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.border.primary}`,
                },
            },
            saveForm: {
                position: 'absolute',
                right: 0,
                padding: 0,
            },
            inputButton: {
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            },
            errorIcon: {
                position: 'absolute',
                right: theme.spacing(0.5),
                fontSize: '18px',
                color: theme.palette.error.main,
            },
        });
    }
}

// eslint-disable-next-line import/prefer-default-export
export const EmployeeType = {
    SALESMAN: 'Salesman',
    SALES_MANAGER: 'Sales Manager',
    FINANCE_MANAGER: 'Finance Manager',
    BDC_SALES: 'BDC Sales',
    ASSISTANT_BDC_MANAGER: 'Assistant BDC Manager',
    PARTS_CLERK: 'Parts Clerk',
    TECHNICIAN: 'Technician',
    SERVICE_ADVISOR: 'Service Advisor',
    OWNER: 'Owner',
};

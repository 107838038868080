export default class AutomatedResponseStyle {
    static content(theme) {
        return ({
            box: {
                height: '85% !important',
                marginTop: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                '& > div > button': {
                    marginRight: '10px',
                },
            },
            boxContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
            },
            paddingLeft25: {
                paddingLeft: '25px',
                paddingRight: '25px',
            },
            sequenceTop: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& > div': {
                    alignItems: 'center',
                    marginBottom: 'initial',
                    marginRight: 0,
                    '& > span': {
                        padding: 0,
                    },
                    '& > label': {
                        marginBottom: 0,
                    },
                },
            },
            otherActions: {
                display: 'flex',
                flexDirection: 'column',
                width: '100px',
                flexShrink: 0,
                '& > button:nth-child(1)': {
                    marginBottom: '2px',
                },
                [theme.breakpoints.down('sm')]: {
                    '& > button:nth-child(1)': {
                        marginBottom: '5px',
                    },
                },
            },
            tableGrid: {
                overflowX: 'auto',
                overflowY: 'hidden',
            },
            followUp: {
                paddingLeft: '15px',
                paddingRight: '15px',
                marginTop: '10px',
                fontSize: '13px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& > div:nth-child(2)': {
                    display: 'flex',
                    alignItems: 'center',
                    '& > button': {
                        marginTop: '6px',
                    },
                    '& > div > div': {
                        display: 'flex',
                        alignItems: 'center',
                        '& > div.react-datepicker-wrapper': {
                            width: '100px',
                        },
                    },
                },
            },
            exclusionsTableContainer: {
                minWidth: '600px !important',
                height: '400px !important',
            },
            innerTableContainer: {
                minWidth: '1200px !important',
            },
            tableContainer: {
                marginTop: '15px',
                paddingLeft: '15px',
                paddingRight: '15px',
                height: '250px',
                overflow: 'hidden',
                minWidth: '1400px',
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    justifyContent: 'left',
                    padding: '7px 5px',
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                    display: 'flex',
                    '& > .MuiTextField-root': {
                        width: '90%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    },
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            tableHeader: {
                textAlign: 'left',
                color: theme.palette.text.waterloo,
                borderRight: `1px solid ${theme.palette.border.ghost}`,
                height: '100%',
                alignItems: 'center',
            },
            actionsContainer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& > button:nth-child(1)': {
                    marginRight: '2px',
                },
                '& > button': {
                    minWidth: '32px',
                    '& .MuiButton-startIcon': {
                        marginRight: '0px',
                    },
                },
            },
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    fontSize: '15px',
                    color: theme.palette.text.white,
                },
            },
            followUpContent: {
                minWidth: '400px',
                padding: '10px 10px',
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
            },
            content: {
                padding: '10px 10px',
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
                '& > div:nth-child(1)': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                    },
                    '& > div:nth-child(1)': {
                        display: 'flex',
                        flexDirection: 'row',
                        width: '80%',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            flexDirection: 'column',
                        },
                        '& > div:nth-child(1)': {
                            flexBasis: 'initial',
                            flexGrow: 'initial',
                            maxWidth: 'initial',
                            width: '50%',
                            [theme.breakpoints.down('sm')]: {
                                width: '100%',
                            },
                        },
                        '& > div:nth-child(1) > input': {
                            height: '38px',
                        },
                    },
                    '& > div:nth-child(2)': {
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            justifyContent: 'flex-start',
                            marginLeft: '25px',
                        },
                        '& > div': {
                            margin: 0,
                            paddingRight: '15px',
                            '& > label': {
                                marginLeft: '5px',
                                marginBottom: 0,
                                marginTop: '2px',
                            },
                        },
                    },
                },
            },
            dialogActions: {
                paddingRight: '45px',
                '& > .form-group': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '10px',
                },
            },
            dialogLabels: {
                fontSize: '13px',
            },
            input: {
                fontSize: '13px',
                resize: 'none',
                width: '100%',
            },
            '@global': {
                '.css-26l3qy-menu div': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
            },
            text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            execution: {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                '& > input': {
                    width: '70px',
                    marginRight: '10px',
                    height: '38px',
                },
                '& > div': {
                    flexGrow: 1,
                },
            },
            autocomplete: {
                borderRadius: '3px',
                minHeight: '40px',
                paddingLeft: '10px',
                paddingRight: '10px',
                border: `1px solid ${theme.palette.border.ghost}`,
            },
            message: {
                fontSize: '13px',
                fontWeight: 500,
                paddingLeft: '15px',
                paddingRight: '15px',
                marginTop: '20px',
            },
            keywords: {
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    marginTop: '10px',
                },
                '& > div:nth-child(1), & > button': {
                    width: '10%',
                    maxWidth: 'initial',
                    flexBasis: 'initial',
                    flexGrow: 'initial',
                    margin: 0,
                    paddingRight: 0,
                    '& > div > div': {
                        minHeight: '40px',
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                },
                '& > div:nth-child(2), & > input': {
                    width: '90%',
                    flexGrow: 'initial',
                    maxWidth: 'initial',
                    flexBasis: 'initial',
                    margin: 0,
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        marginBottom: '5px',
                        paddingRight: 0,
                    },
                },
                '& > input': {
                    marginRight: '5px',
                },
            },
            leftSection: {
                flexGrow: 1,
                '& > div': {
                    padding: 0,
                },
                [theme.breakpoints.down('sm')]: {
                    '& > div:nth-child(2)': {
                        justifyContent: 'center',
                        marginBottom: '10px',
                    },
                },
            },
            leftSectionTop: {
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
                '& > div:nth-child(1), & > div:nth-child(2)': {
                    maxWidth: '250px',
                },
                '& > div': {
                    paddingLeft: 0,
                    paddingRight: '10px',
                },
                '& > div:nth-child(1) > div, & > div:nth-child(2) > div > div': {
                    width: '100%',
                },
                '& > div:nth-child(3) > div': {
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    },
                },
                '& > div:nth-child(3) > div > div': {
                    width: '100px',
                },
                '& > div:nth-child(3) > div > div:nth-child(3)': {
                    display: 'flex',
                    marginLeft: '5px',
                    [theme.breakpoints.down('sm')]: {
                        marginLeft: 0,
                    },
                    '& > label': {
                        marginLeft: '5px',
                        marginBottom: 0,
                        marginTop: '3px',
                        textWrap: 'nowrap',
                    },
                },
            },
            search: {
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '15px',
                '& > div': {
                    width: '250px',
                },
            },
            otherSection: {
                display: 'flex',
                marginTop: '15px',
                marginBottom: '15px',
                flexDirection: 'column',
                marginLeft: '15px',
                marginRight: '15px',
                borderTop: `1px solid ${theme.palette.border.ghost}`,
                paddingTop: '5px',
                paddingBottom: '5px',
                borderBottom: `1px solid ${theme.palette.border.ghost}`,
                '& > div:nth-child(1)': {
                    fontSize: '13px',
                    fontWeight: 500,
                    marginBottom: '15px',
                },
                '& > div:nth-child(2)': {
                    display: 'flex',
                    marginBottom: '10px',
                    '& > div': {
                        width: '250px',
                        marginLeft: '10px',
                        '& > div': {
                            minHeight: '30px',
                        },
                    },
                },
            },
            topSection: {
                display: 'flex',
            },
            textArea: {
                marginRight: theme.spacing(2),
                resize: 'none',
                fontSize: '13px',
            },
            listFields: {
                overflow: 'auto',
                height: '400px',
                border: `1px solid ${theme.palette.border.mercuryLight}`,
                borderRadius: '5px',
                '& li': {
                    borderTop: `1px solid ${theme.palette.border.mercuryLight}`,
                    padding: theme.spacing(1, 2),
                    cursor: 'pointer',
                    fontSize: '13px',
                },
            },
        });
    }
}

import { gql } from '@apollo/client';

export default class GeneralQuery {
    static PRINT_DOCUMENT = gql`
        query printDocument(
            $title: String,
            $source: DocumentSource!,
            $type: String!,
            $filters: [DocumentFilter!],
            $columnsWithTotal: [String!]
        ) {
            printDocument(
                title: $title,
                source: $source,
                type: $type,
                filters: $filters,
                columnsWithTotal: $columnsWithTotal
            ) {
                documentType
                data
            }
        }
    `;

    static PULL_COMMON_RESOURCES = gql`
        query pullCommonResources(
            $module: String!,
            $section: String!
        ) {
            pullCommonResources(
                module: $module,
                section: $section
            ) {
                commonResourceId
                module
                section
                resourceName
                resourceURL
            }
        }
    `;

    static PULL_TRAINING_CATEGORIES = gql`
    query pullTrainingCategories($isSettings: Boolean) {
        pullTrainingCategories(isSettings: $isSettings) {
            trainingCategoryId
            name
            order
            companyCode
            videos {
                trainingVideoId
                name
                description
                videoLink
                matchingRoute
                order
                companyCode
            }
        }
    }
    `;
}

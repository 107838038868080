import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Split from 'react-split';
import PropTypes from 'prop-types';
import ButtonStyles from 'styles/theme/Button';
import FileManagerContainerStyles from 'styles/theme/Files/FileManagerContainer';
import usePreview from 'components/hook/core/usePreview';
import FileManager from 'components/widgets/filemanager/FileManager';
import FilePreviewer from 'components/widgets/filemanager/FilePreviewer';
import FilePreviewerPopup from 'components/widgets/filemanager/FilePreviewerPopup';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => FileManagerContainerStyles.getStyle(theme));

const renderDocumentSection = (documentsProps, onPreviewDocument, classes) => (
    <>
        <div className={classes.files}>
            <FileManager
                documentProp={documentsProps[0]}
                onPreviewDocument={onPreviewDocument}
            />
        </div>
        {documentsProps.length > 1 && (
            <div>
                <Tabs
                    mountOnEnter
                    className={`${classes.borderContainer} ${classes.navbar}`}
                >
                    {documentsProps.slice(1).map((document, index) => (
                        <Tab
                            key={index + 1}
                            eventKey={index + 1}
                            title={document.label}
                            className={classes.tab}
                            mountOnEnter
                        >
                            <div className={classes.files}>
                                <FileManager
                                    key={`file-manager-${index}`}
                                    documentProp={document}
                                    onPreviewDocument={onPreviewDocument}
                                />
                            </div>
                        </Tab>
                    ))}
                </Tabs>
            </div>
        )}
    </>
);

const FileManagerContainer = ({
    documentsProps, showPreview, verticalMode, showPopupPreview, mobileSplitHeight,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) || verticalMode;

    const { state, onPreviewDocument, setState } = usePreview();

    return (
        <>
            <div className={classes.container}>
                {!isMobile && (
                    <Split
                        sizes={showPreview ? [50, 50] : [100, 0]}
                        minSize={100}
                        gutterSize={10}
                        gutterAlign="center"
                        direction="horizontal"
                        cursor="col-resize"
                        className={classes.splitter}
                    >
                        <>
                            <Split
                                sizes={documentsProps.length > 1 ? [50, 50] : [100, 0]}
                                minSize={300}
                                gutterSize={10}
                                gutterAlign="center"
                                direction="vertical"
                                cursor="row-resize"
                                className={classes.associatedSplitter}
                            >
                                {renderDocumentSection(documentsProps, onPreviewDocument, classes)}
                            </Split>
                        </>
                        {!showPopupPreview && <FilePreviewer state={state} setState={setState} />}
                    </Split>
                )}
                {isMobile && (
                    <>
                        <Split
                            sizes={documentsProps.length > 1 ? [50, 50] : [100, 0]}
                            minSize={300}
                            gutterSize={10}
                            gutterAlign="center"
                            direction="vertical"
                            cursor="row-resize"
                            className={classes.associatedSplitter}
                            style={{ height: mobileSplitHeight }}
                        >
                            {renderDocumentSection(documentsProps, onPreviewDocument, classes)}
                        </Split>
                        {!showPopupPreview && <FilePreviewer state={state} setState={setState} />}
                    </>
                )}
            </div>

            {showPopupPreview && <FilePreviewerPopup state={state} setState={setState} />}
        </>
    );
};

FileManagerContainer.propTypes = {
    documentsProps: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        allowedFiles: PropTypes.string,
        pullDocumentsQuery: PropTypes.any.isRequired,
        getDocumentUploadSignedURL: PropTypes.any.isRequired,
        getDocumentSecureURL: PropTypes.any.isRequired,
        createDocumentsMutation: PropTypes.any.isRequired,
        editDocumentNameMutation: PropTypes.any.isRequired,
        deleteMutation: PropTypes.any.isRequired,
        canWrite: PropTypes.bool.isRequired,
        canDelete: PropTypes.bool.isRequired,
        maxFileSize: PropTypes.number,
        referenceId: PropTypes.number,
        referenceType: PropTypes.string.isRequired,
        referenceName: PropTypes.string.isRequired,
        getAssociatedDocumentUploadSignedURL: PropTypes.object,
        createAssociatedDocumentsMutation: PropTypes.object,
        documentTypeMutation: PropTypes.any.isRequired,
        sendReferenceType: PropTypes.bool,
    })),
    showPreview: PropTypes.bool,
    verticalMode: PropTypes.bool,
    showPopupPreview: PropTypes.bool,
    mobileSplitHeight: PropTypes.string,
};
FileManagerContainer.defaultProps = {
    documentsProps: [
        {
            referenceId: null,
        },
    ],
    showPreview: true,
    verticalMode: false,
    showPopupPreview: false,
    mobileSplitHeight: '400px',
};
export default FileManagerContainer;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from 'components/widgets/Loading';
import PropTypes from 'prop-types';
import FilePreviewStyles from 'styles/theme/Files/FilePreview';

const useStyles = makeStyles((theme) => FilePreviewStyles.getStyle(theme));

const FilePreviewer = ({ state }) => {
    const classes = useStyles();

    return (
        <div
            className={classes.preview}
        >
            <div>
                {`Document Preview ${state.previewTitle}`}
            </div>
            <div>
                {!state.previewURL && !state.isProcessingFile && (
                    <div>
                        Click on a document&apos;s name to preview
                    </div>
                )}
                {state.previewURL && state.previewExt === 'pdf' && (
                    <iframe
                        className={classes.iframe}
                        title="Preview"
                        src={`${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${state.previewURL}`}
                        frameBorder="0"
                        allowFullScreen
                    />
                )}
                {state.previewURL && state.previewExt !== 'pdf' && (
                    <img
                        className={classes.innerImage}
                        alt="Preview"
                        src={state.previewURL}
                    />
                )}
                {state.isProcessingFile && (
                    <Loading className={classes.loader} />
                )}
            </div>
        </div>
    );
};

FilePreviewer.propTypes = {
    state: PropTypes.shape({
        previewTitle: PropTypes.string || null,
        previewURL: PropTypes.string,
        previewExt: PropTypes.string,
        isProcessingFile: PropTypes.bool,
    }),
};

FilePreviewer.defaultProps = {
    state: {
        previewTitle: null,
        previewURL: '',
        previewExt: '',
        isProcessingFile: false,
    },
};
export default FilePreviewer;

import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles, TextField, Button,
    useMediaQuery,
} from '@material-ui/core';
import { useSubscription } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import { useTheme } from '@material-ui/core/styles';
import { ReconStatus } from 'utils/enum/InventoryEnum';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import Switch from '@material-ui/core/Switch';
import If from 'components/widgets/conditional/If';
import InputNumber from 'components/widgets/InputNumber';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import InventoryModuleSubscription from 'services/graphQL/subscription/inventory/InventoryModuleSubscription';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    actionsBar: {
        position: 'absolute',
        bottom: '90px',
        left: 0,
        padding: '3px',
        paddingLeft: '10px',
        paddingRight: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: theme.palette.background.secondary,
        overflow: 'hidden',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            bottom: '79px',
            padding: '10px',
            position: 'fixed',
            minWidth: 'initial',
            overflowX: 'auto',
            '& > div.topBar': {
                alignItems: 'center',
                flexDirection: 'row',
                minWidth: '695px',
                marginRight: '50px',
                justifyContent: 'space-between',
            },
            '& > div.lowBar': {
                alignItems: 'baseline',
                flexDirection: 'row',
                minWidth: '430px',
                justifyContent: 'space-between',
            },
        },
    },
    actionComplete: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '14px',
    },
    actionRequest: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '13px',
        marginLeft: '-1px',
        '& > button': {
            marginLeft: '10px',
        },
    },
    input: {
        fontSize: '14px',
        resize: 'none',
        marginLeft: '10px',
        width: '150px',
    },
    actionApprove: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '13px',
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
        },
    },
    reconCost: {
        fontSize: '13px',
        marginRight: '3px',
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        '& > div:nth-child(1)': {
            marginRight: '5px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            marginTop: '5px',
        },
    },
    totalEstimate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '13px',
        '& > input': {
            width: '100px',
            marginLeft: '10px',
            backgroundColor: `${theme.palette.background.white} !important`,
        },
    },
    fullWidthTotalEstimate: {
        width: '100%',
    },
    actionSaveSheet: {
        marginLeft: '15px',
        '& > button': {
            minWidth: '80px',
        },
    },
    noteContainer: {
        borderTop: `0.5px solid ${theme.palette.border.ghost}`,
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '3px !important',
        paddingBottom: '3px !important',
        padding: '3px !important',
        bottom: '13px !important',
        backgroundColor: `${theme.palette.background.secondary} !important`,
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            width: '49.5%',
            backgroundColor: theme.palette.background.white,
        },
        '& > div:nth-child(2) > div': {
            color: `${theme.palette.text.black} !important`,
        },
        [theme.breakpoints.down('sm')]: {
            bottom: '1px !important',
            flexDirection: 'initial',
        },
    },
}));

const ReconActionsBar = ({
    isSavingInspectionItems,
    canApprove,
    status,
    inspector,
    defaultInspector,
    onChangeReconStatus,
    inspectorOptions,
    totalEstimate,
    maximumReconCost,
    saveReconItems,
    note: inputNote,
    savingReconStatus,
    repairsSummary,
    stockNumber,
    refetchReconStatus,
    refetchList,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = { ...useStyles(), ...buttonStyles() };

    const [note, setNote] = useState(inputNote);
    const [isPromptVisible, setIsPromptVisible] = useState(false);
    const { userInformation = {} } = useContext(UserContext);

    const { data: subscriptionData } = useSubscription(InventoryModuleSubscription.REPAIRS_ANALYSIS_COMPLETED, {
        variables: {
            stockNumber,
        },
        shouldResubscribe: true,
    });

    useEffect(() => {
        if (subscriptionData) {
            const response = subscriptionData.repairsAnalysisCompletedByAI;
            if (response) {
                ModalUtils.successMessage(
                    null,
                    'The Repairs were approved by the AI. Check details for more information',
                );
            } else {
                ModalUtils.errorMessage(
                    null,
                    'The Repairs were not approved by the AI. Check details for more information',
                );
            }

            refetchReconStatus();
            refetchList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData]);

    const sendReapprovalRequest = () => {
        setIsPromptVisible(false);
        onChangeReconStatus(ReconStatus.INSPECTION_COMPLETE, inspector, note, false, true);
    };

    return (
        <>
            <div className={classes.actionsBar}>
                <div className="topBar">
                    <If condition={!canApprove}>
                        <div className={classes.actionComplete}>
                            Inspection Complete
                            <Switch
                                disabled={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE || savingReconStatus}
                                checked={status === ReconStatus.INSPECTION_COMPLETE
                                            || status === ReconStatus.INSPECTION_APPROVED
                                            || status === ReconStatus.REPAIRS_COMPLETE}
                                onChange={(_, value) => onChangeReconStatus(
                                    value ? ReconStatus.INSPECTION_COMPLETE : ReconStatus.INSPECTION_PENDING,
                                    value ? defaultInspector : null,
                                    note,
                                )}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </If>
                    <If condition={canApprove || status !== ReconStatus.INSPECTION_PENDING}>
                        <div className={classes.actionRequest}>
                            {!isMobile && !canApprove && ('| ')}
                            Request Approval
                            <Select
                                disabled={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE || savingReconStatus}
                                nowrap
                                size="sm"
                                menuPlacement="top"
                                name="inspector"
                                loading={false}
                                className={classes.input}
                                onChange={(_, value) => onChangeReconStatus(ReconStatus.INSPECTION_COMPLETE, value, note)}
                                value={defaultInspector || ''}
                                options={inspectorOptions}
                            />
                        </div>
                    </If>
                    <If condition={canApprove || (!canApprove && status !== ReconStatus.INSPECTION_PENDING)}>
                        <div className={classes.actionApprove}>
                            Approve
                            <Switch
                                disabled={!canApprove}
                                checked={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE || savingReconStatus}
                                onChange={(_, value) => onChangeReconStatus(
                                    value ? ReconStatus.INSPECTION_APPROVED : ReconStatus.INSPECTION_COMPLETE,
                                    userInformation?.userId || null,
                                    note,
                                    true,
                                )}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </If>
                    <If condition={status === ReconStatus.INSPECTION_APPROVED || status === ReconStatus.REPAIRS_COMPLETE}>
                        <div className={classes.actionApprove}>
                            <span />
                            <Button
                                disabled={isSavingInspectionItems || savingReconStatus}
                                size="small"
                                className={classes.containedSecondaryInfo}
                                onClick={() => setIsPromptVisible(true)}
                            >
                                Request Reapproval
                            </Button>
                        </div>
                    </If>
                </div>
                <div className="lowBar">
                    <div className={classes.reconCost}>
                        <div>Max Recon Cost:</div>
                        <div>
                            {`${NumberUtils.applyCurrencyFormat(maximumReconCost, '$0,0.[00]')} ${!isMobile ? '|' : ''}`}
                        </div>
                    </div>
                    <div className={clsx(classes.totalEstimate, !saveReconItems ? classes.fullWidthTotalEstimate : '')}>
                        Total Estimate
                        <InputNumber
                            disabled
                            value={totalEstimate}
                            placeholder="$0"
                            showCurrency
                            min={0}
                            decimalScale={0}
                            thousandSeparator
                            size="sm"
                        />
                    </div>
                    <div className={classes.actionSaveSheet}>
                        <Button
                            size="small"
                            disabled={
                                !saveReconItems
                                || status === ReconStatus.INSPECTION_APPROVED
                                || status === ReconStatus.REPAIRS_COMPLETE
                                || isSavingInspectionItems
                                || savingReconStatus
                            }
                            className={classes.containedSecondaryInfo}
                            onClick={saveReconItems}
                        >
                            {isSavingInspectionItems ? 'Saving' : 'Save Sheet'}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={clsx(classes.actionsBar, classes.noteContainer)}>
                <TextField
                    placeholder="General Instructions (Max 500)"
                    disabled={savingReconStatus}
                    multiline
                    fullWidth
                    rows={3}
                    size="small"
                    variant="outlined"
                    value={note || ''}
                    onChange={({ target: { value } }) => setNote(value)}
                    onBlur={() => onChangeReconStatus(status, inspector, note)}
                />
                <TextField
                    placeholder="AI Response"
                    disabled
                    multiline
                    fullWidth
                    rows={3}
                    size="small"
                    variant="outlined"
                    value={repairsSummary || ''}
                />
            </div>
            <ConfirmDialog
                title="Attention!"
                description="Do you want to request a reapproval of this Inspection List?"
                open={isPromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => setIsPromptVisible(false)}
                onClickSecondary={() => setIsPromptVisible(false)}
                onClickPrimary={sendReapprovalRequest}
            />
        </>
    );
};

ReconActionsBar.propTypes = {
    isSavingInspectionItems: PropTypes.bool,
    canApprove: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    inspector: PropTypes.number,
    defaultInspector: PropTypes.number,
    inspectorOptions: PropTypes.array,
    totalEstimate: PropTypes.number,
    maximumReconCost: PropTypes.number,
    saveReconItems: PropTypes.func,
    note: PropTypes.string,
    onChangeReconStatus: PropTypes.func.isRequired,
    savingReconStatus: PropTypes.bool.isRequired,
    repairsSummary: PropTypes.string,
    stockNumber: PropTypes.number.isRequired,
    refetchReconStatus: PropTypes.func.isRequired,
    refetchList: PropTypes.func.isRequired,
};

ReconActionsBar.defaultProps = {
    isSavingInspectionItems: false,
    inspector: null,
    defaultInspector: null,
    inspectorOptions: [],
    totalEstimate: 0,
    maximumReconCost: 0,
    note: null,
    saveReconItems: null,
    repairsSummary: null,
};

export default ReconActionsBar;

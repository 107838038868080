import { Button, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import DocumentTypeFilter from 'components/modules/inventory/list/DocumentTypeFilter';
import PropTypes from 'prop-types';

const DocumentTypeInput = ({
    // eslint-disable-next-line max-len
    record, isEditMode, isSelected, documentType, onPreviewDocument, documentTypesData, editDocumentType, updateDocumentDocumentType, startIcon, updatingDocumentType, classes, otherText,
}) => {
    const [state, setState] = useState({
        selectedDocumentType: documentType,
        otherText,
    });

    const onChangeDocumentType = (documentTypeValue) => {
        setState((prevState) => ({
            ...prevState,
            selectedDocumentType: documentTypeValue,
            otherText: '',
        }));
    };

    const onOtherTextChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            otherText: value,
        }));
    };

    const onDocumentTypeClick = () => {
        if (isEditMode && isSelected) {
            updateDocumentDocumentType(state.otherText.length > 0 ? state.otherText.trim() : state.selectedDocumentType);
        } else {
            editDocumentType(record);
        }
    };

    return (
        <div
            id={`document-type-${record.documentId}`}
            className={classes.nameWrapper}
        >
            {(!isEditMode || !isSelected) && (
                <div
                    className={classes.text}
                    onClick={() => onPreviewDocument(record)}
                >
                    {(state.otherText.length > 0) ? `${state.otherText}` : state.selectedDocumentType}
                </div>
            )}
            {isEditMode && isSelected && (
                <DocumentTypeFilter
                    selectedDocumentType={state.selectedDocumentType}
                    onChange={onChangeDocumentType}
                    documentTypes={documentTypesData?.getDocumentTypes}
                    updatingDocumentType={updatingDocumentType}
                    otherText={state.otherText}
                    onOtherChange={onOtherTextChange}
                    parentId={`document-type-${record.documentId}`}
                />
            )}
            <Tooltip title="Update Document Type">
                <div>
                    <Button
                        disabled={updatingDocumentType || state.isProcessingFile}
                        className={classes.containedInfo}
                        size="small"
                        startIcon={startIcon}
                        onClick={onDocumentTypeClick}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

DocumentTypeInput.propTypes = {
    record: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    documentType: PropTypes.string.isRequired,
    documentTypesData: PropTypes.object.isRequired,
    onPreviewDocument: PropTypes.func.isRequired,
    updatingDocumentType: PropTypes.bool.isRequired,
    startIcon: PropTypes.object.isRequired,
    updateDocumentDocumentType: PropTypes.func.isRequired,
    editDocumentType: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    otherText: PropTypes.string.isRequired,
};

export default DocumentTypeInput;

/* eslint-disable import/prefer-default-export */
const leadType = {
    EMAIL: 'Email',
    PHONE_UP: 'Phone-Up',
    WALK_IN: 'Walk-In',
};

const automatedResponseTrigger = Object.freeze({
    MANUAL: 'Manual',
    CREATE_OPPORTUNITY: 'At Create an Opportunity',
});

const automatedResponseDelayMeasure = Object.freeze({
    MINUTES: 'Minutes',
    HOURS: 'Hours',
    DAYS: 'Days',
});

const automatedResponseStatus = Object.freeze({
    RUNNING: 'Running',
    HALTED: 'Halted',
});

const automatedResponseKeywordsJoint = Object.freeze({
    AND: 'AND',
    OR: 'OR',
});

const optoutMessageAction = Object.freeze({
    EVERY_MESSAGE: 'Every Message',
    FIRST_MESSAGE_ONLY: 'First Message Only',
});

export {
    leadType,
    automatedResponseTrigger,
    automatedResponseDelayMeasure,
    automatedResponseStatus,
    automatedResponseKeywordsJoint,
    optoutMessageAction,
};

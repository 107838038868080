import React, {
    useReducer,
    useEffect,
    useContext,
    useRef,
} from 'react';
import {
    makeStyles, fade,
    Checkbox, Button,
    TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    useQuery,
    useApolloClient,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import useFilterActions from 'components/modules/inventory/hooks/useFilterActions';
import UserContext from 'components/context/UserContext';
import ButtonStyles from 'styles/theme/Button';
import { Link as RouteLink } from 'react-router-dom';
import { Card, CardHeader } from 'components/widgets/crm/Card';
import { FetchPolicy, DataSort, ALL_LOTS } from 'utils/enum/Core';
import {
    ReconStatus,
    ReconCardType,
    PartStatus,
    FilterSource,
} from 'utils/enum/InventoryEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { modules } from 'utils/enum/modules';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ImagesUtils from 'utils/ImagesUtils';
import TableUtils from 'utils/TableUtils';
import InventoryHelper from 'utils/InventoryHelper';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import VehicleImage from 'components/widgets/VehicleImage';
import DatePicker from 'react-datepicker';
import VehicleWidget from 'components/modules/inventory/list/VehicleWidget';
import InputNumber from 'components/widgets/InputNumber';
import VirtualTable from 'components/widgets/VirtualTable';
import InputSearch from 'components/widgets/InputSearch';
import Select from 'components/widgets/Select';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LotFilter from 'components/modules/inventory/list/LotFilter';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';

// Icons
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const INIT_STATE = {
    items: [],
    params: {
        start: 1,
        limit: 50,
        orderBy: 'stockNumber',
        dir: DataSort.DESC,
        search: null,
        partStatus: PartStatus.ALL_STATUSES,
        filters: [],
    },
    requestMoreData: true,
    isWarningOpen: false,
    selectedItem: null,
    columnsData: [],
    isPicturesFormOpen: false,
    selectedItemImages: null,
    openImagePreview: false,
    photoIndex: -1,
    tempReconCost: null,
    tempGeneralInstructions: null,
};

const ACTION_TYPES = {
    SET_ITEMS: 'setItems',
    UPDATE_ITEM_FIELDS: 'updateItemFields',
    SET_PARAMS: 'setParams',
    TOGGLE_IS_WARNING_OPEN: 'toggleIsWarningOpen',
    INSPECTION_LIST_APPROVED: 'inspectionListApproved',
    SET_SEARCH: 'setSearch',
    ON_SORT: 'onSort',
    CHANGE_FILTERS: 'changeFilters',
    SET_COLUMNS_DATA: 'setColumnsData',
    TOGGLE_IS_PICTURES_FORM_OPEN: 'toggleIsPicturesFormOPen',
    SET_SELECTED_ITEM_IMAGES: 'setSelectedItemImages',
    TOGGLE_IMAGE_PREVIEW: 'toggleImagePreview',
    SET_PHOTO_INDEX: 'setPhotoIndex',
    SET_PART_STATUS: 'setPartStatus',
    ON_REFRESH: 'onRefresh',
};

const reducer = (state, action = {}) => {
    let clone = null;
    let index = null;

    switch (action.type) {
    case ACTION_TYPES.SET_ITEMS:
        const { value, keep, requestMoreData } = action;

        if (!keep) {
            return {
                ...state,
                items: value,
                requestMoreData,
            };
        }

        const previousRecords = state.items;
        const filtered = value
            .filter((item) => !previousRecords.some((pr) => pr.stockNumber === item.stockNumber));

        return {
            ...state,
            items: [...previousRecords, ...filtered],
            requestMoreData,
        };
    case ACTION_TYPES.UPDATE_ITEM_FIELDS:
        const {
            stockNumber,
        } = action.value;

        clone = cloneDeep(state.items);
        index = clone.findIndex((item) => item?.stockNumber === stockNumber);
        if (index >= 0) clone[index] = { ...clone[index], ...action.value };

        return {
            ...state,
            items: clone,
        };
    case ACTION_TYPES.TOGGLE_IS_WARNING_OPEN:
        return {
            ...state,
            isWarningOpen: !state.isWarningOpen,
            selectedItem: action.value,
        };
    case ACTION_TYPES.INSPECTION_LIST_APPROVED:
        const { items, selectedItem } = state;
        clone = cloneDeep(items);
        index = clone.findIndex((item) => item?.stockNumber === selectedItem?.stockNumber);
        if (index >= 0) clone[index].approved = true;

        return {
            ...state,
            items: clone,
            isWarningOpen: false,
            selectedItem: null,
        };
    case ACTION_TYPES.SET_PARAMS: {
        return { ...state, params: action.value };
    }
    case ACTION_TYPES.SET_SEARCH:
        return {
            ...state,
            items: [],
            params: {
                ...state.params,
                search: action.value,
                start: 1,
                filters: state
                    .params
                    .filters
                    .filter((filter) => filter.columnName === 'lotName'),
            },
            columnsData: [],
        };
    case ACTION_TYPES.ON_SORT:
        const { orderBy, dir } = action.value;

        return {
            ...state,
            items: [],
            params: {
                ...state.params,
                orderBy,
                dir,
                start: 1,
            },
        };
    case ACTION_TYPES.CHANGE_FILTERS:
        const { keepSearch = false } = action;

        return {
            ...state,
            items: [],
            params: {
                ...state.params,
                start: 1,
                search: keepSearch ? state.params.search : null,
                filters: action.value,
            },
            columnsData: action.columnsData || state.columnsData,
        };
    case ACTION_TYPES.SET_COLUMNS_DATA:
        return {
            ...state,
            columnsData: action.value,
        };
    case ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN:
        return {
            ...state,
            isPicturesFormOpen: !state.isPicturesFormOpen,
            selectedItem: action.value,
            selectedItemImages: (!action.value ? null : state.selectedItemImages),
        };
    case ACTION_TYPES.SET_SELECTED_ITEM_IMAGES:
        return {
            ...state,
            selectedItemImages: action.value,
        };
    case ACTION_TYPES.TOGGLE_IMAGE_PREVIEW:
        return {
            ...state,
            openImagePreview: !state.openImagePreview,
            photoIndex: action.value,
        };
    case ACTION_TYPES.SET_PHOTO_INDEX:
        return {
            ...state,
            photoIndex: action.value,
        };
    case ACTION_TYPES.SET_PART_STATUS:
        return {
            ...state,
            items: [],
            params: {
                ...state.params,
                partStatus: action.value,
                start: 1,
                filters: state
                    .params
                    .filters
                    .filter((filter) => filter.columnName === 'lotName'),
            },
            columnsData: [],
        };
    case ACTION_TYPES.ON_REFRESH:
        return {
            ...state,
            items: [],
            params: {
                ...state.params,
                start: 1,
            },
            columnsData: [],
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const ReconCard = ({ type, height }) => {
    const useStyles = makeStyles((theme) => ({
        card: {
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                '& > div:nth-child(1)': {
                    textAlign: 'center',
                },
            },
        },
        tableContainer: {
            width: '100%',
            height,
            overflow: 'hidden',
            marginTop: '10px',
            [theme.breakpoints.down('sm')]: {
                height: '400px',
            },
            '& > div': {
                overflowY: 'hidden',
                overflowX: 'auto',
                minWidth: type === ReconCardType.APPROVALS ? '760px' : '1200px',
                [theme.breakpoints.down('sm')]: {
                    minWidth: '300px',
                },
            },
            '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                backgroundColor: `${theme.palette.background.white} !important`,
                border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                marginBottom: '2px',
                '& > div': {
                    height: '30px',
                    borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                    alignItems: 'center',
                },
            },
            '& .ReactVirtualized__Table__rowColumn': {
                marginLeft: '0px',
                '& > div[class^="makeStyles-main"]': {
                    [theme.breakpoints.down('sm')]: {
                        marginLeft: '10px',
                    },
                },
                '& > .react-datepicker-wrapper input': {
                    textAlign: 'center',
                    fontSize: '12px',
                },
            },
            '& .DragHandleIcon': {
                display: 'none',
            },
        },
        totalEstimate: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '14px',
            '& > div.MuiFormControl-root > div': {
                paddingTop: '5px',
                paddingBottom: '5px',
            },
            '& > input': {
                width: '100px',
                marginLeft: '10px',
                backgroundColor: `${theme.palette.background.white} !important`,
            },
            '& > button': {
                minWidth: '32px',
                height: '29px',
                marginLeft: '10px',
                '& > span > span': {
                    marginLeft: '0px !important',
                    margin: 0,
                },
            },
        },
        searchBar: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 10,
            display: 'flex',
            marginTop: '10px',
            marginRight: '10px',
            alignItems: 'center',
            '& > button.refreshButton': {
                width: '100px',
                marginRight: '10px',
                height: '38px',
            },
            '& > div.partStatusSelector': {
                marginRight: '10px',
                '& > div': {
                    height: '38px !important',
                    width: '180px',
                },
            },
            '& > div.searchInput': {
                width: '210px',
                marginRight: '20px',
            },
            '& > div > div.lotSelector': {
                width: '200px',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginLeft: 0,
                width: '100%',
                position: 'relative',
                marginRight: 0,
                paddingBottom: '10px',
                '& > button.refreshButton': {
                    marginRight: 0,
                    marginBottom: '10px',
                },
                '& > div.partStatusSelector': {
                    marginRight: 0,
                    marginBottom: '10px',
                },
                '& > div.searchInput': {
                    marginRight: 0,
                    marginBottom: '5px',
                },
            },
        },
        textFieldSmall: {
            width: '100%',
            '& input': {
                padding: 7,
            },
        },
        uploadButton: {
            fontSize: '12px',
            borderRadius: '0px',
            backgroundColor: theme.palette.primary.main,
        },
        greenUploadButton: {
            backgroundColor: `${theme.palette.background.green} !important`,
        },
        input: {
            fontSize: '14px',
            resize: 'none',
        },
        '@global': {
            '.css-26l3qy-menu div': {
                fontSize: '14px',
                lineHeight: '1.4',
            },
        },
        link: {
            textDecoration: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                paddingLeft: '5px',
            },
        },
        AppBar: {
            color: theme.palette.text.white,
            backgroundColor: theme.palette.background.sanMarino,
            '& h4': {
                color: theme.palette.text.white,
            },
        },
        content: {
            padding: '20px 10px',
            position: 'relative',
        },
        photosContainer: {
            marginTop: '20px',
            width: '100%',
            height: '50vh',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        cell: {
            display: 'block',
            textAlign: 'center',
        },
    }));

    const client = useApolloClient();
    const { userInformation = {} } = useContext(UserContext);
    const keyStore = new KeyStore();
    const INVENTORY_RECON_APPROVE = keyStore.hasPermission(Permission.INVENTORY_RECON_APPROVE);
    const INVENTORY_RECON_WRITE = keyStore.hasPermission(Permission.INVENTORY_RECON_WRITE);
    const INVENTORY_VEHICLE_WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = { ...useStyles(), ...buttonStyles() };
    const { moveType, setPhotoIndex } = useImagesActions();
    const {
        loadFilterValues,
        setValuesInSelections,
        resetFilters,
        applyFilters,
    } = useFilterActions();
    const tableReference = useRef();
    const [state, dispatch] = useReducer(reducer, {
        ...INIT_STATE,
        params: {
            ...INIT_STATE.params,
            orderBy: type === ReconCardType.PARTS ? 'vr.createdOn' : 'stockNumber',
        },
        tableId: TableUtils.generateUUID(),
    });
    const {
        items,
        params,
        requestMoreData,
        isWarningOpen,
        selectedItem,
        columnsData,
        isPicturesFormOpen,
        selectedItemImages,
        openImagePreview,
        photoIndex,
        tableId,
    } = state;

    const filters = cloneDeep(params.filters);
    const lotFilterIndex = filters.findIndex((filter) => filter.columnName === 'lotName');
    if (lotFilterIndex >= 0 && filters[lotFilterIndex]?.values.includes(ALL_LOTS)) {
        filters.splice(lotFilterIndex, 1);
    }

    const {
        data: itemsData,
        loading: loadingItems,
        error: errorItems,
        refetch: refetchParts,
    // eslint-disable-next-line no-nested-ternary, max-len
    } = useQuery(type === ReconCardType.APPROVALS ? InventoryQuery.GET_RECON_APPROVALS : type === ReconCardType.PARTS ? InventoryQuery.GET_PARTS_LIST : InventoryQuery.GET_PARTS_REQUEST_LIST, {
        variables: {
            paginate: {
                start: params.start,
                limit: params.limit,
            },
            sort: {
                field: params.orderBy,
                dir: params.dir,
            },
            search: params.search,
            ...(type === ReconCardType.PARTS ? {
                partStatus: params.partStatus,
            } : {}),
            filters: filters.length > 0 ? filters : null,
        },
        skip: params.filters.length === 0,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getImages] = useLazyQuery(InventoryQuery.GET_VEHICLE_RECON_ITEM_IMAGES, {
        onCompleted: (response) => {
            if (response) {
                const images = response.getVehicleReconItemImages;
                dispatch({
                    type: ACTION_TYPES.SET_SELECTED_ITEM_IMAGES,
                    value: images,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [updatePartsItem] = useMutation(InventoryMutation.UPDATE_PARTS_LIST_ITEM, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    useEffect(() => {
        const userLot = new KeyStore().getSelectedLot();
        const clone = cloneDeep(params.filters);
        const index = clone.findIndex((filter) => filter.columnName === 'lotName');

        if (index < 0) {
            clone.push(
                userLot?.isDefault ? {
                    columnName: 'lotName',
                    values: userLot.lotName,
                } : {
                    columnName: 'lotName',
                    values: ALL_LOTS,
                },
            );
        }

        dispatch({
            type: ACTION_TYPES.CHANGE_FILTERS,
            value: clone,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorItems) {
            ModalUtils.errorMessage(errorItems?.graphQLErrors);
        }

        if (!loadingItems) {
            const isApprovals = (type === ReconCardType.APPROVALS);
            let data = isApprovals ? itemsData?.getReconApprovals : itemsData?.getPartsList;
            if (!data) return;

            if (!isApprovals) {
                data = data.map((item) => {
                    const clone = { ...item };
                    clone.partStatusETA = clone.partStatusETA ? new Date(clone.partStatusETA) : null;

                    return clone;
                });
            }

            dispatch({
                type: ACTION_TYPES.SET_ITEMS,
                value: data,
                keep: true,
                requestMoreData: data.length === params.limit,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingItems, errorItems]);

    const toggleApproveWarning = (record) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_WARNING_OPEN,
            value: record,
        });
    };

    const approveInspectionList = async () => {
        if (!selectedItem) return;

        const { totalEstimate, maximumReconCost, defaultReconCost } = selectedItem;
        if (totalEstimate > (maximumReconCost || defaultReconCost)) {
            ModalUtils.errorMessage(null, `The Max Recon Cost for this vehicle is ${NumberUtils.applyCurrencyFormat(maximumReconCost || defaultReconCost)}`);

            toggleApproveWarning();
            return;
        }

        try {
            const { data } = await client.mutate({
                mutation: InventoryMutation.SET_RECON_STATUS,
                variables: {
                    stockNumber: selectedItem.stockNumber,
                    status: ReconStatus.INSPECTION_APPROVED,
                    inspector: userInformation?.userId,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                ModalUtils.successMessage(null, 'Inspection List approved successfully');
                dispatch({
                    type: ACTION_TYPES.INSPECTION_LIST_APPROVED,
                });
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const loadMoreData = () => {
        if (!requestMoreData) return;
        const { length } = items;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                start: length + 1,
            },
        });
    };

    const onPartStatusChange = (status) => {
        dispatch({
            type: ACTION_TYPES.SET_PART_STATUS,
            value: status,
        });
    };

    const onSearch = (searchValue) => {
        dispatch({
            type: ACTION_TYPES.SET_SEARCH,
            value: StringUtils.isEmpty(searchValue) ? null : searchValue,
        });
    };

    const onSort = ({ sortBy, sortDirection }) => {
        dispatch({
            type: ACTION_TYPES.ON_SORT,
            value: {
                orderBy: sortBy,
                dir: sortDirection,
            },
        });
    };

    const onLoadFilterValues = async (column, searchInput = null) => {
        const data = await loadFilterValues(
            columnsData,
            column,
            searchInput,
            FilterSource.RECON_CARD,
            type,
        );

        if (data) {
            dispatch({
                type: ACTION_TYPES.SET_COLUMNS_DATA,
                value: data,
            });
        }
    };

    const onSetValuesInSelections = (column, values) => {
        const data = setValuesInSelections(
            columnsData,
            column,
            values,
        );

        if (data) {
            dispatch({
                type: ACTION_TYPES.SET_COLUMNS_DATA,
                value: data,
            });
        }
    };

    const onResetFilters = (column) => {
        const data = resetFilters(
            columnsData,
            column,
        );

        dispatch({
            type: ACTION_TYPES.CHANGE_FILTERS,
            value: !column ? [] : params.filters.filter((item) => item.columnName !== column),
            columnsData: data,
        });

        if (column && tableReference.current) tableReference.current.closeFilterBox();
    };

    const onApplyFilters = (column, selectedValues) => {
        const data = applyFilters(
            params.filters,
            column,
            selectedValues,
        );

        const updatedColumnsData = setValuesInSelections(
            columnsData,
            column,
            selectedValues,
        );

        dispatch({
            type: ACTION_TYPES.CHANGE_FILTERS,
            value: data,
            columnsData: updatedColumnsData,
        });

        if (tableReference.current) tableReference.current.closeFilterBox();
    };

    const onChangeApprovalField = (name, value, item) => {
        const clone = cloneDeep(items);
        const index = clone.findIndex((e) => e.stockNumber === item.stockNumber);
        if (index >= 0) {
            const current = clone[index];
            current[name] = value;

            dispatch({
                type: ACTION_TYPES.SET_ITEMS,
                value: clone,
                keep: false,
                requestMoreData,
            });
        }
    };

    const onChange = (name, value, item) => {
        const clone = cloneDeep(items);
        const index = clone.findIndex((e) => e.vehicleRepairItemId === item.vehicleRepairItemId);
        if (index >= 0) {
            const current = clone[index];
            current[name] = value;

            const isPartStatus = (name === 'partStatus');
            if (isPartStatus) current.partStatusModifiedOn = new Date().toISOString();

            dispatch({
                type: ACTION_TYPES.SET_ITEMS,
                value: clone,
                keep: false,
                requestMoreData,
            });

            if (['partStatus', 'partStatusETA'].includes(name)) {
                updatePartsItem({
                    variables: {
                        input: {
                            vehicleRepairItemId: item.vehicleRepairItemId,
                            estimation: item.estimation,
                            partStatus: isPartStatus ? value : item.partStatus,
                            partStatusETA: isPartStatus ? item.partStatusETA : DateUtils.getISOStringFromLocal(value),
                        },
                    },
                });
            }
        }
    };

    const saveEstimate = (item) => {
        updatePartsItem({
            variables: {
                input: {
                    vehicleRepairItemId: item.vehicleRepairItemId,
                    estimation: item.estimation,
                    partStatus: item.partStatus,
                },
            },
        });
    };

    const togglePicturesForm = async (item) => {
        if (!item) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN,
                value: null,
            });

            return;
        }

        dispatch({
            type: ACTION_TYPES.TOGGLE_IS_PICTURES_FORM_OPEN,
            value: item,
        });

        getImages({
            variables: {
                vehicleInspectionItemId: item.vehicleInspectionItemId,
            },
        });
    };

    const onOpenImage = (index) => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IMAGE_PREVIEW,
            value: index,
        });
    };

    const toggleImagePreview = () => {
        dispatch({
            type: ACTION_TYPES.TOGGLE_IMAGE_PREVIEW,
        });
    };

    const onSetPhotoIndex = (move) => setPhotoIndex(
        move,
        photoIndex,
        selectedItemImages,
        dispatch,
        ACTION_TYPES.SET_PHOTO_INDEX,
    );

    const onFilterByLot = (lots = []) => {
        if (lots.length === 0) return;

        const clone = cloneDeep(params.filters);
        const index = clone.findIndex((filter) => filter.columnName === 'lotName');
        if (index >= 0) {
            const current = { ...clone[index] };
            current.values = lots.join(',');

            clone[index] = current;
        }

        dispatch({
            type: ACTION_TYPES.CHANGE_FILTERS,
            value: clone,
            keepSearch: true,
        });
    };

    const updateFields = async (record) => {
        const {
            stockNumber,
            tempReconCost,
            tempReconNote,
            reconStatus,
            reconCurrentInspector,
        } = record;
        if (!tempReconCost && !tempReconNote) return;

        try {
            const { data } = await client.mutate({
                mutation: InventoryMutation.SET_RECON_STATUS,
                variables: {
                    stockNumber,
                    status: reconStatus,
                    inspector: reconCurrentInspector,
                    ...(tempReconNote ? { note: tempReconNote } : {}),
                    ...(tempReconCost ? { maximumReconCost: tempReconCost } : {}),
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                dispatch({
                    type: ACTION_TYPES.UPDATE_ITEM_FIELDS,
                    value: {
                        stockNumber,
                        maximumReconCost: record.tempReconCost || record.maximumReconCost,
                        reconNote: record.tempReconNote || record.reconNote,
                        tempReconCost: null,
                        tempReconNote: null,
                    },
                });

                ModalUtils.successMessage(null, 'Fields updated successfully');
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const rowStyleFormat = (row) => {
        const { index } = row;
        if (index < 0 || type !== ReconCardType.PARTS) return;

        const record = items[index];
        const { approved } = record;
        if (!approved) return;

        // eslint-disable-next-line consistent-return
        return {
            backgroundColor: theme.palette.background.lightShadeGreen,
            borderBottom: `1px solid ${theme.palette.border.darkGreen}`,
        };
    };

    const partStatuses = [
        {
            value: PartStatus.ESTIMATE_UPDATED,
            label: PartStatus.ESTIMATE_UPDATED,
        },
        {
            value: PartStatus.PART_ORDERED,
            label: PartStatus.PART_ORDERED,
        },
        {
            value: PartStatus.PART_RECEIVED,
            label: PartStatus.PART_RECEIVED,
        },
        {
            value: PartStatus.BACK_ORDERED,
            label: PartStatus.BACK_ORDERED,
        },
    ];

    const columns = type === ReconCardType.APPROVALS ? [
        {
            label: 'Vehicle',
            dataKey: 'stockNumber',
            width: 200,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                if (isMobile) {
                    return (
                        <RouteLink
                            to={{
                                pathname: `/${modules.INVENTORY}/${record.stockNumber}#recon`,
                            }}
                            target="_blank"
                            className={classes.link}
                        >
                            <VehicleWidget short record={record} />
                        </RouteLink>
                    );
                }

                return (
                    <VehicleWidget short record={record} />
                );
            },
        },
        {
            label: 'Part',
            dataKey: 'part',
            width: 40,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <Checkbox
                        checked={record.part}
                    />
                );
            },
        },
        {
            label: 'Total Estimate',
            dataKey: 'totalEstimate',
            width: 140,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.totalEstimate}>
                        <InputNumber
                            disabled
                            value={record.totalEstimate}
                            placeholder="$0"
                            showCurrency
                            min={0}
                            decimalScale={0}
                            thousandSeparator
                            size="sm"
                        />
                    </div>
                );
            },
        },
        {
            label: 'Action',
            dataKey: 'action',
            width: 80,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (record.approved) {
                    return (
                        <span>Approved</span>
                    );
                }

                return (
                    <Button
                        disabled={!INVENTORY_RECON_APPROVE || !INVENTORY_RECON_WRITE}
                        size="small"
                        className={classes.containedSecondaryInfo}
                        onClick={() => toggleApproveWarning(record)}
                    >
                        Approve
                    </Button>
                );
            },
        },
        {
            label: 'Max Recon Cost',
            dataKey: 'maxReconCost',
            width: 160,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.totalEstimate}>
                        <InputNumber
                            disabled={!INVENTORY_VEHICLE_WRITE}
                            value={record.tempReconCost || record.maximumReconCost || record.defaultReconCost || 0}
                            placeholder="$0"
                            showCurrency
                            min={0}
                            decimalScale={0}
                            thousandSeparator
                            size="sm"
                            onChange={(value) => onChangeApprovalField('tempReconCost', value, record)}
                        />
                        {INVENTORY_VEHICLE_WRITE && (
                            <Button
                                disabled={!record.tempReconCost}
                                className={classes.containedInfo}
                                size="small"
                                startIcon={<SaveOutlinedIcon />}
                                onClick={() => updateFields(record)}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            label: 'General Instructions',
            dataKey: 'generalInstructions',
            width: 450,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <div className={classes.totalEstimate}>
                        <TextField
                            multiline
                            fullWidth
                            rows={2}
                            size="small"
                            variant="outlined"
                            value={record.tempReconNote || record.reconNote || ''}
                            onChange={({ target: { value } }) => onChangeApprovalField('tempReconNote', value, record)}
                        />
                        <Button
                            disabled={!record.tempReconNote}
                            className={classes.containedInfo}
                            size="small"
                            startIcon={<SaveOutlinedIcon />}
                            onClick={() => updateFields(record)}
                        />
                    </div>
                );
            },
        },
    ] : [
        {
            label: 'Vehicle',
            dataKey: 'stockNumber',
            width: 200,
            disableSort: true,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                if (isMobile && record.stockNumber != null) {
                    return (
                        <RouteLink
                            to={{
                                pathname: `/${modules.INVENTORY}/${record.stockNumber}#recon`,
                            }}
                            target="_blank"
                            className={classes.link}
                        >
                            <VehicleWidget short record={record} />
                        </RouteLink>
                    );
                }

                return (
                    <VehicleWidget short record={record} />
                );
            },
        },
        {
            label: 'Item',
            dataKey: 'name',
            width: 200,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span>{record.invoiceNumber ? 'Technician' : record.name}</span>
                );
            },
        },
        {
            label: 'Comments',
            dataKey: 'comments',
            width: 250,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <TextField
                        className={classes.textFieldSmall}
                        value={record?.comment || ''}
                        variant="outlined"
                        size="small"
                    />
                );
            },
        },
        {
            label: 'Estimate',
            dataKey: 'estimate',
            width: 120,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <InputNumber
                        disabled={record.reconStatus === ReconStatus.INSPECTION_APPROVED || record.reconStatus === ReconStatus.REPAIRS_COMPLETE}
                        value={record?.estimation || 0}
                        onChange={(newValue) => onChange('estimation', newValue, record)}
                        onBlur={() => saveEstimate(record)}
                        placeholder="$0"
                        showCurrency
                        min={0}
                        decimalScale={0}
                        thousandSeparator
                        size="sm"
                    />
                );
            },
        },
        {
            label: 'Part Status',
            dataKey: 'partStatus',
            width: 180,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <Select
                        nowrap
                        size="sm"
                        className={classes.input}
                        placeholder="Need Estimate"
                        name="partStatus"
                        onChange={(name, value) => onChange(name, value, record)}
                        value={record?.partStatus || ''}
                        options={record.reconStatus === ReconStatus.INSPECTION_APPROVED || record.reconStatus === ReconStatus.REPAIRS_COMPLETE
                            ? partStatuses.filter((p) => p.value !== PartStatus.ESTIMATE_UPDATED)
                            : partStatuses}
                    />
                );
            },
        },
        {
            label: 'Created On',
            dataKey: 'vr.createdOn',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.cell}>
                        {DateUtils.getFormattedDateInUserTimezone(
                            record.createdOn,
                            DateFormat.DEFAULT_DATETIME_WITHOUT_SECONDS,
                        )}

                    </span>
                );
            },
        },
        {
            label: 'Timestamp',
            dataKey: 'vr.partStatusModifiedOn',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.cell}>
                        {DateUtils.getFormattedDateInUserTimezone(
                            record.partStatusModifiedOn,
                            DateFormat.DEFAULT_DATETIME_WITHOUT_SECONDS,
                        )}

                    </span>
                );
            },
        },
        {
            label: 'ETA',
            dataKey: 'vr.partStatusETA',
            width: 150,
            disableSort: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <DatePicker
                        size="sm"
                        className={clsx('form-control form-control-sm')}
                        selected={record.partStatusETA}
                        onChange={(date) => onChange('partStatusETA', date, record)}
                        showTimeSelect
                        timeFormat={DateFormat.DEFAULT_TIME}
                        timeIntervals={30}
                        timeCaption="Time"
                        minDate={new Date()}
                        dateFormat={
                            DateFormat
                                .DEFAULT_DATETIME_WITHOUT_SECONDS
                                .replace('DD', 'dd')
                                .replace('YYYY', 'yyyy')
                        }
                        popperPlacement={!isMobile ? 'left-start' : 'bottom-end'}
                        popperContainer={CalendarContainer}
                    />
                );
            },
        },
        {
            label: 'Pictures',
            dataKey: 'pictures',
            width: 100,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <Button
                        size="small"
                        className={clsx(classes.containedSecondaryInfo, classes.uploadButton, (record.images || 0) > 0 ? classes.greenUploadButton : '')}
                        onClick={() => ((record.images || 0) > 0 ? togglePicturesForm(record) : null)}
                    >
                        {`View (${record.images || 0})`}
                    </Button>
                );
            },
        },
    ];

    if (!isMobile) {
        columns.unshift({
            label: '',
            dataKey: 'image',
            width: 130,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const image = ImagesUtils.getSecureImageURL(
                    record.thumbnail,
                    InventoryHelper.getThumbnail(record.style),
                );

                return record.stockNumber ? (
                    <RouteLink
                        to={{
                            pathname: `/${modules.INVENTORY}/${record.stockNumber}#recon`,
                        }}
                        target="_blank"
                    >
                        <VehicleImage
                            image={image}
                            bodyStyle={record.style}
                            height="100%"
                            width="100%"
                        />
                    </RouteLink>
                ) : (
                    <VehicleImage
                        image={image}
                        bodyStyle={record.style}
                        height="100%"
                        width="100%"
                    />
                );
            },
        });

        columns.splice(2, 0, {
            label: 'Approver',
            dataKey: 'reconCurrentInspectorFullName',
            width: 150,
            disableSort: true,
            filterEnabled: false,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span>{record.invoiceNumber ? record.advisor : record.approver}</span>
                );
            },
        });
    }

    const parentWidth = document.getElementById(tableId)?.clientWidth;
    let tableWidth = columns.reduce((a, b) => a + b.width, 0);
    if (parentWidth > tableWidth) tableWidth = parentWidth - 15;

    const lots = params.filters
        .find((filter) => filter.columnName === 'lotName')?.values?.split(',') || [];
    return (
        <>
            <Card className={classes.card}>
                <CardHeader title={type === ReconCardType.APPROVALS ? 'Recon Approvals' : 'Parts List'} />
                <div className={classes.searchBar}>
                    {type === ReconCardType.PARTS && (
                        <>
                            <Button
                                disabled={loadingItems}
                                className={clsx(classes.containedGreen, 'refreshButton')}
                                size="small"
                                onClick={() => {
                                    dispatch({ type: ACTION_TYPES.ON_REFRESH });
                                    refetchParts();
                                }}
                            >
                                Refresh
                            </Button>
                            <Select
                                nowrap
                                size="sm"
                                className={clsx(classes.input, 'partStatusSelector')}
                                placeholder="Part Status"
                                name="partStatus"
                                onChange={(_, value) => onPartStatusChange(value)}
                                value={params.partStatus || ''}
                                options={Object.values(PartStatus).map((l) => ({
                                    value: l,
                                    label: l,
                                }))}
                            />
                        </>
                    )}
                    <InputSearch
                        customClasses="searchInput"
                        initialSearch={params.search || ''}
                        onSearch={onSearch}
                    />
                    {lots.length > 0 && (
                        <LotFilter className="lotSelector" onSearch={onFilterByLot} initialLots={lots} />
                    )}
                </div>
                <div id={tableId} className={classes.tableContainer}>
                    <VirtualTable
                        ref={tableReference}
                        loading={loadingItems}
                        loadMore={loadMoreData}
                        rowHeight={75}
                        totalRecords={Infinity}
                        data={items}
                        columns={columns}
                        sort={onSort}
                        sortBy={params.orderBy}
                        sortDirection={params.dir}
                        filters={params.filters}
                        loadFilterValues={onLoadFilterValues}
                        setValuesInSelections={onSetValuesInSelections}
                        resetFilters={onResetFilters}
                        applyFilters={onApplyFilters}
                        columnsData={columnsData}
                        width={tableWidth || 0}
                        rowStyleFormat={rowStyleFormat}
                    />
                </div>
            </Card>
            <InspectionImagesDialog
                isPicturesFormOpen={isPicturesFormOpen}
                openImagePreview={openImagePreview}
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={selectedItemImages}
                onOpenImage={onOpenImage}
                photoIndex={photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                moveType={moveType}
                isApproved
            />
            <ConfirmDialog
                title="Attention!"
                description="Do you want to approve this inspection list?"
                open={isWarningOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={toggleApproveWarning}
                onClickSecondary={toggleApproveWarning}
                onClickPrimary={approveInspectionList}
            />
        </>
    );
};

ReconCard.defaultProps = {
    height: '88%',
};

ReconCard.propTypes = {
    type: PropTypes.string.isRequired,
    height: PropTypes.string,
};

export default ReconCard;

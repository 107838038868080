import { gql } from '@apollo/client';

export default class DocumentsFragment {
    static GET_DOCUMENT_FRAGMENT = gql`
        #        Typename was added to avoid cache issues
        fragment DocumentFragment on GenericDocument {
            __typename
            documentId
            documentUrl
            documentSize
            referenceType
            documentType
            createdOn
            isSecureUrl
        }
    `
}

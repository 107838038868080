import React, { useState } from 'react';
import KeyStore from 'utils/KeyStore';
import { modules } from 'utils/enum/modules';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import Permission from 'utils/enum/Permissions';
import Container from 'components/widgets/Container';
import VideoSettings from 'components/modules/settings/training/VideoSettings';

// Icons
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: 'flex',
        flexDirection: 'row',
        background: theme.palette.background.white,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    content: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        flexGrow: 1,
        height: '100%',
        padding: theme.spacing(2),
        background: theme.palette.background.white,
        border: `solid 1px ${theme.palette.border.primary}`,
        overflowY: 'auto',
        '& > div': {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            border: '0px',
        },
    },
    sideBar: {
        width: 280,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        borderTop: `solid 1px ${theme.palette.border.primary}`,
        overflowY: 'hidden',
        overflowX: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100% !important',
            borderTop: '0px',
            '& > div > div:nth-child(2)': {
                display: 'none',
            },
        },
    },
}));

const subTabs = ['videos'];
const getSelectedTab = (subtab) => {
    const tabIndex = subTabs.indexOf(subtab);
    if (tabIndex !== -1) return tabIndex;

    return 0;
};

const TrainingSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${modules.SETTINGS}/${maintab}/${subTabs[newIndex]}`);
        setActiveIndex(newIndex);
    };

    const keyStore = new KeyStore();
    const TRAINING_GLOBAL_SETTINGS_WRITE = keyStore.hasPermission(Permission.TRAINING_GLOBAL_SETTINGS_WRITE);
    const TRAINING_LIMITED_SETTINGS_MANAGE_VIDEOS = keyStore.hasPermission(Permission.TRAINING_LIMITED_SETTINGS_MANAGE_VIDEOS);

    const settingsItems = [
        {
            permission: TRAINING_GLOBAL_SETTINGS_WRITE || TRAINING_LIMITED_SETTINGS_MANAGE_VIDEOS,
            index: 0,
            text: 'Videos',
            icon: <OndemandVideoOutlinedIcon />,
            component: <VideoSettings />,
        },
    ].filter((item) => item.permission);

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {settingsItems.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            <div className={classes.content}>
                {settingsItems.map((item) => (
                    activeIndex === item.index && <div key={item.index}>{item.component}</div>
                ))}
            </div>
        </Container>
    );
};

export default TrainingSettings;

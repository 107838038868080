import {
    FormControl, Input, ListItemText, makeStyles, MenuItem, Select, TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { useState } from 'react';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import PropTypes from 'prop-types';
import InventoryListStyle from 'styles/modules/inventory/list/InventoryListStyle';

const customTheme = getMuiTheme({
    palette: {
        accent1Color: '#3F51B5',
        textColor: 'rgba(0, 0, 0, 0.65)',
    },
    checkbox: {
        checkedColor: '#3F51B5',
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT_RATIO = 4.5;

const MenuProps = (containerWidth) => ({
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * ITEM_HEIGHT_RATIO + ITEM_PADDING_TOP,
            width: containerWidth,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'top', horizontal: 'center' },
});

const getMenuItem = (key, value, className) => (
    <MenuItem key={key} value={value} className={className} onKeyDown={(e) => e.stopPropagation()}>
        <ListItemText primary={value} />
    </MenuItem>
);

const getMenuItemOther = (key, value, className, otherText, onOtherChange, toggleOpenSelector) => (
    <MenuItem
        key={key}
        value={value}
        className={className}
        onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Escape') toggleOpenSelector(false);
            e.stopPropagation();
        }}
    >
        <TextField
            id={`document-type-${value}`}
            label="Other"
            className={className}
            placeholder="Press Enter or ESC to Dismiss"
            value={otherText}
            autoFocus
            onChange={(event) => {
                onOtherChange(event.target.value);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Escape') {
                    toggleOpenSelector(false); // Close menu when Enter is pressed
                }
            }}
            style={{
                width: '100%',
            }}
        />
    </MenuItem>
);
const DocumentTypeFilter = ({
    onChange, updatingDocumentType, selectedDocumentType, documentTypes, containerWidth, onOtherChange, otherText,
}) => {
    const useStyles = makeStyles(InventoryListStyle.lotFilter());
    const classes = useStyles();
    const [openDocumentsSelect, toggleOpenSelector] = useState(false);

    return (
        <MuiThemeProvider muiTheme={customTheme}>
            <FormControl>
                <Select
                    open={openDocumentsSelect}
                    onOpen={() => toggleOpenSelector(true)}
                    onClose={(event) => {
                        if (event.target?.value !== 'Other:' && event.target.tagName !== 'INPUT') {
                            toggleOpenSelector(false);
                        }
                    }}
                    className={clsx(classes.select)}
                    labelId="select-document-type-label"
                    disabled={updatingDocumentType}
                    value={selectedDocumentType}
                    onChange={(event, selected) => {
                        const { value } = selected.props;
                        onChange(value);
                        if (value.trim() !== 'Other:') {
                            toggleOpenSelector(false);
                        }
                    }}
                    input={<Input placeholder="Please select a document type" />}
                    MenuProps={MenuProps(containerWidth)}
                >
                    <MenuItem value="All">All</MenuItem>
                    {documentTypes.map((doc) => getMenuItem(doc.description, doc.description))}
                    {getMenuItemOther('Other:', 'Other:', classes, otherText, onOtherChange, toggleOpenSelector)}
                </Select>
            </FormControl>
        </MuiThemeProvider>
    );
};

DocumentTypeFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    containerWidth: PropTypes.number,
    documentTypes: PropTypes.arrayOf(PropTypes.shape({
        documentTypeId: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    updatingDocumentType: PropTypes.bool.isRequired,
    selectedDocumentType: PropTypes.string.isRequired,
    otherText: PropTypes.string.isRequired,
    onOtherChange: PropTypes.func.isRequired,
};

DocumentTypeFilter.defaultProps = {
    containerWidth: 250,
};

export default DocumentTypeFilter;

import React, { useReducer, useEffect, useRef } from 'react';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import { cloneDeep } from 'lodash';
import { makeStyles, Container, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/client';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import useFilterActions from 'components/modules/inventory/hooks/useFilterActions';
import { FilterSource } from 'utils/enum/InventoryEnum';
import { FetchPolicy, DataSort } from 'utils/enum/Core';
import { CachedIcon, ExcelIcon } from 'components/icons';
import { Form, Row } from 'react-bootstrap';
import { Link as RouteLink } from 'react-router-dom';
import { modules } from 'utils/enum/modules';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import SpreadsheetUtils from 'utils/SpreadsheetUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import ReportQuery from 'services/graphQL/query/ReportQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import ReportHeader from 'components/reports/ReportHeader';
import ReportFooter from 'components/reports/ReportFooter';
import ReportContent from 'components/reports/ReportContent';
import VirtualTable from 'components/widgets/VirtualTable';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import ButtonStyles from 'styles/theme/Button';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';

const dateISOFormat = new Date().toISOString();
const today = DateUtils.getFormattedDateInUserTimezone(dateISOFormat, DateFormat.DEFAULT_DATE);
const calculatedStartDate = DateUtils.format(DateUtils.subtract(today, 7));
const INIT_STATE = {
    params: {
        start: 0,
        limit: 25,
        ignoreLimit: false,
        field: 'modifiedOn',
        dir: DataSort.DESC,
        filters: [],
        startDate: calculatedStartDate,
        endDate: today,
    },
    table: {
        records: [],
        totalCount: 0,
    },
    columnsData: [],
    isPullingData: false,
    isExportingExcel: false,
    tempStartDate: new Date(calculatedStartDate),
    tempEndDate: new Date(today),
    appraisalListId: null,
    isPicturesFormOpen: false,
    appraisalImages: null,
    isUploadingImages: false,
    openImagePreview: false,
    photoIndex: -1,
};

const ACTION_TYPES = {
    SET_TABLE: 'setTable',
    SET_FIELD_IN_ROOT: 'setFieldInRoot',
    SET_PARAMS: 'setParams',
    SET_COLUMNS_DATA: 'setColumnsData',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE:
        return {
            ...state,
            table: action.value,
            isPullingData: action.isPullingData,
        };
    case ACTION_TYPES.SET_FIELD_IN_ROOT:
        return {
            ...state,
            ...(action.value),
        };
    case ACTION_TYPES.SET_PARAMS:
        return {
            ...state,
            params: {
                ...state.params,
                ...(action.value),
            },
            isPullingData: action.isPullingData || state.isPullingData,
            columnsData: action.columnsData || state.columnsData,
        };
    case ACTION_TYPES.SET_COLUMNS_DATA:
        return {
            ...state,
            columnsData: action.value,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyle = makeStyles((theme) => ({
    container: {
        padding: '15px',
    },
    noPadding: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    tableContainer: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        '& > div': {
            overflow: 'hidden',
            minWidth: '2400px',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            marginLeft: '0px',
            '& > div[class^="makeStyles-main"]': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '10px',
                },
            },
        },
        '& .DragHandleIcon': {
            display: 'none',
        },
    },
    subLabel: {
        fontSize: '13px',
        marginLeft: '5px',
        marginRight: '10px',
        marginTop: '8px',
    },
    group: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
        },
    },
    goButton: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '30px',
        },
    },
    mobileContainer: {
        width: '100%',
        padding: '10px',
        backgroundColor: theme.palette.background.white,
    },
    box: {
        border: `1px solid ${theme.palette.border.mercury}`,
        marginBottom: '15px',
    },
    boxTitle: {
        color: theme.palette.text.white,
        display: 'flex',
        padding: '5px',
        fontSize: '14px',
        marginBottom: '20px',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.bigStone,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    boxContent: {
        padding: '10px',
        fontSize: '13px',
        '& > div': {
            display: 'flex',
            marginBottom: '5px',
            '& > div:nth-child(1)': {
                fontWeight: 'bold',
                width: '40%',
            },
            '& > div:nth-child(2)': {
                width: '60%',
                textAlign: 'right',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
    },
    uploadButton: {
        fontSize: '12px',
        borderRadius: '0px',
        backgroundColor: theme.palette.primary.main,
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
}));

const AppraisalHistory = () => {
    const client = useApolloClient();
    const {
        moveType, addImages, deleteImages, setPhotoIndex,
    } = useImagesActions();
    const {
        loadFilterValues,
        setValuesInSelections,
        resetFilters,
        applyFilters,
    } = useFilterActions();
    const classes = { ...useStyle(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const keyStore = new KeyStore();
    const canWrite = keyStore.hasPermission(Permission.INVENTORY_APPRAISALS_WRITE);

    const tableReference = useRef();
    const [state, dispatch] = useReducer(reducer, INIT_STATE);
    const {
        params: {
            start,
            limit,
            ignoreLimit,
            field,
            dir,
            filters,
            startDate,
            endDate,
        },
        table,
        columnsData,
        isPullingData,
        isExportingExcel,
        tempStartDate,
        tempEndDate,
    } = state;

    const {
        data: historyData,
        loading,
        error,
    } = useQuery(ReportQuery.GET_APPRAISAL_HISTORY_REPORT, {
        variables: {
            paginate: {
                start,
                limit,
                ignoreLimit,
            },
            sort: {
                field,
                dir,
            },
            filters: filters.length > 0 ? filters : null,
            startDate,
            endDate,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getImages] = useLazyQuery(InventoryQuery.PULL_APPRAISAL_IMAGES, {
        onCompleted: (response) => {
            if (response) {
                const images = response.pullAppraisalImages;
                const clone = cloneDeep(state.table.records);

                const index = clone.findIndex((record) => record.appraisalListId === state.appraisalListId);
                if (index >= 0) clone[index] = { ...clone[index], images: (images || []).length };

                dispatch({
                    type: ACTION_TYPES.SET_FIELD_IN_ROOT,
                    value: {
                        appraisalImages: images,
                        table: {
                            records: clone,
                            totalCount: state.table.totalCount,
                        },
                    },
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const onOpenImage = (index) => {
        dispatch({
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                openImagePreview: !state.openImagePreview,
                photoIndex: index,
            },
        });
    };

    const toggleImagePreview = () => {
        dispatch({
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                openImagePreview: !state.openImagePreview,
            },
        });
    };

    const onSetPhotoIndex = (type) => setPhotoIndex(
        type,
        state.photoIndex,
        state.appraisalImages,
        dispatch,
        ACTION_TYPES.SET_FIELD_IN_ROOT,
        true,
    );

    const onDeleteImages = async (selectedImages, toggleImagesDeletionPrompt) => {
        const payload = {
            variables: {
                appraisalListId: state.appraisalListId,
            },
        };

        deleteImages(
            state.appraisalImages,
            'appraisalListImageId',
            InventoryMutation.REMOVE_APPRAISAL_IMAGES,
            selectedImages,
            toggleImagesDeletionPrompt,
            getImages,
            payload,
        );
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }

        if (!loading) {
            const response = historyData?.getAppraisalHistoryReport;
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records: response.data,
                        totalCount: response.totalCount,
                    },
                    isPullingData: false,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error]);

    const onDateChange = (name, value) => {
        dispatch({
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                [name]: value,
            },
        });
    };

    const applyDateRange = () => {
        const updatedStartDate = DateUtils
            .getFormattedDateInUserTimezone(tempStartDate, DateFormat.DEFAULT_DATE);
        const updatedEndDate = DateUtils
            .getFormattedDateInUserTimezone(tempEndDate, DateFormat.DEFAULT_DATE);

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                startDate: updatedStartDate,
                endDate: updatedEndDate,
            },
            isPullingData: startDate !== updatedStartDate || endDate !== updatedEndDate,
        });
    };

    const onParamsChange = (param, value) => {
        if (param === 'page') {
            dispatch({
                type: ACTION_TYPES.SET_PARAMS,
                value: {
                    start: value.init,
                },
                isPullingData: true,
            });

            return;
        }

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                [param]: value,
            },
            isPullingData: true,
        });
    };

    const onSort = ({ sortBy, sortDirection }) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                field: sortBy,
                dir: sortDirection,
                start: 0,
            },
        });
    };

    const onLoadFilterValues = async (column, searchInput = null) => {
        const data = await loadFilterValues(
            columnsData,
            column,
            searchInput,
            FilterSource.APPRAISALS,
            null,
            startDate,
            endDate,
        );

        if (data) {
            dispatch({
                type: ACTION_TYPES.SET_COLUMNS_DATA,
                value: data,
            });
        }
    };

    const onSetValuesInSelections = (column, values) => {
        const data = setValuesInSelections(
            columnsData,
            column,
            values,
        );

        if (data) {
            dispatch({
                type: ACTION_TYPES.SET_COLUMNS_DATA,
                value: data,
            });
        }
    };

    const onApplyFilters = (column, selectedValues) => {
        const data = applyFilters(
            filters,
            column,
            selectedValues,
        );

        const updatedColumnsData = setValuesInSelections(
            columnsData,
            column,
            selectedValues,
        );

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                start: 0,
                filters: data,
            },
            columnsData: updatedColumnsData,
        });

        if (tableReference.current) tableReference.current.closeFilterBox();
    };

    const onResetFilters = (column) => {
        const data = resetFilters(
            columnsData,
            column,
        );

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                start: 0,
                filters: !column ? [] : filters.filter((item) => item.columnName !== column),
            },
            columnsData: data,
        });

        if (column && tableReference.current) tableReference.current.closeFilterBox();
    };

    const exportToExcel = async () => {
        const name = `appraisal-history-${startDate.replace(/\//g, '-')}-${endDate.replace(/\//g, '-')}`;
        const spreadSheet = new SpreadsheetUtils(name);

        dispatch({
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                isExportingExcel: true,
            },
        });

        try {
            const response = await client.query({
                query: ReportQuery.GET_APPRAISAL_HISTORY_REPORT,
                variables: {
                    paginate: {
                        start: 0,
                        ignoreLimit: true,
                    },
                    sort: {
                        field,
                        dir,
                    },
                    filters: filters.length > 0 ? filters : null,
                    startDate,
                    endDate,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (response) {
                const { data: history } = response.data?.getAppraisalHistoryReport;
                const rows = history.map((record) => ({
                    'FULL NAME': record.customer.firstName ? `${record.customer.firstName} ${record.customer.lastName}` : '',
                    'CELL PHONE': record.customer.cellPhone || '',
                    EMAIL: record.customer.email || '',
                    DATE: record.appraisalDate,
                    APPRAISER: record.appraiser,
                    VIN: record.vin,
                    VEHICLE: `${record.year} ${record.make} ${record.model} ${record.trim}`,
                    ODOMETER: NumberUtils.applyThousandsFormat(record.odometer),
                    NOTES: record.notes,
                    PURCHASED: record.purchased ? 'Yes' : 'No',
                    WHOLESALE: record.wholesale ? 'Yes' : 'No',
                    'SUGGESTED PRICE': NumberUtils.applyCurrencyFormat(record.suggestedPrice),
                    'MARKET PERCENTAGE': NumberUtils.applyThousandsFormat(record.marketPercentage),
                    'REPAIRS TRANSPORT': NumberUtils.applyCurrencyFormat(record.repairs),
                    MARKUP: NumberUtils.applyCurrencyFormat(record.markUp),
                    'APPRAISED VALUE': NumberUtils.applyCurrencyFormat(record.appraisedValue),
                    'LISTING PRICE': record.listingPrice ? NumberUtils.applyCurrencyFormat(record.listingPrice) : '',
                    'DAYS LISTED': record.daysListed ? NumberUtils.applyThousandsFormat(record.daysListed) : '',
                    SELLER: record.seller || '',
                }));

                const widthSizePixel = 150;
                const firstRow = rows[0];
                const columnsProperties = { sizes: Object.keys(firstRow).map(() => widthSizePixel) };
                await spreadSheet.addWorkSheet(rows, columnsProperties, 'Report');
                await spreadSheet.download();

                dispatch({
                    type: ACTION_TYPES.SET_FIELD_IN_ROOT,
                    value: {
                        isExportingExcel: false,
                    },
                });
            }
        } catch (err) {
            ModalUtils.errorMessage(null, 'Error downloading the excel file');
        }
    };

    const togglePicturesForm = (id) => {
        if (!state.isPicturesFormOpen) {
            getImages({
                variables: {
                    appraisalListId: id,
                },
            });
        }

        dispatch({
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                appraisalListId: id,
                isPicturesFormOpen: !state.isPicturesFormOpen,
                appraisalImages: null,
            },
        });
    };

    const onAddImages = async (files) => {
        const dispatcherPayload = {
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                isUploadingImages: true,
            },
        };

        const additionalDispatcherPayload = {
            type: ACTION_TYPES.SET_FIELD_IN_ROOT,
            value: {
                isUploadingImages: false,
            },
        };

        const pathPrefix = `appraisals/${state.appraisalListId}`;
        addImages(
            pathPrefix,
            files,
            dispatch,
            dispatcherPayload,
            additionalDispatcherPayload,
            'appraisalListId',
            state.appraisalListId,
            null,
            InventoryMutation.SAVE_APPRAISAL_IMAGES,
            getImages,
        );
    };

    const columns = [
        {
            label: 'Full Name',
            dataKey: 'fullName',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!record.customer?.firstName) return null;

                return (
                    <span className={classes.ellipsis}>{`${record.customer.firstName} ${record.customer.lastName}`}</span>
                );
            },
        },
        {
            label: 'Cell Phone',
            dataKey: 'cellPhone',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.customer?.cellPhone}</span>
                );
            },
        },
        {
            label: 'Email',
            dataKey: 'email',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.customer?.email}</span>
                );
            },
        },
        {
            label: 'Date',
            dataKey: 'modifiedOn',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.appraisalDate}</span>
                );
            },
        },
        {
            label: 'Appraiser',
            dataKey: 'appraiser',
            width: 140,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.appraiser}</span>
                );
            },
        },
        {
            label: 'VIN',
            dataKey: 'vin',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <RouteLink
                        to={`/${modules.INVENTORY}/appraisals?vin=${record.vin}&odometer=${record.odometer}`}
                        target="_blank"
                    >
                        <span className={classes.ellipsis}>{record.vin}</span>
                    </RouteLink>
                );
            },
        },
        {
            label: 'Vehicle',
            dataKey: 'make',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{`${record.year} ${record.make} ${record.model} ${record.trim}`}</span>
                );
            },
        },
        {
            label: 'Odometer',
            dataKey: 'miles',
            width: 90,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{NumberUtils.applyThousandsFormat(record.odometer)}</span>
                );
            },
        },
        {
            label: 'Notes',
            dataKey: 'notes',
            width: 150,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.notes}</span>
                );
            },
        },
        {
            label: 'Purchased',
            dataKey: 'purchased',
            width: 90,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.purchased ? 'Yes' : 'No'}</span>
                );
            },
        },
        {
            label: 'Wholesale',
            dataKey: 'wholesale',
            width: 90,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.wholesale ? 'Yes' : 'No'}</span>
                );
            },
        },
        {
            label: 'Photos',
            dataKey: 'photos',
            width: 90,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <Button
                        size="small"
                        className={clsx(classes.containedSecondaryInfo, classes.uploadButton, (record.images || 0) > 0 ? classes.greenUploadButton : '')}
                        onClick={() => togglePicturesForm(record.appraisalListId)}
                    >
                        {`View (${record.images || 0})`}
                    </Button>
                );
            },
        },
        {
            label: 'Suggested Price',
            dataKey: 'suggestedPrice',
            width: 140,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{NumberUtils.applyCurrencyFormat(record.suggestedPrice)}</span>
                );
            },
        },
        {
            label: '% of Market',
            dataKey: 'marketPercentage',
            width: 100,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.marketPercentage}</span>
                );
            },
        },
        {
            label: 'Repairs & Transport',
            dataKey: 'repairs',
            width: 170,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{NumberUtils.applyCurrencyFormat(record.repairs)}</span>
                );
            },
        },
        {
            label: 'Mark Up',
            dataKey: 'markUp',
            width: 90,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{NumberUtils.applyCurrencyFormat(record.markUp)}</span>
                );
            },
        },
        {
            label: 'Appraised Value',
            dataKey: 'appraisedValue',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{NumberUtils.applyCurrencyFormat(record.appraisedValue)}</span>
                );
            },
        },
        {
            label: 'Listing Price',
            dataKey: 'listingPrice',
            width: 120,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.listingPrice ? NumberUtils.applyCurrencyFormat(record.listingPrice) : ''}</span>
                );
            },
        },
        {
            label: 'Days Listed',
            dataKey: 'daysListed',
            width: 100,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.ellipsis}>{record.daysListed}</span>
                );
            },
        },
        {
            label: 'Seller',
            dataKey: 'seller',
            width: 140,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!record.seller) return null;

                return (
                    <a
                        target="_blank"
                        href={record.listingURL}
                        rel="noopener noreferrer"
                        className={classes.ellipsis}
                    >
                        {record.seller}
                    </a>
                );
            },
        },
    ];

    return (
        <Container
            maxWidth={false}
            disableGutters
            className={clsx({ [classes.noPadding]: isMobile },
                { [classes.container]: !isMobile })}
        >
            <ReportHeader
                titleReport="Appraisal History"
            >
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>Start Date:</Form.Label>
                    <DatePicker
                        allowSameDay
                        title="Start Date"
                        selected={tempStartDate}
                        size="sm"
                        className={clsx('form-control form-control-sm')}
                        popperContainer={CalendarContainer}
                        maxDate={tempEndDate}
                        onChange={(date) => onDateChange('tempStartDate', date)}
                    />
                </Form.Group>
                <Form.Group as={Row} className={classes.group}>
                    <Form.Label className={classes.subLabel}>End Date:</Form.Label>
                    <DatePicker
                        allowSameDay
                        title="End Date"
                        selected={tempEndDate}
                        size="sm"
                        className={clsx('form-control form-control-sm')}
                        popperContainer={CalendarContainer}
                        minDate={tempStartDate}
                        onChange={(date) => onDateChange('tempEndDate', date)}
                    />
                </Form.Group>
                <Button
                    className={classes.goButton}
                    variant="outlined"
                    startIcon={<CachedIcon />}
                    disabled={loading || isExportingExcel}
                    size="small"
                    onClick={applyDateRange}
                >
                    Go
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<ExcelIcon />}
                    disabled={loading || isExportingExcel}
                    size="small"
                    onClick={exportToExcel}
                >
                    Excel
                </Button>
            </ReportHeader>
            <ReportContent loading={isPullingData}>
                {isMobile && (
                    <div className={classes.mobileContainer}>
                        {table.records.map((record, index) => (
                            <div key={index} className={classes.box}>
                                <div className={classes.boxTitle}>
                                    {`Customer: ${record.customer.firstName} ${record.customer.lastName}`}
                                </div>
                                <div className={classes.boxContent}>
                                    <div>
                                        <div>Cell Phone:</div>
                                        <div>{record.customer.cellPhone}</div>
                                    </div>
                                    <div>
                                        <div>Email:</div>
                                        <div>{record.customer.email}</div>
                                    </div>
                                    <div>
                                        <div>Date:</div>
                                        <div>{record.appraisalDate}</div>
                                    </div>
                                    <div>
                                        <div>Appraiser:</div>
                                        <div>{record.appraiser}</div>
                                    </div>
                                    <div>
                                        <div>VIN:</div>
                                        <div>{record.vin}</div>
                                    </div>
                                    <div>
                                        <div>Vehicle:</div>
                                        <div>{`${record.year} ${record.make} ${record.model} ${record.trim}`}</div>
                                    </div>
                                    <div>
                                        <div>Odometer:</div>
                                        <div>{NumberUtils.applyThousandsFormat(record.odometer)}</div>
                                    </div>
                                    <div>
                                        <div>Notes:</div>
                                        <div>{record.notes}</div>
                                    </div>
                                    <div>
                                        <div>Purchased:</div>
                                        <div>{record.purchased ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div>
                                        <div>Suggested Price:</div>
                                        <div>{NumberUtils.applyCurrencyFormat(record.suggestedPrice)}</div>
                                    </div>
                                    <div>
                                        <div>Market Percentage:</div>
                                        <div>{NumberUtils.applyThousandsFormat(record.marketPercentage)}</div>
                                    </div>
                                    <div>
                                        <div>Repairs & Transport:</div>
                                        <div>{NumberUtils.applyCurrencyFormat(record.repairs)}</div>
                                    </div>
                                    <div>
                                        <div>Mark Up:</div>
                                        <div>{NumberUtils.applyCurrencyFormat(record.markUp)}</div>
                                    </div>
                                    <div>
                                        <div>Appraised Value:</div>
                                        <div>{NumberUtils.applyCurrencyFormat(record.appraisedValue)}</div>
                                    </div>
                                    <div>
                                        <div>Listing Price:</div>
                                        <div>{record.listingPrice ? NumberUtils.applyCurrencyFormat(record.listingPrice) : ''}</div>
                                    </div>
                                    <div>
                                        <div>Days Listed:</div>
                                        <div>{record.daysListed ? NumberUtils.applyThousandsFormat(record.daysListed) : ''}</div>
                                    </div>
                                    <div>
                                        <div>Seller:</div>
                                        <div>
                                            {record.seller ? (
                                                <a
                                                    target="_blank"
                                                    href={record.listingURL}
                                                    rel="noopener noreferrer"
                                                    className={classes.ellipsis}
                                                >
                                                    {record.seller}
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {!isMobile && (
                    <div className={classes.tableContainer}>
                        <VirtualTable
                            ref={tableReference}
                            loading={loading}
                            rowHeight={75}
                            data={table.records}
                            columns={columns}
                            sort={onSort}
                            sortBy={field}
                            sortDirection={dir}
                            filters={filters}
                            loadFilterValues={onLoadFilterValues}
                            setValuesInSelections={onSetValuesInSelections}
                            resetFilters={onResetFilters}
                            applyFilters={onApplyFilters}
                            columnsData={columnsData}
                        />
                    </div>
                )}
            </ReportContent>
            <ReportFooter
                onPageChange={(value) => onParamsChange('page', value)}
                onRowsPerPageChange={(value) => onParamsChange('limit', value)}
                currentPage={start}
                rowsPerPage={limit}
                totalRecords={table.totalCount}
                labelRowsPerPage="Records per Page"
            />
            <InspectionImagesDialog
                isPicturesFormOpen={state.isPicturesFormOpen}
                isUploadingImages={state.isUploadingImages}
                openImagePreview={state.openImagePreview}
                addImages={onAddImages}
                canWrite={canWrite}
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={state.appraisalImages}
                onOpenImage={onOpenImage}
                photoIndex={state.photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                onDeleteImages={onDeleteImages}
                moveType={moveType}
                isApproved={false}
            />
        </Container>
    );
};

export default AppraisalHistory;

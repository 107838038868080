import React, { useContext, useEffect, useReducer } from 'react';
import {
    makeStyles, Dialog, DialogContent, AppBar, Toolbar, Typography,
} from '@material-ui/core';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from 'components/widgets/modal/DialogActions';
import Split from 'react-split';
import { useLazyQuery, useMutation } from '@apollo/client';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import { FetchPolicy } from 'utils/enum/Core';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';
import ModalUtils from 'utils/ModalUtils';
import OperationCodeParts from 'components/modules/service/opCode/OperationCodeParts';
import { Col, Form, Row } from 'react-bootstrap';
import FieldsetTitle from 'components/widgets/fieldsetTitle/fieldsetTitle';
import InputNumber from 'components/widgets/InputNumber';
import { OperationCodeDetailSchemaList, OperationCodeSchema } from 'utils/schema/service/OperationCodeSchema';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { useHistory, useParams } from 'react-router';
import { modules } from 'utils/enum/modules';
import { ServiceSubModules } from 'utils/enum/ServiceInvoiceEnum';
import ServiceMapper from 'services/mapData/ServiceMapper';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import UserContext from 'components/context/UserContext';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';

const useStyle = makeStyles((theme) => AccountingStyles.checkActionList(theme));
const ownStyle = makeStyles((theme) => (
    {
        dialogContent: {
            height: '62vh',
            '& > div:first-child': {
                height: '62vh',
            },
            padding: 0,
            overflow: 'hidden',
        },
        mainTable: {
            height: 'calc(100vh - 420px)',
        },
        search: {
            borderRadius: 10,
            width: 400,
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
        buttonSave: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.text.white,
            '&:hover': {
                backgroundColor: theme.palette.success.dark,
            },
        },
        hoursLaborDivs: {
            marginLeft: 0,
            marginRight: 0,
        },
    }
));

const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValues',
    SET_INITIAL_STATE: 'setInitialState',
    ADD_SELECTED_PARTS: 'addSelectedParts',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    DELETE_PART: 'deletePart',
};

const OpCodeReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return { ...state, record: action.payload };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        return {
            ...state,
            record: {
                ...state.record,
                ...action.payload,
            },
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction && action.fireActionParam) action.fireAction(action.fireActionParam);
        if (action.fireAction && !action.fireActionParam) action.fireAction();

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.payload;
    }
    case ACTION_TYPES.ADD_SELECTED_PARTS:
    {
        const partsSelected = action.payload;
        const parts = state.record.detail;

        let currentIndex = (parts?.length ?? 0) * -1;

        partsSelected.forEach((data) => {
            currentIndex -= 1;

            const recordExist = parts.find((c) => c.partInventoryId === data.partsInventoryId);
            if (recordExist) {
                parts.forEach((item) => {
                    if (item.partInventoryId === data.partsInventoryId) {
                        // eslint-disable-next-line no-param-reassign
                        item.quantity += 1;
                    }
                });

                return;
            }

            parts.push({
                jobTemplateDetailId: currentIndex,
                jobTemplateId: state.record.opCodeId,
                partInventoryId: data.partsInventoryId,
                partStockNumber: data.partStockNumber,
                description: data.description,
                partCost: data.partCost,
                netPrice: data.netPrice,
                listPrice: data.listPrice,
                quantity: 1,
            });
        });

        return {
            ...state,
            record: {
                ...state.record,
                detail: parts,
            },
            isDirty: true,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        const keyValue = 'jobTemplateDetailId';
        const {
            value, columnId, cell,
        } = action.payload;

        const newRecords = state.record.detail.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;
            }

            return newItem;
        });

        return {
            ...state,
            record: {
                ...state.record,
                detail: newRecords,
            },
            isDirty: true,
        };
    }
    case ACTION_TYPES.DELETE_PART: {
        const deleteId = action.payload;
        const currentParts = state.record.detail?.filter((c) => c.jobTemplateDetailId !== deleteId);

        return {
            ...state,
            record: {
                ...state.record,
                detail: currentParts,
            },
            isDirty: true,
        };
    }
    default:
        return state;
    }
};

const initState = {
    record: {
        opCodeId: 0,
        opCode: '',
        customerStates: '',
        cause: '',
        correction: '',
        note: '',
        hours: 0,
        laborAmount: 0,
        detail: [],
    },
    isDirty: false,
};

const OperationCodeCreate = () => {
    const { id: opCodeId } = useParams();
    const history = useHistory();

    const classes = {
        ...useStyle(),
        ...ownStyle(),
    };

    const {
        appBar, centerItems, title, dialogContent,
    } = classes;

    const [state, dispatch] = useReducer(OpCodeReducer, initState);

    const { defaultLot } = useContext(UserContext);

    const { record, isDirty } = state;

    const { isValid, errors } = isValidSchema(OperationCodeSchema, record);
    const { isValid: isValidParts } = isValidSchema(OperationCodeDetailSchemaList, record.detail);

    const onClose = () => {
        history.push(`/${modules.SERVICE}/${ServiceSubModules.OPERATION_CODES}`);
    };

    const [getOperationCode, { loading }] = useLazyQuery(ServiceQuery.GET_SERVICE_OPERATION_CODE, {
        onCompleted: (res) => {
            if (res.getOperationCode) {
                const proccesedRecord = ServiceMapper.mapOperationCode(res.getOperationCode);

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    payload: proccesedRecord,
                });
            }
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveOperationCode, { loading: isSaving }] = useMutation(ServiceMutation.CREATE_OPERATION_CODE, {
        onCompleted: (mutationData) => {
            if (mutationData?.createOperationCode) {
                ModalUtils.successMessage(null, 'Successfully saved!');
                onClose();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [updateOperationCode, { loading: isUpdating }] = useMutation(ServiceMutation.UPDATE_OPERATION_CODE, {
        onCompleted: (mutationData) => {
            if (mutationData?.updateOperationCode) {
                ModalUtils.successMessage(null, 'Successfully saved!');
                onClose();
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSaveOpCode = () => {
        const recordToSave = ServiceMapper.mapOperationCodeToSave(record);

        saveOperationCode({
            variables: {
                record: recordToSave,
            },
        });
    };

    const onUpdateOpCode = () => {
        const recordToUpdate = ServiceMapper.mapOperationCodeToSave(record);

        updateOperationCode({
            variables: {
                record: recordToUpdate,
            },
        });
    };

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            payload: {
                [field]: value,
            },
        });
    };

    const onAddPart = (values) => {
        dispatch({
            type: ACTION_TYPES.ADD_SELECTED_PARTS,
            payload: values,
        });
    };

    const onDeletePart = (deleteId) => {
        dispatch({
            type: ACTION_TYPES.DELETE_PART,
            payload: deleteId,
        });
    };

    const onChangePart = (columnId, value, cell) => {
        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            payload: {
                columnId, value, cell,
            },
        });
    };

    useEffect(() => {
        if (opCodeId > 0) {
            getOperationCode({
                variables: {
                    id: opCodeId,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        opCode,
        customerStates,
        cause,
        correction,
        hours,
        laborAmount,
        note,
        description,
        recommends,
    } = record;

    const keyStore = new KeyStore();
    const OPERATION_CODE_EDIT = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_EDIT);

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                maxWidth="md"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
            >
                <AppBar className={appBar}>
                    <Toolbar className={centerItems}>
                        <Typography variant="h6" className={title}>
                            {opCodeId > 0 ? 'Modify Op Code' : 'Create Op Code'}
                        </Typography>
                        <div className={centerItems}>
                            <IconButton edge="start" color="inherit" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent className={dialogContent}>
                    <Split
                        sizes={[50, 50]}
                        className={classes.split}
                        direction="vertical"
                        minSize={0}
                    >
                        <div className={classes.main}>
                            <Form as={Row}>
                                <Col lg={4}>
                                    <FieldsetTitle title="">
                                        <Form as={Row}>
                                            <Col xs={12} sm={6} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Op Code</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        name="opCode"
                                                        value={opCode}
                                                        onChange={(e) => onChangeValue('opCode', e.target.value)}
                                                        className={isValidField(errors, 'opCode') ? 'invalid-field' : ''}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        name="description"
                                                        value={description || ''}
                                                        onChange={(e) => onChangeValue('description', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Customer States</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        name="customerStates"
                                                        value={customerStates || ''}
                                                        onChange={(e) => onChangeValue('customerStates', e.target.value)}
                                                        className={isValidField(errors, 'customerStates') ? 'invalid-field' : ''}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form>
                                    </FieldsetTitle>
                                </Col>
                                <Col lg={4}>
                                    <FieldsetTitle title="">
                                        <Form as={Row}>
                                            <Col xs={12} sm={3} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Cause</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        value={cause || ''}
                                                        onChange={(e) => onChangeValue('cause', e.target.value)}
                                                        className={isValidField(errors, 'cause') ? 'invalid-field' : ''}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Correction</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        value={correction || ''}
                                                        onChange={(e) => onChangeValue('correction', e.target.value)}
                                                        className={isValidField(errors, 'correction') ? 'invalid-field' : ''}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Note</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        value={note || ''}
                                                        onChange={(e) => onChangeValue('note', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form>
                                    </FieldsetTitle>
                                </Col>
                                <Col lg={4}>
                                    <FieldsetTitle title="">
                                        <Form as={Row}>
                                            <Col xs={12} sm={3} lg={12}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Recommends</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        size="sm"
                                                        rows="2"
                                                        value={recommends || ''}
                                                        onChange={(e) => onChangeValue('recommends', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6} lg={12}>
                                                <Form.Group as={Row} className={classes.hoursLaborDivs}>
                                                    <Form.Label column lg={6}>Hours</Form.Label>
                                                    <Col lg={6}>
                                                        <InputNumber
                                                            size="sm"
                                                            allowNegative={false}
                                                            value={hours}
                                                            placeholder="0"
                                                            onChange={(value) => onChangeValue('hours', value)}
                                                            alignRight
                                                            className={(isValidField(errors, 'hours')) ? 'invalid-field' : ''}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className={classes.hoursLaborDivs}>
                                                    <Form.Label column lg={6}>Labor Sale</Form.Label>
                                                    <Col lg={6}>
                                                        <InputNumber
                                                            size="sm"
                                                            showCurrency
                                                            allowNegative={false}
                                                            value={laborAmount}
                                                            thousandSeparator
                                                            placeholder="0"
                                                            onChange={(value) => onChangeValue('laborAmount', value)}
                                                            alignRight
                                                            className={(isValidField(errors, 'laborAmount')) ? 'invalid-field' : ''}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Form>
                                    </FieldsetTitle>
                                </Col>
                            </Form>
                        </div>
                        <div>
                            <OperationCodeParts
                                parts={record?.detail ?? []}
                                onAddPart={onAddPart}
                                onDeletePart={onDeletePart}
                                onChangePart={onChangePart}
                                lotName={defaultLot}
                            />
                        </div>
                    </Split>
                </DialogContent>
                <DialogActions
                    titlePrimary="Save"
                    onClickSecondary={onClose}
                    onClickPrimary={opCodeId > 0 ? onUpdateOpCode : onSaveOpCode}
                    hiddenPrimaryButton={!OPERATION_CODE_EDIT}
                    disablePrimaryButton={!(isDirty && isValid && isValidParts) || (isSaving || isUpdating) || (!OPERATION_CODE_EDIT)}
                />
            </Dialog>
            {isSaving && <DialogActionMessage message="Saving information... " />}
            {loading && <DialogActionMessage message="Loading information... " />}
        </>
    );
};

export default OperationCodeCreate;

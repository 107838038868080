import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    FormLabel,
    makeStyles,
    Checkbox,
    TextField,
    Button,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';
import Switch from '@material-ui/core/Switch';
import InputNumber from 'components/widgets/InputNumber';
import If from 'components/widgets/conditional/If';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    editPanel: {
        padding: '5px 5px 0',
        border: `1px solid ${theme.palette.border.mercuryLight}`,
        boxShadow: `1px 2px 5px 0px ${theme.palette.background.silver}`,
        margin: '0 10px 15px 10px',
        paddingBottom: '30px',
    },
    categoryContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px',
        backgroundColor: theme.palette.background.darkPurple,
        border: `1px solid ${theme.palette.border.ghost}`,
        '& > label': {
            color: `${theme.palette.text.white} !important`,
        },
    },
    nameContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '5px',
        fontSize: '13px',
        marginTop: '2px',
        border: `1px solid ${theme.palette.border.ghost}`,
        '& > label': {
            color: theme.palette.text.waterloo,
        },
    },
    title: {
        color: theme.palette.text.waterloo,
        fontSize: 13,
        fontWeight: 'bold',
    },
    element: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        fontSize: '13px',
        '& > div:nth-child(2)': {
            width: '60%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > div.MuiFormControl-root': {
                width: '100%',
            },
        },
    },
    passCheck: {
        color: theme.palette.text.green,
    },
    soonCheck: {
        color: theme.palette.text.barberry,
    },
    failCheck: {
        color: theme.palette.text.red,
    },
    uploadButton: {
        padding: '4px 4px !important',
    },
    greenUploadButton: {
        backgroundColor: `${theme.palette.background.green} !important`,
    },
    textFieldSmall: {
        '& > div': {
            height: '30px',
        },
    },
    select: {
        width: '100%',
        border: `1px solid ${theme.palette.border.ghost}`,
        borderRadius: '0.25rem',
        height: '32px',
        '& > div': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
        },
    },
    invalidSelect: {
        border: `1px solid ${theme.palette.border.red}`,
    },
}));

const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 250,
        },
    },
    getContentAnchorEl: null,
};

const InspectionCategoryPanel = ({
    data,
    isMPI,
    canWrite,
    onChange,
    togglePicturesForm,
    inspectionComplete,
    jobTypes,
    isApproved,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { name: categoryName, items } = data;

    return (
        <div className={clsx(classes.root, classes.editPanel)}>
            <div className={classes.categoryContainer}>
                <FormLabel className={classes.title}>
                    { categoryName }
                </FormLabel>
            </div>
            <div className={classes.itemsContainer}>
                {items.map((item, index) => {
                    const { reconInspectionItemId, current, images } = item;
                    const totalPictures = images ?? 0;

                    return (
                        <div key={`${item.name}-${index}`} className={classes.itemContainer}>
                            <div className={classes.nameContainer}>
                                <FormLabel className={classes.title}>
                                    { item.name }
                                </FormLabel>
                            </div>
                            <div className={classes.element}>
                                <div>Pass</div>
                                <If condition={canWrite}>
                                    <div>
                                        <Checkbox
                                            color="default"
                                            className={classes.passCheck}
                                            checked={current?.pass || false}
                                            onChange={(_, value) => onChange('pass', value, reconInspectionItemId)}
                                        />
                                    </div>
                                </If>
                            </div>
                            {isMPI && (
                                <div className={classes.element}>
                                    <div>Soon</div>
                                    <If condition={canWrite}>
                                        <div>
                                            <Checkbox
                                                color="default"
                                                className={classes.soonCheck}
                                                checked={current?.soon || false}
                                                onChange={(_, value) => onChange('soon', value, reconInspectionItemId)}
                                            />
                                        </div>
                                    </If>
                                </div>
                            )}
                            <div className={classes.element}>
                                <div>Fail</div>
                                <If condition={canWrite}>
                                    <div>
                                        <Checkbox
                                            color="default"
                                            className={classes.failCheck}
                                            checked={current?.fail || false}
                                            onChange={(_, value) => onChange('fail', value, reconInspectionItemId)}
                                        />
                                    </div>
                                </If>
                            </div>
                            {!isMPI && (
                                <div className={classes.element}>
                                    <div>Part</div>
                                    <If condition={canWrite}>
                                        <div>
                                            <Checkbox
                                                disabled={current?.fail ? !current.fail : true}
                                                checked={current?.part || false}
                                                onChange={(_, value) => onChange('part', value, reconInspectionItemId)}
                                            />
                                        </div>
                                    </If>
                                </div>
                            )}
                            <div className={classes.element}>
                                <div>Repair</div>
                                <If condition={canWrite}>
                                    <div>
                                        <Switch
                                            disabled={current?.fail ? !current.fail : true}
                                            checked={current?.repair || false}
                                            onChange={(_, value) => onChange('repair', value, reconInspectionItemId)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </If>
                            </div>
                            {isMPI && (
                                <div className={classes.element}>
                                    <div>Job Types</div>
                                    <If condition={canWrite}>
                                        <div>
                                            <Select
                                                disabled={!current?.repair || inspectionComplete}
                                                className={clsx(
                                                    classes.select,
                                                    current?.repair && (current?.jobTypes || []).length === 0 ? classes.invalidSelect : '',
                                                )}
                                                multiple
                                                value={current?.jobTypes || []}
                                                onChange={({ target: { value } }) => onChange('jobTypes', value, reconInspectionItemId)}
                                                renderValue={
                                                    (selected) => selected.map((id) => jobTypes.find((el) => el.enumValuesId === id)?.description).join(', ')
                                                }
                                                MenuProps={MenuProps}
                                            >
                                                {jobTypes.map(({ enumValuesId, description }, idx) => (
                                                    <MenuItem key={idx} value={enumValuesId}>
                                                        <Checkbox checked={(current?.jobTypes || []).includes(enumValuesId)} />
                                                        <ListItemText primary={description} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </If>
                                </div>
                            )}
                            <div className={classes.element}>
                                <div>Comments</div>
                                <If condition={canWrite}>
                                    <div>
                                        <TextField
                                            disabled={isApproved}
                                            className={classes.textFieldSmall}
                                            value={current?.comment || ''}
                                            name="comment"
                                            onChange={({ target: { name, value } }) => onChange(name, value, reconInspectionItemId)}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </div>
                                </If>
                            </div>
                            {!isMPI && (
                                <div className={classes.element}>
                                    <div>Estimate</div>
                                    <If condition={canWrite}>
                                        <div>
                                            <InputNumber
                                                disabled={(current?.fail ? !current.fail : true) || isApproved}
                                                value={current?.estimation || 0}
                                                onChange={(newValue) => onChange('estimation', newValue, reconInspectionItemId)}
                                                placeholder="$0"
                                                showCurrency
                                                min={0}
                                                decimalScale={0}
                                                thousandSeparator
                                                size="sm"
                                            />
                                        </div>
                                    </If>
                                </div>
                            )}
                            <div className={classes.element}>
                                <div>Pictures</div>
                                <If condition={canWrite}>
                                    <div>
                                        <Button
                                            size="medium"
                                            className={clsx(
                                                classes.containedSecondaryInfo,
                                                classes.uploadButton,
                                                totalPictures > 0 ? classes.greenUploadButton : '',
                                            )}
                                            onClick={() => togglePicturesForm(item)}
                                        >
                                            {`View (${totalPictures})`}
                                        </Button>
                                    </div>
                                </If>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

InspectionCategoryPanel.propTypes = {
    data: PropTypes.object.isRequired,
    canWrite: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    togglePicturesForm: PropTypes.func.isRequired,
    isApproved: PropTypes.bool,
    isMPI: PropTypes.bool,
    inspectionComplete: PropTypes.bool,
    jobTypes: PropTypes.array,
};

InspectionCategoryPanel.defaultProps = {
    canWrite: false,
    isApproved: false,
    isMPI: false,
    inspectionComplete: false,
    jobTypes: [],
};

export default InspectionCategoryPanel;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
    Grid, Tooltip,
} from '@material-ui/core';
import { Col, Form } from 'react-bootstrap';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import Select from 'components/widgets/Select';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';

// icons
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '20px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    dialogActions: {
        '& > .form-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '40px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
            },
        },
    },
    subLabel: {
        fontSize: '13px',
        display: 'flex',
        '& > svg': {
            width: '15px',
            height: '15px',
            cursor: 'pointer',
            marginLeft: '5px',
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
    },
    videoLinkHelp: {
        fontSize: '12px',
        fontWeight: 500,
    },
}));

const VideoDialog = ({
    toggleDialog,
    onSave,
    isSavingVideo,
    categories,
    selectedCategory,
    selectedVideo,
    hasGlobalPermission,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const currentCategory = categories.find((cat) => cat.trainingCategoryId === selectedCategory);
    const currentVideo = (currentCategory?.videos ?? []).find((v) => v.trainingVideoId === selectedVideo);

    const [state, setState] = useState({
        videoCategory: currentCategory?.trainingCategoryId,
        videoName: currentVideo?.name,
        videoDescription: currentVideo?.description,
        videoLink: currentVideo?.videoLink ?? 'https://player.vimeo.com/video/',
        matchingRoute: currentVideo?.matchingRoute,
    });

    const {
        videoCategory,
        videoName,
        videoDescription,
        videoLink,
        matchingRoute,
    } = state;

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const isValidVideoLink = !StringUtils.isEmpty(videoLink)
        && StringUtils.isSecureURL(videoLink)
        && (
            videoLink.toLowerCase().includes('player.vimeo.com')
            || videoLink.toLowerCase().includes('youtube.com/embed')
        );
    const isValidRoute = StringUtils.isEmpty(matchingRoute) || (!StringUtils.isEmpty(matchingRoute) && StringUtils.isSecureURL(matchingRoute));
    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Video"
                onClose={() => toggleDialog()}
                toolbarSize="md"
            />
            <DialogContent>
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Category</Form.Label>
                                <Select
                                    nowrap
                                    size="sm"
                                    loading={false}
                                    className={classes.input}
                                    name="videoCategory"
                                    onChange={(name, value) => onChange(name, value)}
                                    value={videoCategory || ''}
                                    options={categories.map((c) => ({ label: c.name, value: c.trainingCategoryId }))}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Name</Form.Label>
                                <Form.Control
                                    autoFocus
                                    className={clsx(classes.input, StringUtils.isEmpty(videoName) ? 'invalid-field' : '')}
                                    spellCheck="false"
                                    type="text"
                                    name="videoName"
                                    value={videoName || ''}
                                    onChange={({ target }) => onChange(target.name, target.value)}
                                    maxLength={255}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Description (optional)</Form.Label>
                                <Form.Control
                                    className={classes.input}
                                    spellCheck="false"
                                    as="textarea"
                                    size="sm"
                                    rows="3"
                                    name="videoDescription"
                                    value={videoDescription || ''}
                                    onChange={({ target }) => onChange(target.name, target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>
                                    Video Link
                                    <Tooltip
                                        title={`
                                            Only vimeo and youtube videos supported. Youtube videos must start with https://www.youtube.com/embed/ + videoId
                                            and Vimeo videos with https://player.vimeo.com/video/ + videoId
                                        `}
                                        placement="top"
                                    >
                                        <HelpOutlinedIcon />
                                    </Tooltip>
                                </Form.Label>
                                <Form.Control
                                    className={clsx(classes.input, !isValidVideoLink ? 'invalid-field' : '')}
                                    spellCheck="false"
                                    type="text"
                                    name="videoLink"
                                    value={videoLink || ''}
                                    onChange={({ target }) => onChange(target.name, target.value)}
                                    maxLength={255}
                                />
                                <div className={classes.videoLinkHelp}>https://www.youtube.com/embed/ | https://player.vimeo.com/video/</div>
                            </Form.Group>
                            {hasGlobalPermission && (
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.subLabel}>
                                        DMS URL (optional)
                                        <Tooltip
                                            title={`
                                                It represents the address at the top bar of the browser to help match a specific video. 
                                                It must start with https://. For detail-like URLs please use {*}. Example: 
https://dmspro.automatrix.com/inventory/140144#advertising -> https://dmspro.automatrix.com/inventory/{*}#advertising
                                            `}
                                            placement="top"
                                        >
                                            <HelpOutlinedIcon />
                                        </Tooltip>
                                    </Form.Label>
                                    <Form.Control
                                        className={
                                            clsx(
                                                classes.input,
                                                !isValidRoute ? 'invalid-field' : '',
                                            )
                                        }
                                        spellCheck="false"
                                        type="text"
                                        name="matchingRoute"
                                        value={matchingRoute || ''}
                                        onChange={({ target }) => onChange(target.name, target.value)}
                                        maxLength={255}
                                    />
                                    <div className={classes.videoLinkHelp}>{'{*}'}</div>
                                </Form.Group>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Form.Group as={Col}>
                    <Button
                        size="small"
                        className={classes.containedSecondaryInfo}
                        disabled={
                            StringUtils.isEmpty(videoName)
                            || !isValidVideoLink
                            || !isValidRoute
                        }
                        onClick={() => onSave(state)}
                    >
                        {isSavingVideo ? 'Saving...' : 'Save'}
                    </Button>
                </Form.Group>
            </DialogActions>
        </Dialog>
    );
};

VideoDialog.defaultProps = {
    categories: [],
    selectedCategory: null,
    selectedVideo: null,
};

VideoDialog.propTypes = {
    toggleDialog: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isSavingVideo: PropTypes.bool.isRequired,
    categories: PropTypes.array,
    selectedCategory: PropTypes.string,
    selectedVideo: PropTypes.string,
    hasGlobalPermission: PropTypes.bool.isRequired,
};

export default VideoDialog;

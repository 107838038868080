import {
    ApolloClient, InMemoryCache, split,
} from '@apollo/client';
import KeyStore from 'utils/KeyStore';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

const {
    REACT_APP_GRAPHQL_HTTP,
    REACT_APP_GRAPHQL_BI_SERVER_HTTP,
    REACT_APP_GRAPHQL_WS,
} = process.env;

const keyStore = new KeyStore();
export const getApolloHeaders = () => ({
    HTTP_URL: REACT_APP_GRAPHQL_HTTP,
    BI_HTTP_URL: REACT_APP_GRAPHQL_BI_SERVER_HTTP ?? REACT_APP_GRAPHQL_HTTP,
    WS_URL: REACT_APP_GRAPHQL_WS,
});

// Get token
const getToken = () => `Bearer ${keyStore.getToken()}`;
const getHashToken = () => keyStore.getHashToken();

// Create auth link to set headers like authorization and others
const authLink = setContext((_, { headers }) => {
    const token = getToken();
    const hashToken = getHashToken();
    return {
        headers: {
            ...headers,
            hashToken: hashToken || '',
            authorization: token || '',
            'x-auth-type': 'oauth2',
        },
    };
});

// Create the Web Socket link to make ws requests
const wsLink = new WebSocketLink({
    uri: getApolloHeaders().WS_URL,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: async () => {
            const token = getToken();
            const hashToken = getHashToken();
            return {
                hashToken,
                authorization: token,
                'x-auth-type': 'oauth2',
            };
        },
    },
});

// Create error link
const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors?.some((x) => x.message === 'UNAUTHENTICATED')
        || networkError?.result?.errors?.some((e) => e.extensions.code === 'UNAUTHENTICATED')) {
        keyStore.clear();
        const { origin } = window.location;
        window.location.replace(`${origin}/login`);
    }
});

// Build an HTTP links
const httpLinkMainServer = createUploadLink({
    uri: getApolloHeaders().HTTP_URL,
});

const httpLinkBIServer = createUploadLink({
    uri: getApolloHeaders().BI_HTTP_URL,
});

// Create multi connections
const link = split(
    ({ operationName }) => [
        'pullRecordsSavedQuery',
        'previewQueryResults',
        'pullQueryResultOfEntity',
        'saveChart',
        'saveRule',
        'printDocument',
    ].includes(operationName),
    errorLink.concat(authLink.concat(httpLinkBIServer)),
    split(
        ({ query }) => { const { kind, operation } = getMainDefinition(query); return kind === 'OperationDefinition' && operation === 'subscription'; },
        wsLink,
        errorLink.concat(authLink.concat(httpLinkMainServer)),
    ),
);

// Build Apollo Client with the specific link
const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        addTypename: false,
    }),
});

export default client;

import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Select from 'components/widgets/Select';
import FormTab from 'styles/modules/deals/FormsTab';
import InputSearch from 'components/widgets/InputSearch';
import { DEALS_FORMS_PACKS_SORTING } from 'utils/enum/General';
import TabContainer from 'components/widgets/tabs/TabContainer';
import FormLibraryTab from 'components/modules/deals/read/form/FormLibraryTab';
import FormDetailContainer from 'components/containers/deals/read/forms/FormDetailContainer';

// Bootstrap
import { Tabs, Tab } from 'react-bootstrap';

// Material UI
import { Button, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormPacksTab from 'components/modules/deals/read/form/FormPacksTab';
import CustomFieldDialog from 'components/modules/deals/create/forms/CustomFieldDialog';

const styles = (theme) => FormTab.formDetail(theme);

const sortList = [
    DEALS_FORMS_PACKS_SORTING.BY_NAME,
    DEALS_FORMS_PACKS_SORTING.BY_DATE_ADDED,
];

class FormDetail extends Component {
    render() {
        const {
            classes, formPackList, formLibrary, packSelected, sortSelected,
            onSavePrintListCheckedDefault, onSearchForm, onCloseCustomField,
            defaultActiveKey, onChangeValueFormPack, accountNumber, tabSelected,
            printingFormWithCustomField, onRemoveFormOfFormPack, formPackListBackUp,
            onPrintList, loadingPrint, onPreview, formPackSelected, onSortingChanged,
            openDialogCustomField, customField, getCustomFieldInformation, setTabSelected,
        } = this.props;
        const listFormSelected = formPackSelected();
        const disabledFormPack = !listFormSelected?.length || loadingPrint;

        return (
            <div className={classes.containerDetail}>
                <TabContainer className={classes.tabContainer}>
                    {tabSelected === 0 && (
                        <div className={classes.sortingDiv}>
                            <FormLabel>Sorting:</FormLabel>
                            <Select
                                size="sm"
                                name="sortSelected"
                                value={sortSelected}
                                className={classes.sortingDropdown}
                                options={sortList.map((value) => ({ label: value, value }))}
                                onChange={(_, value) => onSortingChanged({ sortSelected: value })}
                            />
                        </div>
                    )}
                    <Tabs
                        id="form-tab"
                        mountOnEnter
                        activeKey={tabSelected}
                        onSelect={setTabSelected}
                        defaultActiveKey={defaultActiveKey}
                        className={`${classes.borderContainer} ${classes.navbar}`}
                    >
                        <Tab className={classes.tab} eventKey={0} mountOnEnter title="Form Packs">
                            <div className="d-flex-column">
                                <FormPacksTab
                                    onPreview={onPreview}
                                    records={formPackList}
                                    packSelected={packSelected}
                                    onChangeValueFormPack={onChangeValueFormPack}
                                    onRemoveFormOfFormPack={onRemoveFormOfFormPack}
                                />
                            </div>
                        </Tab>
                        <Tab className={classes.tab} eventKey={1} mountOnEnter title="Form Library">
                            <div className="d-flex-column">
                                <InputSearch
                                    size="sm"
                                    forceSearch
                                    initialSearch=""
                                    onSearch={onSearchForm}
                                    customClasses={classes.search}
                                    executeWhenClearButton={() => onSearchForm('')}
                                />
                                <FormLibraryTab
                                    records={formLibrary}
                                    packSelected={packSelected}
                                    accountNumber={accountNumber}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </TabContainer>
                <div className={classes.footer}>
                    <Button
                        variant="contained"
                        className={classes.save}
                        onClick={onSavePrintListCheckedDefault}
                        disabled={isEqual(formPackList, formPackListBackUp)}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.printButton}
                        onClick={onPrintList}
                        disabled={disabledFormPack}
                    >
                        Print List
                    </Button>
                </div>
                {openDialogCustomField && (
                    <CustomFieldDialog
                        records={customField}
                        open={openDialogCustomField}
                        loading={printingFormWithCustomField}
                        onClose={onCloseCustomField}
                        onClickPrimaryButton={getCustomFieldInformation}
                    />
                )}
            </div>
        );
    }
}

FormDetail.propTypes = {
    defaultActiveKey: PropTypes.number,
    classes: PropTypes.object.isRequired,
    onPreview: PropTypes.func.isRequired,
    onPrintList: PropTypes.func.isRequired,
    formLibrary: PropTypes.array.isRequired,
    onSearchForm: PropTypes.func.isRequired,
    loadingPrint: PropTypes.bool.isRequired,
    customField: PropTypes.array.isRequired,
    formPackList: PropTypes.array.isRequired,
    tabSelected: PropTypes.number.isRequired,
    packSelected: PropTypes.object.isRequired,
    setTabSelected: PropTypes.func.isRequired,
    sortSelected: PropTypes.string.isRequired,
    accountNumber: PropTypes.number.isRequired,
    formPackSelected: PropTypes.func.isRequired,
    onSortingChanged: PropTypes.func.isRequired,
    onCloseCustomField: PropTypes.func.isRequired,
    formPackListBackUp: PropTypes.array.isRequired,
    onChangeValueFormPack: PropTypes.func.isRequired,
    openDialogCustomField: PropTypes.bool.isRequired,
    onRemoveFormOfFormPack: PropTypes.func.isRequired,
    getCustomFieldInformation: PropTypes.func.isRequired,
    printingFormWithCustomField: PropTypes.bool.isRequired,
    onSavePrintListCheckedDefault: PropTypes.func.isRequired,
};

FormDetail.defaultProps = {
    defaultActiveKey: 0,
};

export default withStyles(styles)(FormDetailContainer(FormDetail));

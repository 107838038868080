export default class FileManagerStyles {
    static getStyle(theme = {}) {
        return ({
            dragzone: {
                cursor: 'pointer',
                border: `1px solid ${theme.palette.border.alto}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px 10px',
                transition: 'border-color .3s',
                height: '35px',
                color: theme.palette.text.white,
                backgroundColor: theme.palette.success.main,
                '& > svg': {
                    fill: theme.palette.text.white,
                    width: '20px',
                    height: '20px',
                    marginLeft: '5px',
                },
            },
            tableContainer: {
                marginTop: '3px',
                height: '100%',
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    justifyContent: 'left',
                    padding: '7px 5px',
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                    display: 'flex',
                    '& > .MuiTextField-root': {
                        width: '90%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    },
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            tableHeader: {
                textAlign: 'left',
                color: theme.palette.text.waterloo,
                borderRight: `1px solid ${theme.palette.border.ghost}`,
                height: '100%',
                alignItems: 'center',
            },
            text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            deleteButton: {
                minWidth: '32px',
                '& span.MuiButton-startIcon': {
                    margin: 0,
                },
            },
            loader: {
                position: 'absolute',
                left: '0',
                top: '0',
                zIndex: '999',
                backgroundColor: theme.palette.background.white,
                width: '100%',
                height: '98%',
                opacity: '0.5',
            },
            download: {
                padding: 0,
                minWidth: 'initial',
                marginRight: '10px',
                '& > span > span': {
                    margin: 0,
                    '& > svg': {
                        fontSize: '25px !important',
                    },
                },
            },
            nameWrapper: {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                '& > div:nth-child(1)': {
                    width: '90%',
                    cursor: 'pointer',
                    color: theme.palette.secondary.main,
                    '& > div.MuiOutlinedInput-root': {
                        marginRight: '10px',
                        '& > input': {
                            height: '13px !important',
                        },
                    },
                },
                '& > div:nth-child(2)': {
                    width: '10%',
                    '& > button': {
                        padding: '5px',
                        minWidth: 'initial',
                        '& > span > span': {
                            margin: 0,
                            '& > svg': {
                                fontSize: '16px !important',
                            },
                        },
                    },
                },
            },
            textFieldSmall: {
                '& input': {
                    padding: 7,
                },
            },
        });
    }
}

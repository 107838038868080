import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles, Menu, MenuItem,
    Tooltip, Tabs, Tab,
} from '@material-ui/core';
import {
    PowerSettingsNew as PowerSettingsNewIcon,
} from '@material-ui/icons';
import {
    MinusSquare,
    PlusSquare,
} from 'components/icons/index';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import UserUtils from 'utils/UserUtils';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import GeneralQuery from 'services/graphQL/query/GeneralQuery';
import UserContext from 'components/context/UserContext';
import Avatar from '@material-ui/core/Avatar';
import avatarUser from 'assets/user/userDefault.jpg';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import NotificationBadge, { TYPE } from 'components/layout/NotificationBadge';
import useLogOut from 'components/hook/core/useLogOut';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';

const useStyles = makeStyles((theme) => ({
    '@global': {
        '.pro-sidebar': {
            display: 'none',
        },
    },
    container: {
        display: 'flex',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    sideBar: {
        flexShrink: 0,
        width: '250px',
        backgroundColor: theme.palette.background.bigStone,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '500px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            fontSize: '12px',
            padding: '8px 0px',
            maxHeight: '100px',
            color: '#fff',
            borderBottom: 'none !important',
            backgroundColor: theme.palette.border.chambray,
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '-9px',
            paddingBottom: '15px',
            backgroundColor: theme.palette.border.chambray,
            '& > div': {
                marginRight: '10px',
            },
        },
    },
    userAvatar: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '38px',
        height: '38px',
        cursor: 'pointer',
    },
    category: {
        fontWeight: 'bold',
        color: theme.palette.text.white,
    },
    expand: {
        fill: theme.palette.background.white,
    },
    minus: {
        fill: theme.palette.background.white,
    },
    list: {
        height: '83%',
        scrollbarColor: `${theme.palette.background.white} ${theme.palette.background.bigStone}`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& > div:nth-child(1)': {
            backgroundColor: theme.palette.background.white,
            padding: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.background.bigStone,
            fontWeight: 'bold',
            fontSize: '14px',
        },
        '& > ul': {
            height: '100%',
            width: '100%',
            marginTop: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '10px',
            '& .MuiTreeItem-label': {
                fontSize: '13px',
            },
        },
        '& > div:nth-child(1) button': {
            minWidth: '100px',
            padding: 0,
        },
    },
    video: {
        marginTop: '5px',
        '& svg': {
            fill: theme.palette.background.white,
        },
        '& .MuiTreeItem-label': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            color: theme.palette.text.white,
        },
    },
    content: {
        flexGrow: 1,
        padding: '20px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            minHeight: '300px',
        },
    },
    defaultMessage: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: theme.palette.background.bigStone,
        fontWeight: 'bold',
        transform: 'translate(-50%, -50%)',
    },
    selectedVideo: {
        marginBottom: '10px',
        '& > iframe': {
            height: '84vh',
            width: '100%',
            backgroundColor: theme.palette.background.bigStone,
        },
    },
    selectedDescription: {
        fontSize: '13px',
        maxHeight: '50px',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'initial',
        },
    },
    selectedName: {
        marginTop: '20px',
        fontWeight: 'bold',
        color: theme.palette.background.bigStone,
    },
}));

const pickVideoBasedOnRoute = (categories, path, hash) => {
    if (categories?.length === 0 || StringUtils.isEmpty(path)) return null;

    const videos = categories
        .reduce((a, b) => [
            ...a,
            ...(
                (b.videos ?? [])
                    .filter((v) => !StringUtils.isEmpty(v.matchingRoute))
                    .map((v) => ({ ...v, category: { trainingCategoryId: b.trainingCategoryId, name: b.name } }))
            ),
        ], []);
    if (videos.length === 0) return null;

    return videos.find((v) => {
        const { pathname } = new URL(v.matchingRoute);
        const decodedPath = decodeURIComponent(pathname);
        const regExp = new RegExp(decodedPath.replace('{*}', '.+'), 'i'); // Match path

        return (StringUtils.isEmpty(hash) && regExp.test(path))
        || (regExp.test(path) && v.matchingRoute.includes(hash));
    });
};

const TrainingVideos = () => {
    const classes = useStyles();
    const location = useLocation();
    const { userInformation: userData } = useContext(UserContext);
    const { logOut, executingLogOut } = useLogOut();
    const [state, setState] = useState({
        anchorEl: null,
        categories: [],
        expandedCategories: [],
        selectedVideo: null,
        selectedCategory: null,
        tabValue: 0,
    });

    const {
        anchorEl,
        categories,
        expandedCategories,
        selectedVideo,
        selectedCategory,
        tabValue,
    } = state;

    const {
        data: categoriesData,
        loading: loadingCategories,
        error: errorLoadingCategories,
    } = useQuery(GeneralQuery.PULL_TRAINING_CATEGORIES, {
        variables: {
            isSettings: false,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (errorLoadingCategories) {
            ModalUtils.errorMessage(errorLoadingCategories?.graphQLErrors);
            return;
        }

        if (!loadingCategories) {
            const items = categoriesData?.pullTrainingCategories;
            if (categories) {
                const params = new URLSearchParams(location.search);
                const path = params.get('path');
                const hash = params.get('hash');
                const video = pickVideoBasedOnRoute(items, path, hash);

                setState((prevState) => ({
                    ...prevState,
                    categories: items,
                    ...(video ? { selectedCategory: video.category } : {}),
                    selectedVideo: video,
                    expandedCategories: (items ?? []).map((i) => i.trainingCategoryId),
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCategories, errorLoadingCategories]);

    const onCategoryToggle = (_, nodeIds) => {
        setState((prevState) => ({
            ...prevState,
            expandedCategories: nodeIds ?? [],
        }));
    };

    const setCurrentVideo = (categoryId, videoId) => {
        const category = categories.find((c) => c.trainingCategoryId === categoryId) ?? {};
        const videos = category.videos ?? [];
        const video = videos.find((v) => v.trainingVideoId === videoId);

        setState((prevState) => ({
            ...prevState,
            selectedVideo: video,
            selectedCategory: category,
        }));
    };

    const onChangeTab = (_, value) => {
        setState((prevState) => ({
            ...prevState,
            tabValue: value,
        }));
    };

    return (
        <div className={classes.container}>
            <div className={classes.sideBar}>
                <div>
                    <Avatar
                        aria-controls="basic-menu"
                        alt={userData?.username}
                        src={userData?.userPicture ?? avatarUser}
                        className={classes.userAvatar}
                        onClick={(e) => setState((prevState) => ({ ...prevState, anchorEl: e.currentTarget }))}
                    />
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setState((prevState) => ({ ...prevState, anchorEl: null }))}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <MenuItem onClick={() => logOut()} disabled={executingLogOut}>
                            <PowerSettingsNewIcon title="Logout" />
                            Logout
                        </MenuItem>
                    </Menu>
                    <div>{UserUtils.getUserInformation(userData)}</div>
                </div>
                <div>
                    <NotificationBadge type={TYPE.COMMUNICATION} />
                    <NotificationBadge type={TYPE.NOTIFICATION} />
                </div>
                <div className={classes.list}>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        orientation="horizontal"
                        variant="fullWidth"
                        onChange={onChangeTab}
                        TabIndicatorProps={{ hidden: true }}
                    >
                        <Tab disableFocusRipple disableRipple wrapped label="Automatrix Training" />
                        <Tab disableFocusRipple disableRipple wrapped label="Company Training" />
                    </Tabs>
                    <TreeView
                        expanded={expandedCategories}
                        onNodeToggle={onCategoryToggle}
                        defaultCollapseIcon={<MinusSquare className={classes.minus} />}
                        defaultExpandIcon={<PlusSquare className={classes.expand} />}
                    >
                        {categories
                            .filter((c) => (tabValue === 0 ? c.companyCode == null : c.companyCode != null) && c.videos?.length > 0)
                            .map((c) => {
                                const { videos } = c;

                                return (
                                    <TreeItem
                                        key={c.trainingCategoryId}
                                        nodeId={c.trainingCategoryId}
                                        label={<span className={classes.category}>{c.name}</span>}
                                    >
                                        {videos.map((v) => (
                                            <Tooltip key={v.trainingVideoId} title={v.name}>
                                                <TreeItem
                                                    className={classes.video}
                                                    nodeId={v.trainingVideoId}
                                                    label={v.name}
                                                    icon={<PlayCircleFilledOutlinedIcon />}
                                                    onLabelClick={(event) => {
                                                        event.preventDefault();
                                                        setCurrentVideo(c.trainingCategoryId, v.trainingVideoId);
                                                    }}
                                                />
                                            </Tooltip>
                                        ))}
                                    </TreeItem>
                                );
                            })}
                    </TreeView>
                </div>
            </div>
            <div className={classes.content}>
                {!selectedVideo && (
                    <div className={classes.defaultMessage}>Pick a video on the left menu to watch</div>
                )}
                {selectedVideo && (
                    <>
                        <div className={classes.selectedVideo}>
                            <iframe
                                title={selectedVideo.name}
                                src={selectedVideo.videoLink}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                        {selectedVideo.description && (
                            <div className={classes.selectedDescription}>
                                {selectedVideo.description}
                            </div>
                        )}
                        <div className={classes.selectedName}>
                            {`${selectedCategory.name} -> ${selectedVideo.name}`}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TrainingVideos;

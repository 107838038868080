/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import produce from 'immer';
import { isEqual } from 'lodash';

// HTTP
import { useMutation, useLazyQuery } from '@apollo/client';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

import { FormLabel, makeStyles } from '@material-ui/core';
import GroupActionsButtons from 'components/widgets/GroupActionsButtons';

import { FetchPolicy } from 'utils/enum/Core';
import InventoryMap from 'services/mapData/InventoryMap';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { InventoryPanels } from 'utils/enum/InventoryEnum';
import ModalUtils from 'utils/ModalUtils';
import MessageUtils from 'utils/MessageUtils';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    editPanel: {
        padding: '5px 5px 0',
        border: '1px solid #e8e8e8',
        boxShadow: '1px 2px 5px 0px #c1c1c1',
        margin: '0 20px 15px 10px',
        height: '98%',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        color: '#6b6b6b',
        fontSize: 14,
        marginBottom: 5,
        fontWeight: 'bold',
    },
});

const initState = {
    editMode: false,
    pricing: {
        purchasedPrice: 0,
        slashedPrice: 0,
        pack: 0,
        packOverride: false,
        stickerPrice: 0,
        repairs: 0,
        internetPrice: 0,
        floorplanCost: 0,
        minimumPrice: 0,
        wholeSalePrice: 0,
        age: 0,
        markup: 0,
        showSlashed: false,
    },
    bookValue: {
        nadaRetail: 0,
        nadaTrade: 0,
    },
    floorPlan: {
        flooredRate: 0,
        flooredBy: '',
        floorPlannedDate: null,
        curtailmentDate: null,
        borrowedAmount: 0,
        isFloorPlanned: false,
        isBorrowedAmountOverride: false,
        floorplanCost: 0,
        purchasedPrice: 0,
    },
    advertising: {
        enableReduce: false,
        reducePrice: '',
        reduceFrequency: '',
        removeAd: '',
        placeAd: false,
        websiteDownpayment: 0,
        websitePayment: 0,
        paymentFrequency: '',
        lastReduced: null,
    },
    detail: {},
    customFieldsBackup: [],
    customFields: [],
};

const ACTION_TYPES = {
    INIT_DATA: 'initData',
    ON_CHANGE: 'onChange',
    SET_OVERRIDE: 'setOverride',
    SET_EDIT_MODE: 'setEditMode',
    SET_CUSTOM_FIELDS: 'setCustomFields',
    UPDATE_CUSTOM_FIELD: 'updateCustomField',
    UPDATE_CUSTOM_FIELD_OPTIONS: 'updateCustomFieldOptions',
    ON_SAVE: 'onSave',
    ON_CHANGE_FLOORPLAN: 'onChangeFloorplan',
};

const reducer = produce((draftState, { type, payload = null }) => {
    switch (type) {
    case ACTION_TYPES.INIT_DATA:
        const { fieldName, data, isCustomFields } = payload;

        if (isCustomFields) {
            draftState.editMode = false;
            draftState.customFields = draftState.customFieldsBackup;
            return;
        }

        draftState.editMode = false;
        draftState[fieldName] = {
            ...draftState[fieldName],
            ...data,
        };
        break;
    case ACTION_TYPES.ON_CHANGE:
        draftState[payload.dataFieldName][payload.fieldName] = payload.value;
        break;
    case ACTION_TYPES.ON_CHANGE_FLOORPLAN:
        draftState[payload.dataFieldName] = {
            ...draftState[payload.dataFieldName],
            ...payload.data,
        };
        break;
    case ACTION_TYPES.SET_OVERRIDE:
        draftState.pricing.packOverride = false;
        draftState.pricing.pack = payload;
        break;
    case ACTION_TYPES.SET_EDIT_MODE:
        draftState.editMode = !draftState.editMode;
        break;
    case ACTION_TYPES.SET_CUSTOM_FIELDS:
        draftState.customFieldsBackup = payload;
        draftState.customFields = payload;
        draftState.editMode = false;
        break;
    case ACTION_TYPES.UPDATE_CUSTOM_FIELD:
        draftState.customFields[payload.index].value = payload.value;
        break;
    case ACTION_TYPES.UPDATE_CUSTOM_FIELD_OPTIONS:
        draftState.customFields[payload.index].options = payload.options;
        draftState.customFields[payload.index].value = payload.value;
        break;
    default:
        break;
    }
});

const getData = (title, state = {}) => {
    switch (title) {
    case InventoryPanels.PRICING:
        return state.pricing;
    case InventoryPanels.FLOORPLAN:
        return state.floorPlan;
    case InventoryPanels.BOOK_VALUE:
        return state.bookValue;
    case InventoryPanels.ADVERTISING_SETUP:
        return state.advertising;
    case InventoryPanels.PURCHASE_INFO:
        return state.detail;
    case InventoryPanels.CUSTOM_FIELDS:
        return state.customFields;
    default:
        return {};
    }
};

const getFieldName = (title) => {
    switch (title) {
    case InventoryPanels.PRICING:
        return 'pricing';
    case InventoryPanels.FLOORPLAN:
        return 'floorPlan';
    case InventoryPanels.BOOK_VALUE:
        return 'bookValue';
    case InventoryPanels.ADVERTISING_SETUP:
        return 'advertising';
    case InventoryPanels.PURCHASE_INFO:
        return 'detail';
    case InventoryPanels.CUSTOM_FIELDS:
        return 'customFields';
    default:
        return '';
    }
};

const getMapData = (title, data) => {
    switch (title) {
    case InventoryPanels.PRICING:
        return InventoryMap.mapPricingData(data);
    case InventoryPanels.BOOK_VALUE:
        return InventoryMap.mapBookValueData(data);
    case InventoryPanels.FLOORPLAN:
        return InventoryMap.mapFloorplanData(data);
    case InventoryPanels.ADVERTISING_SETUP:
        return InventoryMap.mapAdvertisingData(data);
    default:
        return '';
    }
};

const PanelsContainers = ({
    stockNumber,
    data,
    title,
    children,
    setEditedSection,
    allowEdit,
    editedSection,
    onReload,
    blockingStatus,
    currentUserId,
    changeBlockingStatus,
    version,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const keyStore = new KeyStore();
    const WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const dataFieldName = getFieldName(title);
    const isRecordBlocked = blockingStatus?.isEditing === true && blockingStatus.editingById !== currentUserId;
    const disabled = (dataFieldName !== editedSection && !StringUtils.isEmpty(editedSection)) || isRecordBlocked;

    const getValueProperty = (customFields) => customFields
        .map((cf) => ({ value: cf.value }));

    const hasChangesInOtherSections = () => {
        const current = state[dataFieldName];
        const isFloorPlan = (title === InventoryPanels.FLOORPLAN);

        const floorPlanActive = isFloorPlan && current.isFloorPlanned;
        if (floorPlanActive && StringUtils.isEmpty(current.flooredBy)) return false;
        if (floorPlanActive && !current.floorPlannedDate) return false;
        return !isEqual(data, current);
    };

    const isCustomFields = (title === InventoryPanels.CUSTOM_FIELDS);
    const hasChanges = isCustomFields
        ? !isEqual(
            getValueProperty(state.customFieldsBackup),
            getValueProperty(state[dataFieldName]),
        )
        : hasChangesInOtherSections();

    const [updateVehicle, { loading }] = useMutation(InventoryMutation.UPDATE_VEHICLE);
    const [saveCustomFields, { loading: savingCustomFields }] = useMutation(InventoryMutation.SAVE_VEHICLE_CUSTOM_FIELDS, {
        onCompleted: async (response) => {
            if (response) {
                await changeBlockingStatus({
                    variables: {
                        stockNumber,
                        isBlocking: false,
                    },
                });

                dispatch({
                    type: ACTION_TYPES.SET_CUSTOM_FIELDS,
                    payload: state.customFields,
                });

                onReload();
                ModalUtils.successMessage(null, 'Custom Fields saved successfully.');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [getVehicleCustomFields] = useLazyQuery(InventoryQuery.GET_VEHICLE_CUSTOM_FIELDS, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_CUSTOM_FIELDS,
                    payload: response.getCustomFieldsInventory,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (title === InventoryPanels.CUSTOM_FIELDS) {
            getVehicleCustomFields({
                variables: {
                    stockNumber: stockNumber.toString(),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.INIT_DATA,
            payload: {
                fieldName: dataFieldName,
                data,
                isCustomFields,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataFieldName]);

    const getCustomFieldsInput = () => {
        const { customFields } = state;
        return customFields.map((cf) => ({
            entityMetadataId: cf.entityMetadataId,
            value: cf.value || '',
        }));
    };

    const onSave = async () => {
        if (WRITE) {
            try {
                if (isCustomFields) {
                    const input = getCustomFieldsInput();
                    saveCustomFields({
                        variables: {
                            stockNumber: stockNumber.toString(),
                            input,
                        },
                    });
                    return;
                }

                const newData = state[dataFieldName];
                const input = getMapData(title, newData);
                const variables = {
                    stockNumber,
                    input: {
                        ...input,
                        version,
                    },
                };

                const response = await updateVehicle({ variables });
                if (response?.data?.updateVehicle) {
                    await changeBlockingStatus({
                        variables: {
                            stockNumber,
                            isBlocking: false,
                        },
                    });

                    const updatedFields = dataFieldName === InventoryPanels.PRICING.toLowerCase()
                        ? { ...newData, markup: input.internetPrice - input.cost }
                        : newData;

                    dispatch({
                        type: ACTION_TYPES.INIT_DATA,
                        payload: {
                            fieldName: dataFieldName,
                            data: updatedFields,
                        },
                    });

                    onReload();
                    ModalUtils.successMessage(null, 'Vehicle updated successfully!');
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', title));
                }
            } catch (ex) {
                const message = ex.message || MessageUtils.getGenericError('save', title);

                ModalUtils.errorMessage(ex.graphQLErrors, message);
            }
        }
    };

    const onChange = (fieldName, value, newData = null) => {
        if (isCustomFields) {
            dispatch({
                type: ACTION_TYPES.UPDATE_CUSTOM_FIELD,
                payload: {
                    index: state.customFields
                        .findIndex((cf) => cf.entityMetadataId === fieldName),
                    value,
                },
            });

            return;
        }

        if (newData !== null) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_FLOORPLAN,
                payload: {
                    dataFieldName,
                    data: newData,
                },
            });
        } else if (fieldName === 'packOverride' && !value) {
            dispatch({
                type: ACTION_TYPES.SET_OVERRIDE,
                payload: 0,
            });
        } else {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE,
                payload: {
                    dataFieldName,
                    fieldName,
                    value,
                },
            });
        }
    };

    const updateCustomFieldOptions = (customField) => {
        const { options, value } = customField;
        const isValueIncluded = options.some(
            (option) => option.option === value,
        );

        dispatch({
            type: ACTION_TYPES.UPDATE_CUSTOM_FIELD_OPTIONS,
            payload: {
                index: state.customFields
                    .findIndex((cf) => cf.entityMetadataId === customField.entityMetadataId),
                options,
                value: isValueIncluded ? value : '',
            },
        });
    };

    const toggleEditMode = (editMode = false) => {
        if (editMode) {
            changeBlockingStatus({
                variables: {
                    stockNumber,
                    isBlocking: true,
                },
            });

            dispatch({
                type: ACTION_TYPES.SET_EDIT_MODE,
            });
            setEditedSection(dataFieldName);
        } else {
            changeBlockingStatus({
                variables: {
                    stockNumber,
                    isBlocking: false,
                },
            });

            dispatch({
                type: ACTION_TYPES.INIT_DATA,
                payload: {
                    fieldName: dataFieldName,
                    data,
                    isCustomFields,
                },
            });
            setEditedSection('');
        }
    };

    const records = getData(title, state);
    return (
        <div className={clsx(classes.root, classes.editPanel)}>
            <div className={classes.titleContainer}>
                <FormLabel className={classes.title}>
                    { title }
                </FormLabel>
                {allowEdit && (
                    <GroupActionsButtons
                        onSave={onSave}
                        toggleEditMode={toggleEditMode}
                        loading={loading || savingCustomFields}
                        editMode={state.editMode}
                        disabled={disabled}
                        allowSave={hasChanges}
                    />
                )}
            </div>
            <>
                {
                    React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                            if (state.editMode && !child.props.readOnly) {
                                return React.cloneElement(child, {
                                    onChange,
                                    updateCustomFieldOptions,
                                    data: records,
                                    writePermission: WRITE,
                                });
                            }
                            if (!state.editMode && child.props.readOnly) {
                                return React.cloneElement(child, {
                                    data: records,
                                });
                            }
                        }

                        return null;
                    })
                }
            </>
        </div>
    );
};

PanelsContainers.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    editedSection: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    children: PropTypes.node.isRequired,
    setEditedSection: PropTypes.func,
    allowEdit: PropTypes.bool,
    onReload: PropTypes.func,
    blockingStatus: PropTypes.object,
    currentUserId: PropTypes.number,
    changeBlockingStatus: PropTypes.func,
    version: PropTypes.number,
};

PanelsContainers.defaultProps = {
    title: 'Vehicle Info',
    data: {},
    allowEdit: false,
    editedSection: '',
    blockingStatus: null,
    currentUserId: 0,
    setEditedSection: () => {},
    onReload: () => {},
    changeBlockingStatus: () => null,
    version: 0,
};

export default PanelsContainers;

import { gql } from '@apollo/client';
import DocumentsFragment from 'services/graphQL/fragments/documents';

export default class CustomerQuery {
    static GET_VEHICLE_WISH_BY_CONVERSATION_ID = gql`
        query getVehicleWishes($conversationId: ID!) {
            getVehicleWishes(conversationId: $conversationId) {
                vehicleWishId
                stock: stockNumber
                year
                make
                model
                trim
                color
                notes
                isFavorite
                picture
            }
        }
    `;

    static GET_CUSTOMER_INFORMATION = gql`
        query getCustomerInformation($conversationId: ID!) {
            getCustomerInformation(conversationId: $conversationId) {
                firstName
                lastName
                email
                phone
                leadId
            }
        }
    `;

     static GET_CUSTOMER_INFORMATION_BY_CRM_ID = gql`
        query getCustomerByCRMId($crmId: ID!) {
            getCustomerByCRMId(crmId: $crmId) {
                crmId
                customer:buyer {
                    customerCode
                    firstName
                    lastName
                    email
                    cellPhone
                    workPhone
                    homePhone
                }
                createdOn
                lastUpdated
            }
        }
    `;

    static GET_CUSTOMER_AND_ADDRESS = gql`
        query getCustomerAndAddress($customerCode: ID!) {
            getCustomerAndAddress(customerCode: $customerCode) {
                customerCode
                customerId
                firstName
                middleName
                lastName
                cellPhone
                workPhone
                homePhone
                email
                dob
                ssn
                dln
                isBusiness
                allowCallCellPhone
                allowTextCellPhone
                allowCallHomePhone
                allowCallWorkPhone
                isTaxable
                language
                currentAddress {
                    customerAddressId
                    customerId
                    parentId
                    address1
                    address2
                    housingStatus
                    mortgageOrRent
                    start
                    end
                    totalMonths
                    phone
                    city
                    state
                    county
                    zipCode
                    country
                }
                currentEmployment {
                  customerId
                  customerEmploymentId
                  status
                  employer
                  title
                  monthlyIncome
                  workPhone
                  otherIncomeMonthly
                  otherIncomeSource
                  address1
                  address2
                  city
                  state
                  county
                  zipCode
                  country
                  start
                  totalMonths
                }
            }
        }
    `;

    static GET_CUSTOMERS = gql`
        query getCustomerList($paginate: DataPaging, $sort: [DataSort], $filter: Filter) {
            getCustomerList(paginate: $paginate, sort: $sort, filter: $filter) {
                totalCount
                customers {
                    customerId
                    customerCode
                    firstName
                    lastName
                    cellPhone
                    sysDate
                    cellPhone
                    email
                }
            }
        }
    `;

    static GET_ADDRESS_LIST = gql`
        query getCustomerAddressList($input: CustomerAddressInput, $sort: [DataSort]) {
            getCustomerAddressList(input: $input, sort: $sort) {
                customerId
                customerAddressId
                address1
                address2
                county
                country
                state
                zipCode
                city
                phone
                isCurrentAddress
                housingStatus
                mortgageOrRent
                totalMonths
                start
                end
                status
                createdOn
            }
        }
    `;

    static GET_EMPLOYMENT_LIST = gql`
        query getCustomerEmploymentList($input: CustomerEmploymentInput, $sort: [DataSort]) {
            getCustomerEmploymentList(input: $input, sort: $sort) {
              customerId
              customerEmploymentId
              parentId
              status
              employer
              title
              start
              end
              monthlyIncome
              workPhone
              otherIncomeMonthly
              otherIncomeSource
              address1
              address2
              city
              state
              county
              zipCode
              country
              createdOn
              createdByUserId
              modifiedOn
              modifiedByUserId
              totalMonths
              isCurrentEmployment
            }
        }
    `;

    static DECODE_ZIP_CODE = gql`
        query decodeZip($zip: String!) {
            decodeZip(zip: $zip) {
                city
                county
                state
            }
        }
    `;

    static GET_CURRENT_EMPLOYMENT_ID = gql`
        query getCurrentEmploymentId($customerCode: ID!) {
          customer: getCustomerAndAddress(customerCode: $customerCode) {
            currentEmploymentId
            customerId
          }
        }
    `;

    static GET_CUSTOMER_OPPORTUNITIES_BY_CUSTOMER_ID = gql`
        query getCustomerOpportunitiesByCustomerId($customerId: ID!){
            getCustomerOpportunitiesByCustomerId(customerId: $customerId) {
                status
                leadCode
                modifiedOn
                vehicleMake
                vehicleTrim
                vehicleYear
                processStage
                vehicleModel
                vehicle {
                    year
                    make
                    trim
                    model
                    stockNumber
                    customStockNumber
                }
            }
        }
    `;

    static GET_CUSTOMER_DEALS_BY_CUSTOMER_ID = gql`
        query getCustomerDealsByCustomerId($customerId: ID!, $sort: DataSort, $paging: DataPaging) {
            getCustomerDealsByCustomerId(customerId: $customerId, sort: $sort, paging: $paging) {
                accountNumber
                soldDate
                status
                dealType
                vehicle {
                    stockNumber
                    customStockNumber
                    year
                    make
                    model
                }
            }
        }
    `;

    static GET_ROS_BY_CUSTOMER_ID = gql`
        query getROsByCustomerId($customerId: ID!){
            getROsByCustomerId(customerId: $customerId) {
                date
                total
                status
                vehicleVin
                vehicleYear
                vehicleMake
                vehicleModel
                invoiceNumber
            }
        }
    `;

    static GET_SUGGESTED_ADDRESS = gql`
        query getSuggestedAddress($input: SuggestedAddressInput!) {
            getSuggestedAddress(input: $input) {
                firm
                address {
                    streetAddress
                    streetAddressAbbreviation
                    secondaryAddress
                    cityAbbreviation
                    city
                    state
                    ZIPCode
                    ZIPPlus4
                    urbanization
                }
                additionalInfo {
                    deliveryPoint
                    carrierRoute
                    DPVConfirmation
                    DPVCMRA
                    business
                    centralDeliveryPoint
                    vacant
                }
                corrections {
                    code
                    text
                }
                matches {
                    code
                    text
                }
                warnings
            }
        }
    `;

    static PULL_AVAILABLE_CUSTOMER_DOCUMENTS = gql`
        query pullAvailableCustomerDocuments($customerCode: ID!) {
            pullAvailableCustomerDocuments(customerCode: $customerCode) {
                ...DocumentFragment
            }
        }
        ${DocumentsFragment.GET_DOCUMENT_FRAGMENT}
    `;

    static GET_CUSTOMER_DOCUMENT_UPLOAD_SIGNED_URL = gql`
        query getCustomerDocumentUploadSignedURL($customerCode: Int!, $fileName: String!) {
            getCustomerDocumentUploadSignedURL(customerCode: $customerCode, fileName: $fileName) {
                url
                path
            }
        }
    `;

    static GET_CUSTOMER_DOCUMENT_SECURE_URL = gql`
        query getCustomerDocumentSecureURL($path: String!) {
            getCustomerDocumentSecureURL(path: $path)
        }
    `;
}

import React from 'react';
import PropTypes from 'prop-types';
import { DOCUMENT_REFERENCE_TYPE } from 'utils/enum/Core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerMutate from 'services/graphQL/mutate/CustomerMutate';
import DocumentTypeMutation from 'services/graphQL/mutate/DocumentTypeMutation';
import FileManagerContainer from 'components/widgets/filemanager/FileManagerContainer';

export default function DocumentsTab({ customerCode }) {
    const keyStore = new KeyStore();
    const DEALS_DOCUMENTS_WRITE = keyStore.hasPermission(Permission.DEALS_DOCUMENTS_WRITE);
    const DEALS_DOCUMENTS_DELETE = keyStore.hasPermission(Permission.DEALS_DOCUMENTS_DELETE);

    return (
        <div>
            <FileManagerContainer
                documentsProps={[
                    {
                        referenceId: Number(customerCode),
                        referenceType: DOCUMENT_REFERENCE_TYPE.CUSTOMER,
                        referenceName: 'customerCode',
                        pullDocumentsQuery: CustomerQuery.PULL_AVAILABLE_CUSTOMER_DOCUMENTS,
                        getDocumentUploadSignedURL: CustomerQuery.GET_CUSTOMER_DOCUMENT_UPLOAD_SIGNED_URL,
                        getDocumentSecureURL: CustomerQuery.GET_CUSTOMER_DOCUMENT_SECURE_URL,
                        createDocumentsMutation: CustomerMutate.CREATE_CUSTOMER_DOCUMENTS,
                        editDocumentNameMutation: CustomerMutate.EDIT_CUSTOMER_DOCUMENT_NAME,
                        deleteMutation: CustomerMutate.DELETE_CUSTOMER_DOCUMENT,
                        canWrite: DEALS_DOCUMENTS_WRITE,
                        canDelete: DEALS_DOCUMENTS_DELETE,
                        documentTypeMutation: DocumentTypeMutation.UPDATE_DOCUMENT_TYPE,
                    },
                ]}
            />
        </div>
    );
}

DocumentsTab.propTypes = {
    customerCode: PropTypes.string.isRequired,
};

export default class TabStyles {
    static tabContainer() {
        return ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                '& .tab-content': {
                    overflow: 'hidden',
                    height: '100%',
                },
                '& .tab-content > div > div': {
                    width: '100%',
                    height: '100%',
                },
            },
        });
    }

    static tabPanel(theme = {}) {
        return ({
            tabPanel: {
                background: theme.palette.background.white,
                flex: 1,
                height: '100%',
                overflow: 'hidden',
            },
            box: {
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
            },
            tab: {
                height: '100%',
            },
            historyTab: {
                padding: '20px 10px',
                backgroundColor: theme.palette.background.default,
            },
            navbar: {
                '& > a': {
                    color: theme.palette.text.link,
                    'text-decoration': 'none',
                    'background-color': 'transparent',
                    'line-height': '1.5',
                },
            },
            borderContainer: {
                '& + div': {
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                    marginLeft: '0.5px',
                },
            },
            headerBanner: {
                transitionDelay: '4s',
                transitionTimingFunction: 'ease-in',
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap',
                },
                '& > label': {
                    color: theme.palette.text.tuna,
                    fontSize: 16,
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 14,
                        marginBottom: '5px',
                    },
                    '& > span': {
                        fontWeight: 'bold',
                        marginRight: 5,
                    },
                },
            },
            yellowBanner: {
                backgroundColor: theme.palette.background.yellow,
                justifyContent: 'center',

                '& > label': {
                    fontWeight: 'bold',
                },
            },
            greenBanner: {
                backgroundColor: theme.palette.background.malachite,
                justifyContent: 'flex-start',
                '& > label': {
                    marginRight: 40,
                },
            },
        });
    }
}

import { gql } from '@apollo/client';

export default class DocumentTypeQuery {
    static GET_DOCUMENT_TYPES = gql`
        query getDocumentTypes{
            getDocumentTypes{
                documentTypeId
                description
            }
        }
    `;
}

import { gql } from '@apollo/client';

export default class AccountingDocumentsQuery {
    static GET_INVOICE_DOCUMENTS = gql`
        query getInvoiceDocumentByReference($referenceId: Int!) {
            getInvoiceDocumentByReference(referenceId: $referenceId) {
                documentId
                referenceId
                referenceType
                documentType
                documentSize
                documentUrl
                documentName
                isSecureUrl
            }
        }
    `;

    static CREATE_INVOICE_DOCUMENTS = gql`
    mutation createInvoiceDocument($referenceId: Int!, $input: [DocumentReferenceInput!]!) {
        createInvoiceDocument(referenceId: $referenceId, input: $input)
    }
    `;

    static EDIT_INVOICE_DOCUMENT_NAME = gql`
        mutation editInvoiceDocumentName($documentId: Int!, $name: String!) {
            editInvoiceDocumentName(documentId: $documentId, name: $name)
        }
    `

    static DELETE_INVOICE_DOCUMENT = gql`
    mutation deleteInvoiceDocument($documentId: Int!) {
        deleteInvoiceDocument(documentId: $documentId)
    }
    `;

    // For Checks
    static GET_CHECK_DOCUMENTS = gql`
        query getCheckDocumentByReference($referenceId: Int!) {
            getCheckDocumentByReference(referenceId: $referenceId) {
                documentId
                referenceId
                referenceType
                documentType
                documentSize
                documentUrl
                documentName
                isSecureUrl
            }
        }
    `;

    static CREATE_CHECK_DOCUMENTS = gql`
    mutation createCheckDocument($referenceId: Int!, $input: [DocumentReferenceInput!]!) {
        createCheckDocument(referenceId: $referenceId, input: $input)
    }
    `;

    static EDIT_CHECK_DOCUMENT_NAME = gql`
        mutation editCheckDocumentName($documentId: Int!, $name: String!) {
            editCheckDocumentName(documentId: $documentId, name: $name)
        }
    `

    static DELETE_CHECK_DOCUMENT = gql`
    mutation deleteCheckDocument($documentId: Int!) {
        deleteCheckDocument(documentId: $documentId)
    }
    `;

    static GET_DEPOSIT_DOCUMENTS = gql`
        query getDepositDocumentByReference($referenceId: Int!) {
            getDepositDocumentByReference(referenceId: $referenceId) {
                documentId
                referenceId
                referenceType
                documentType
                documentSize
                documentUrl
                documentName
                isSecureUrl
            }
        }
    `;

    static CREATE_DEPOSIT_DOCUMENTS = gql`
    mutation createDepositDocument($referenceId: Int!, $input: [DocumentReferenceInput!]!) {
        createDepositDocument(referenceId: $referenceId, input: $input)
    }
    `;

    static EDIT_DEPOSIT_DOCUMENT_NAME = gql`
        mutation editDepositDocumentName($documentId: Int!, $name: String!) {
            editDepositDocumentName(documentId: $documentId, name: $name)
        }
    `;

    static DELETE_DEPOSIT_DOCUMENT = gql`
    mutation deleteDepositDocument($documentId: Int!) {
        deleteDepositDocument(documentId: $documentId)
    }
    `;
}

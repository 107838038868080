import React, { useEffect, useState } from 'react';

import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import Permission from 'utils/enum/Permissions';
import { useMutation, useQuery } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import { ALL_LOTS, FetchPolicy } from 'utils/enum/Core';
import { makeStyles, Button, Grid } from '@material-ui/core';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { DEALS_FORMS_PACKS_SORTING } from 'utils/enum/General';
import CircularProgress from '@material-ui/core/CircularProgress';
import Category, { LotDefaultskey } from 'utils/enum/LotsCategory';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '0.875rem',
    },
}));

const sortList = [
    DEALS_FORMS_PACKS_SORTING.BY_NAME,
    DEALS_FORMS_PACKS_SORTING.BY_DATE_ADDED,
];

const FormGeneralSettings = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();

    const [state, setState] = useState({
        sortSelected: DEALS_FORMS_PACKS_SORTING.BY_NAME,
    });

    const {
        sortSelected,
    } = state;

    const handleChange = (fieldName, value) => {
        setState((previousState) => ({
            ...previousState,
            [fieldName]: value,
        }));
    };

    const {
        data, loading, error,
    } = useQuery(LotQuery.GET_SETTINGS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            lotName: ALL_LOTS,
            category: Category.FORMS,
            key: [LotDefaultskey.DEALS_FORMS_PACKS_SORTING],
        },
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getSettings } = data;
            const value = getSettings[0]?.value;
            setState((previousState) => ({
                ...previousState,
                sortSelected: value ?? DEALS_FORMS_PACKS_SORTING.BY_NAME,
            }));
        }
    }, [data, loading, error]);

    const [saveData, { loading: updating }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.saveLotDefaults) {
                ModalUtils.successMessage(null, 'Saved Successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => {
        saveData({
            variables: {
                critical: false,
                lotName: ALL_LOTS,
                value: sortSelected,
                category: Category.FORMS,
                key: LotDefaultskey.DEALS_FORMS_PACKS_SORTING,
            },
        });
    };

    return (
        <Grid container>
            <Grid item md={3}>
                <Form.Group as={Col}>
                    <Form.Label className={classes.label}>Default Sorting for deals forms packs</Form.Label>
                    <Select
                        size="sm"
                        name="sortSelected"
                        value={sortSelected}
                        onChange={handleChange}
                        disabled={!keyStore.hasPermission(Permission.FORMS_SETTINGS_GENERAL_WRITE) || updating || loading}
                        options={sortList.map((value) => ({ label: value, value }))}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Button
                        size="small"
                        onClick={onSave}
                        variant="outlined"
                        disabled={!keyStore.hasPermission(Permission.FORMS_SETTINGS_GENERAL_WRITE) || updating || loading}
                        startIcon={<SaveOutlinedIcon />}
                    >
                        {updating ? 'Saving...' : 'Save'}
                        {updating && <CircularProgress size={20} />}
                    </Button>
                </Form.Group>
            </Grid>
        </Grid>
    );
};
export default FormGeneralSettings;

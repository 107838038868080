import React from 'react';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import TabSetting from 'components/modules/settings/TabSetting';
import FormFields from 'components/modules/settings/form/FormFields';
import FormMapper from 'components/modules/settings/form/FormMapper';
import FormGeneralSettings from 'components/modules/settings/form/FormGeneralSettings';

const keyStore = new KeyStore();

const getSettings = () => {
    const settings = [];

    if (keyStore.hasPermission(Permission.FORMS_SETTINGS_GENERAL_READ)) {
        settings.push({
            url: 'general',
            text: 'General',
            component: <FormGeneralSettings />,
            icon: <TuneOutlinedIcon />,
        });
    }

    if (keyStore.hasPermission(Permission.FORMS_SETTINGS_MAPPER_READ)) {
        settings.push({
            url: 'mapper',
            text: 'Mapper',
            component: <FormMapper />,
            icon: <TuneOutlinedIcon />,
        });
    }

    if (keyStore.hasPermission(Permission.FORMS_SETTINGS_FIELDS_READ)) {
        settings.push({
            url: 'fields',
            text: 'Fields',
            component: <FormFields />,
            icon: <TuneOutlinedIcon />,
        });
    }

    return settings;
};

const FormSettings = () => <TabSetting records={getSettings()} />;

export default FormSettings;
